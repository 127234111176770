import { ReactNode } from 'react'

import { useQueueConsumer } from 'src/contexts/QueueProvider'
import { useNonBlockingAutoscrollEffect } from 'src/contexts/useNonBlockingAutoscrollEffect'

interface TranscriptUpdateEventQueueExecutorProps {
    children: ReactNode
}

export const TranscriptUpdateEventQueueExecutor = ({ children }: TranscriptUpdateEventQueueExecutorProps) => {
    const [revisionQueueSize, revisionQueueExecute] = useQueueConsumer('revision')
    const [asrQueueSize, asrQueueExecute] = useQueueConsumer('asr')

    useNonBlockingAutoscrollEffect(() => {
        revisionQueueExecute()
    }, [revisionQueueSize])

    useNonBlockingAutoscrollEffect(() => {
        asrQueueExecute()
    }, [asrQueueSize])

    return <>{children}</>
}
