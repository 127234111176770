import LogRocket from 'logrocket'

import { getLogRocketApiKey } from 'src/utils/env'

import { ServiceBase } from './ServiceBase'

class WebSessionRecordingService extends ServiceBase {
    constructor() {
        super({ blockedEnvs: ['dev'] })
    }

    init() {
        LogRocket.init(getLogRocketApiKey()!)
    }

    identify(id: string) {
        LogRocket.identify(id)
    }
}

export const webSessionRecordingService = new WebSessionRecordingService()
