import { paletteChromakey } from './palette.chromakey'
import { paletteDark } from './palette.dark'
import { paletteDefault } from './palette.default'
import { paletteHighContrast } from './palette.high-contrast'
import { Palette, ThemeCore, ThemeType } from './types'

export const themeCore: ThemeCore = {
    breakPoints: {
        laptopMaxWidth: 1365,
        laptopMinWidth: 1240,
        tabletMaxWidth: 1023,
        mobilePhoneMaxWidth: 767,
    },

    zIndices: {
        appHeader: 2,
        trancriptToolbar: 1,
    },

    sizes: {
        headerHeight: 60,
        trancriptToolbarHeight: 78,
    },
}

export const themePalettes: Record<ThemeType, Palette> = {
    default: paletteDefault,
    dark: paletteDark,
    highContrast: paletteHighContrast,
    chromakey: paletteChromakey,
}
