import { useEffect, useRef, useState } from 'react'

// other options to consider: "mousedown", "resize", "keydown", "touchstart", "wheel"
const ACTIVE_EVENTS: (keyof DocumentEventMap)[] = ['mousemove']

export function useIdle(timeoutMs: number) {
    const [isIdle, setIsIdle] = useState(false)
    const idleTimeout = useRef<NodeJS.Timeout>()

    useEffect(() => {
        const listener = () => {
            setIsIdle(false)
            if (idleTimeout.current) {
                clearTimeout(idleTimeout.current)
            }
            idleTimeout.current = setTimeout(() => {
                setIsIdle(true)
            }, timeoutMs)
        }

        idleTimeout.current = setTimeout(() => {
            setIsIdle(true)
        }, timeoutMs)

        for (const event of ACTIVE_EVENTS) {
            document.addEventListener(event, listener)
        }

        return () => {
            for (const event of ACTIVE_EVENTS) {
                document.removeEventListener(event, listener)
            }

            if (idleTimeout.current) {
                clearTimeout(idleTimeout.current)
            }
        }
    }, [timeoutMs])

    return isIdle
}
