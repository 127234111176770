import { ChangeEvent } from 'react'

import styled, { useTheme } from 'styled-components'
import { Overlay2, Popover } from '@blueprintjs/core'
import { theme } from 'styled-tools'
import { CheckIcon } from '@verbit-ai/icons-library'
import { Checkbox } from '@verbit-ai/verbit-ui-library'

import { useConfig } from 'src/contexts/ConfigurationContext'
import { useBlueprintThemeClass } from 'src/hooks/useBlueprintThemeClass'
import { shouldClosePopover } from 'src/utils/popover'
import { useResponsive } from 'src/hooks/useResponsive'
import { BP5_POPOVER_VERTICAL_SPACING_CLASSNAME } from 'src/global-styles'
import { FONT_SIZE_PERCENTAGE_OPTIONS } from 'src/models/UIConfiguration'

const FontSizePanel = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    border-radius: 3px;
    background-color: ${theme('palette.fontSizeControl.backgroundColor')};

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        top: calc(${theme('sizes.headerHeight')}px + 10px);
        right: 24px;
        box-shadow: 0px 4px 14px 0px #0000001a;
        padding: 8px 16px;
    }
`

const FontSizeOption = styled.button`
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    font-size: 14px;
    line-height: 21px;
    color: ${theme('palette.fontSizeControl.textColor')};

    &:hover {
        background-color: ${theme('palette.fontSizeControl.optionHoverColor')};
    }
`

const FullWidthToggleWrapper = styled.div`
    padding: 8px 16px;
    border-top: 1px solid ${theme('palette.fontSizeControl.delimiterColor')};

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        display: none;
    }
`

const StyledCheckbox = styled(Checkbox)`
    display: flex !important;

    & .chakra-checkbox__label {
        font-size: 14px;
        line-height: 21px;
        margin-left: 16px;
        color: ${theme('palette.fontSizeControl.textColor')};
    }
`

interface FontSizeControlProps {
    isOpen: boolean
    onClose: () => void
    children?: React.ReactNode
}

export const FONT_SIZE_CONTROL_CLASSNAME = 'font-size-control'

export function FontSizeControl({ isOpen, onClose, children }: FontSizeControlProps) {
    const [config, setProp] = useConfig()
    const { isTablet } = useResponsive()
    const {
        palette: {
            fontSizeControl: { iconColor },
        },
    } = useTheme()
    const popoverClassName = useBlueprintThemeClass()

    const toggleContainerFullWidth = (event: ChangeEvent<HTMLInputElement>) => {
        setProp('isFullWidthMode', event.target.checked)
        onClose()
    }

    const fontSizePanel = () => (
        <FontSizePanel>
            {FONT_SIZE_PERCENTAGE_OPTIONS.map((fontSizePercentage) => (
                <FontSizeOption
                    key={fontSizePercentage}
                    tabIndex={0}
                    aria-label={`Set font size to ${fontSizePercentage}%`}
                    onClick={() => setProp('fontSizePercentage', fontSizePercentage)}>
                    <span>{fontSizePercentage}%</span>
                    {fontSizePercentage === config.fontSizePercentage && <CheckIcon size={16} color={iconColor} />}
                </FontSizeOption>
            ))}
            <FullWidthToggleWrapper>
                <StyledCheckbox defaultChecked={config.isFullWidthMode} onChange={toggleContainerFullWidth}>
                    Expand to full width view
                </StyledCheckbox>
            </FullWidthToggleWrapper>
        </FontSizePanel>
    )

    const onCloseAttempt = (event: React.SyntheticEvent<HTMLElement, Event>) => {
        if (shouldClosePopover(event)) {
            onClose()
        }
    }

    return isTablet ? (
        <Overlay2 isOpen={isOpen} onClose={onClose} canEscapeKeyClose canOutsideClickClose hasBackdrop={false}>
            {fontSizePanel()}
        </Overlay2>
    ) : (
        <Popover
            popoverClassName={popoverClassName}
            portalClassName={BP5_POPOVER_VERTICAL_SPACING_CLASSNAME}
            className={FONT_SIZE_CONTROL_CLASSNAME}
            content={fontSizePanel()}
            interactionKind="click"
            isOpen={isOpen}
            onClose={onCloseAttempt}
            placement="bottom-end"
            minimal>
            {children}
        </Popover>
    )
}
