import { VerbitLogo } from '@verbit-ai/icons-library'

import { Palette } from './types'

export const paletteHighContrast: Palette = {
    global: {
        focusIndicatorColor: '#1A4AF0',
        iconColor: '#304571',
    },
    header: {
        logo: <VerbitLogo theme="light" />,
        titleColor: '#030d69',
        panelBackgroundColor: '#ffffff',
        panelFilter: 'drop-shadow(0px 4px 20px rgba(0,0,0,0.1))',
        panelBorder: '#030D69',
        linkColor: '#030D69',
        dropdownTextColor: '#030d69',
        dropdownIconColor: '#304571',
        dropdownBgColor: '#ffffff',
        dropdownBorderColor: '#030D69',
    },
    transcript: {
        toolbarOuterBackground: '#ffffff',
        toolbarInnerBackground: 'transparent',
        titleColor: '#030D69',
        speakerColors: ['#030D69', '#030D69', '#030D69', '#030D69', '#030D69', '#030D69'],
        timestampColors: ['#4E4E5F', '#4E4E5F', '#4E4E5F', '#4E4E5F', '#4E4E5F', '#4E4E5F'],
        backgroundColor: '#ffffff',
        textColors: [
            { colorValue: '#020219', colorTitle: 'Black' },
            { colorValue: '#4C2C92', colorTitle: 'Purple' },
            { colorValue: '#046492', colorTitle: 'Blue' },
            { colorValue: '#174E22', colorTitle: 'Green' },
            { colorValue: '#981B1E', colorTitle: 'Red' },
            { colorValue: '#5A3A44', colorTitle: 'Brown' },
        ],
        textBackgroundColors: [
            { colorValue: '#ffffff', colorTitle: 'White' },
            { colorValue: '#B1FDFF', colorTitle: 'Teal' },
            { colorValue: '#DBE8C7', colorTitle: 'Green' },
            { colorValue: '#D0D9FC', colorTitle: 'Blue' },
            { colorValue: '#FAC6D7', colorTitle: 'Pink' },
            { colorValue: '#D2B48C', colorTitle: 'Tan' },
        ],
        loaderColor: '#000000',
        searchResultHighlight: 'rgba(40, 216, 197, 0.3)',
    },
    gradientButton: {
        gradient: '#030D69',
        backgroundColor: '#fff',
        hoverBgColor: '#f4f6fe',
        iconColor: '#030D69',
        textColor: '#030D69',
    },
    verbitButton: {
        primaryBackgroundColor: '#1a4af0',
        primaryHoverBgColor: '#486ef3',
        primaryBorderColor: '#1a4af0',
        primaryHoverBorderColor: '#1a4af0',
        primaryTextColor: '#ffffff',
        secondaryBackgroundColor: '#ffffff',
        secondaryHoverBgColor: '#ffffff',
        secondaryBorderColor: '#030D69',
        secondaryHoverBorderColor: '#030D69',
        secondaryTextColor: '#020219',
    },
    search: {
        outerBorderColor: '#ffffff',
        innerBorderColor: 'rgba(3, 13, 105, 0.50)',
        inputBgColor: '#f5f5f6',
        textColor: '#020219',
        placeholderColor: '#020219',
        clearButtonColor: '#020219',
        navButtonColor: '#020219',
        resultsColor: '#020219',
    },
    fontSizeControl: {
        backgroundColor: '#ffffff',
        iconColor: '#030D69',
        textColor: '#020219',
        optionHoverColor: '#F5F5F6',
        delimiterColor: '#e4e4e5',
    },
    themeToggle: {
        backgroundColor: '#ffffff',
        iconColor: '#030D69',
        textColor: '#030D69',
        titleColor: 'linear-gradient(270.09deg, #030d69 -2%, #194ddd 57.73%, #573aa6 96.88%)',
        colorOptionsBgColor: '#ffffff',
        fontOptionsBorderColor: '#E4E4E5',
        defaultColorIndicatorStroke: '#E4E4E5',
        colorIndicatorActiveColor: '#030D69',
        mobileColorIndicatorBorderColor: '#030D69',
        mobileButtonBorderColor: '#030D69',
        mobileActiveColor: '#1A4AF0',
    },
    languageSelect: {
        desktopTextColor: '#020219',
        mobileTextColor: '#1a4af0',
        iconColor: '#1a4af0',
        separatorColor: '#e4e4e5',
        mobileTitleColor: 'linear-gradient(270deg, #2b7abb -2%, #194ddd 44.65%, #573aa6 96.88%)',
        mobileBgColor: '#ffffff',
    },
    toast: {
        backgroundColor: '#ffffff',
        borderColor: '#030D69',
        textColor: '#030D69',
        successTextColor: '#008a24',
        actionColor: '#030D69',
        successIconColor: '#008a24',
        infoIconColor: '#030D69',
        closeIconColor: '#030D69',
        shadow: '0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2)',
    },
    scrollDownButton: {
        backgroundColor: '#ffffff',
        borderColor: '#ccccd1',
        shadow: '0px 4px 14px 6px rgba(0, 0, 0, 0.1)',
        iconColor: '#4E4E5F',
    },
    routingError: {
        backgroundColor: '#ffffff',
        textColor: '#000000',
        linkColor: '#106ba3',
    },
    confirmationOverlay: {
        backdropColor: 'rgba(255, 255, 255, 0.9)',
        panelShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.1)',
        panelBackground: '#ffffff',
        textColor: '#020219',
    },
}
