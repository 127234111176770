import { useCallback, useEffect, useRef, useState } from 'react'

import { Popover } from '@blueprintjs/core'
import { CaretDownIcon } from '@verbit-ai/icons-library'
import styled, { useTheme } from 'styled-components'
import { Overlay } from '@blueprintjs/core'
import { theme } from 'styled-tools'

import { analyticsService } from 'src/services/AnalyticsService'
import { useUserContext } from 'src/contexts/UserProvider'
import { SearchResult, fuzzySearch } from 'src/utils/search'
import { useTranscript } from 'src/contexts/TranscriptContext'
import { useSearchResultAPI } from 'src/contexts/SearchContext'
import { useResponsive } from 'src/hooks/useResponsive'
import { useBlueprintThemeClass } from 'src/hooks/useBlueprintThemeClass'
import { announceText } from 'src/utils/announce'

import { SearchField } from './SearchField'

const SearchPanel = styled.div`
    border: 4px solid ${theme('palette.search.outerBorderColor')};
    border-radius: 3px;
    padding: 8px;
    background-color: ${theme('palette.search.inputBgColor')};
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid ${theme('palette.search.innerBorderColor')};
    }

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        position: fixed;
        top: calc(${theme('sizes.headerHeight')}px + 10px);
        left: 20px;
        right: 20px;
        box-shadow: 0px 4px 14px 0px #0000001a;
        padding: 16px 8px;
    }
`

const SearchChevron = styled.button`
    position: relative;
    &.rotated {
        transform: rotate(180deg);
    }

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        svg {
            width: 18px;
            height: 18px;
        }
    }
`

const SearchResultsFound = styled.span`
    color: ${theme('palette.search.resultsColor')};
    font-size: 14px;
    line-height: 21px;
`

interface SearchProps {
    isOpen: boolean
    onToggle: (nextIsOpen: boolean) => void
    children?: React.ReactNode
}

export function Search({ isOpen, onToggle, children }: SearchProps) {
    const { user } = useUserContext()
    const transcript = useTranscript()
    const { isTablet } = useResponsive()
    const { selectSearchResult } = useSearchResultAPI()

    const [searchTerm, setSearchTerm] = useState('')
    const [results, setResults] = useState<SearchResult[]>([])
    const [activeItem, setActiveItem] = useState(0)
    const previousSearchTerm = useRef<string | undefined>()

    useEffect(() => {
        if (results.length) {
            announceText(`Result number ${activeItem + 1} out of ${results.length}`)
        }
    }, [activeItem, results.length])

    const {
        palette: {
            search: { navButtonColor },
        },
    } = useTheme()
    const popoverClassName = useBlueprintThemeClass()

    const onSearchTermChange = useCallback(
        (value: string) => {
            setSearchTerm(value)
            // when input is cleared, reset results automatically
            // (so that user doesn't have to hit Enter after clearing)
            if (!value.length) {
                setResults([])
                selectSearchResult(null)
            }
        },
        [selectSearchResult],
    )

    const onActiveItemChange = useCallback(
        (delta: 1 | -1) => {
            let nextActiveItem = activeItem + delta
            if (nextActiveItem < 0) {
                nextActiveItem = results.length - 1
            }
            if (nextActiveItem >= results.length) {
                nextActiveItem = 0
            }
            setActiveItem(nextActiveItem)
            selectSearchResult(results[nextActiveItem])
        },
        [activeItem, results, selectSearchResult],
    )
    const prevItem = useCallback(() => onActiveItemChange(-1), [onActiveItemChange])
    const nextItem = useCallback(() => onActiveItemChange(1), [onActiveItemChange])

    const onSearch = useCallback(
        (newSearchTerm: string) => {
            if (newSearchTerm.length) {
                // pressing Enter multiple times with the same term
                // cycles through results instead of doing new search
                if (newSearchTerm === previousSearchTerm.current) {
                    onActiveItemChange(1)

                    return
                }
                analyticsService.trackSearch({
                    user,
                    query: newSearchTerm,
                })
                const searchResults = fuzzySearch(transcript, newSearchTerm)
                setResults(searchResults)
                setActiveItem(0)
                selectSearchResult(searchResults[0])
                previousSearchTerm.current = newSearchTerm
            } else {
                setResults([])
                selectSearchResult(null)
            }
        },
        [user, transcript, selectSearchResult, onActiveItemChange],
    )

    const onPopoverInteraction = useCallback(
        (nextIsOpen: boolean) => {
            onToggle(nextIsOpen)
            if (!nextIsOpen) {
                setSearchTerm('')
                setResults([])
                setActiveItem(0)
                selectSearchResult(null)
                previousSearchTerm.current = undefined
            }
        },
        [onToggle, selectSearchResult],
    )
    const onOverlayClosed = useCallback(() => onPopoverInteraction(false), [onPopoverInteraction])

    const searchPanel = () => (
        <SearchPanel>
            <SearchField autoFocus value={searchTerm} onChange={onSearchTermChange} onSearch={onSearch} activeIndex={activeItem} />
            <SearchChevron className="rotated" tabIndex={0} aria-label="Previous" onClick={prevItem}>
                <CaretDownIcon color={navButtonColor} />
            </SearchChevron>
            <SearchChevron tabIndex={0} aria-label="Next" onClick={nextItem}>
                <CaretDownIcon color={navButtonColor} />
            </SearchChevron>
            <SearchResultsFound>
                {results.length ? activeItem + 1 : 0} of {results.length}
            </SearchResultsFound>
        </SearchPanel>
    )

    return isTablet ? (
        <Overlay isOpen={isOpen} onClose={onOverlayClosed} canEscapeKeyClose canOutsideClickClose hasBackdrop={false}>
            {searchPanel()}
        </Overlay>
    ) : (
        <Popover
            popoverClassName={popoverClassName}
            content={searchPanel()}
            interactionKind="click"
            isOpen={isOpen}
            onInteraction={onPopoverInteraction}
            placement="top-start">
            {children}
        </Popover>
    )
}
