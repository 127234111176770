import { useMemo } from 'react'
import { ListChildComponentProps } from 'react-window'

import styled, { css, useTheme } from 'styled-components'
import { ifProp, prop, theme } from 'styled-tools'

import { ASRTranscriptParagraph, Transcript, TranscriptParagraph } from 'src/models/Transcript'
import { useTranscript } from 'src/contexts/TranscriptContext'
import { countLines } from 'src/utils/text'
import { AI_PANEL_TABLET_HEIGHT, getTranscriptDimensions } from 'src/components/constants'
import { FontFamily } from 'src/themes/types'
import { Language } from 'src/models/Language'
import { isChromakeyModeActive } from 'src/utils/env'
import { useResponsive } from 'src/hooks/useResponsive'
import { useConfig } from 'src/contexts/ConfigurationContext'
import { FONT_SIZE_BASE } from 'src/models/UIConfiguration'

import { WordView } from './WordView'
import { PARAGRAPH_TIMESTAMP_FONT_SIZE_COEFF, PARAGRAPH_TIMESTAMP_LINE_HEIGHT_COEFF, ParagraphTimestamp } from './ParagraphTimestamp'
import { PARAGRAPH_AUTHOR_FONT_SIZE_COEFF, PARAGRAPH_AUTHOR_LINE_HEIGHT_COEFF, ParagraphAuthor } from './ParagraphAuthor'

const PARAGRAPH_LINE_HEIGHT_PROPORTION = 1.5
const TEXT_FONT_SIZE_PROPORTION = 1

const PARAGRAPH_VERTICAL_PADDING = 8
const MOBILE_PHONE_TEXT_HORIZONTAL_PADDING = 20

const METADATA_GAP = 4
const MOBILE_METADATA_GAP = 8

const Wrapper = styled.div`
    position: relative;
    transition: border-color 500ms ease-out;
    background-color: ${theme('palette.transcript.backgroundColor')};
`

const Container = styled.div<{ $fontFamily: string; $isFirst: boolean }>`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: 0 0 ${PARAGRAPH_VERTICAL_PADDING * 2}px;
    line-height: ${PARAGRAPH_LINE_HEIGHT_PROPORTION}em;
    font-family: ${prop('$fontFamily')};

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        flex-direction: column;
        align-items: center;
        justify-content: ${ifProp('$isFirst', 'flex-end', 'flex-start')};
    }

    @media (max-width: ${theme('breakPoints.mobilePhoneMaxWidth')}px) {
        width: 100%;
    }
`

const FakeParagraph = styled(Container)`
    position: relative;
    line-height: unset;
    padding: unset;
`

interface MetadataProps {
    $isChromakeyMode: boolean
    $offsetLeft: number
    $textMaxWidth: number
    $timestampWidth: number
}
const Metadata = styled.div<MetadataProps>`
    width: ${prop('$timestampWidth')}px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    gap: ${METADATA_GAP}px;
    font-family: inherit;
    margin-left: ${prop('$offsetLeft')}px;

    ${ifProp(
        '$isChromakeyMode',
        css`
            position: absolute;
            top: 0;
            left: 50%;
            padding: 0;
        `,
    )}

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        width: ${prop('$textMaxWidth')}px;
        padding-left: 0;
        flex-direction: row;
        margin-bottom: ${MOBILE_METADATA_GAP}px;
        gap: 8px;

        ${ifProp(
            '$isChromakeyMode',
            css`
                top: ${PARAGRAPH_LINE_HEIGHT_PROPORTION}em;
            `,
        )}
    }

    @media (max-width: ${theme('breakPoints.mobilePhoneMaxWidth')}px) {
        width: 100%;
        padding-left: ${MOBILE_PHONE_TEXT_HORIZONTAL_PADDING}px;
        padding-right: ${MOBILE_PHONE_TEXT_HORIZONTAL_PADDING}px;

        ${ifProp(
            '$isChromakeyMode',
            css`
                left: 0;
                padding-left: 26px;
            `,
        )}
    }
`

const MetadataSeparator = styled.span`
    display: none;
    width: 1px;
    height: 100%;
    background-color: #91919d;

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        display: inline-block;
    }
`

const Text = styled.div<{ $color: string; $backgroundColor: string; $textMaxWidth: number }>`
    width: ${prop('$textMaxWidth')}px;
    font-family: inherit;
    font-size: ${TEXT_FONT_SIZE_PROPORTION}em;
    color: ${prop('$color')};
    background-color: ${prop('$backgroundColor')};
    transition: margin-left 250ms ease-out;

    @media (max-width: ${theme('breakPoints.mobilePhoneMaxWidth')}px) {
        width: 100%;
        padding: 0 ${MOBILE_PHONE_TEXT_HORIZONTAL_PADDING}px;
    }
`

type Props = ListChildComponentProps<{
    containerWidth: number
    fontSize: number
}>

export const ParagraphView = ({ index, style, data }: Props) => {
    const transcript = useTranscript()
    const {
        fontFamily,
        textColorIndex,
        textBackgroundColorIndex,
        palette: {
            transcript: { textColors, textBackgroundColors },
        },
    } = useTheme()
    const textColor = textColors[textColorIndex]
    const textBgColor = textBackgroundColors[textBackgroundColorIndex]
    const [{ fontSizePercentage, isFullWidthMode }] = useConfig()

    const { isMobilePhone } = useResponsive()
    const isChromakeyMode = isChromakeyModeActive()
    const isTimestampVisible = !isChromakeyMode
    const { textMaxWidth, timestampWidth } = getTranscriptDimensions({
        isDiarizationSupported: transcript.isDiarizationSupported,
        fontSizePercentage,
        isFullWidthMode,
        containerWidth: data.containerWidth,
    })
    const metadataOffsetLeft = isChromakeyMode ? (isMobilePhone ? -data.fontSize * 1.5 : -(textMaxWidth / 2 + data.fontSize * 1.5)) : 0

    const paragraph = Transcript.getParagraphByIndex(transcript, index)
    const paragraphWords = transcript.words[paragraph?.id]
    const author = transcript.authors[paragraph?.authorId]

    const text = useMemo(
        () =>
            paragraphWords?.map((word, wordIndex) => (
                <WordView key={`${wordIndex}${word.text}`} word={word} index={wordIndex} isFirst={wordIndex === 0} />
            )),
        [paragraphWords],
    )

    if (!paragraph) {
        return <FakeParagraph style={style} $fontFamily={fontFamily} $isFirst={false} />
    }

    return (
        <Wrapper style={style}>
            <Container $fontFamily={fontFamily} $isFirst={index === 0}>
                <Metadata
                    $isChromakeyMode={isChromakeyMode}
                    $offsetLeft={metadataOffsetLeft}
                    $textMaxWidth={textMaxWidth}
                    $timestampWidth={timestampWidth}>
                    <ParagraphAuthor author={author} />
                    {isTimestampVisible && (
                        <>
                            <MetadataSeparator />
                            <ParagraphTimestamp timeAbsolute={paragraph.timeAbsolute} timeRelative={paragraph.time} />
                        </>
                    )}
                </Metadata>
                <Text $color={textColor.colorValue} $backgroundColor={textBgColor.colorValue} $textMaxWidth={textMaxWidth}>
                    {text}
                </Text>
            </Container>
        </Wrapper>
    )
}

interface GetParagraphHeightDto {
    canvas: HTMLCanvasElement
    paragraph: TranscriptParagraph | ASRTranscriptParagraph
    paragraphIndex: number
    fontSizePercentage: number
    fontFamily: FontFamily
    width: number
    isTablet: boolean
    isMobilePhone: boolean
    isAiPanelVisible: boolean
    language: Language
    isChromakeyMode: boolean
    isDiarizationSupported: boolean
    isFullWidthMode: boolean
}

export const getParagraphHeight = ({
    canvas,
    paragraph,
    paragraphIndex,
    fontSizePercentage,
    fontFamily,
    width,
    isTablet,
    isMobilePhone,
    isAiPanelVisible,
    language,
    isChromakeyMode,
    isDiarizationSupported,
    isFullWidthMode,
}: GetParagraphHeightDto) => {
    const textVerticalPadding = isMobilePhone ? MOBILE_PHONE_TEXT_HORIZONTAL_PADDING : 0
    const isAdditionalPaddingRequired = isAiPanelVisible && isTablet && paragraphIndex === 0
    const additionalPadding = isAdditionalPaddingRequired ? AI_PANEL_TABLET_HEIGHT + PARAGRAPH_VERTICAL_PADDING : 0

    const { textMaxWidth } = getTranscriptDimensions({
        isDiarizationSupported,
        fontSizePercentage,
        isFullWidthMode,
        containerWidth: width,
    })
    const textWidth = (isMobilePhone ? width : Math.min(textMaxWidth, width)) - 2 * textVerticalPadding

    const fontSize = Math.round((FONT_SIZE_BASE * fontSizePercentage) / 100)
    const ctx = canvas.getContext('2d')!
    ctx.font = `${fontSize}px ${fontFamily}, sans-serif`

    let linesAmount = countLines({ ctx, words: paragraph.words, maxWidth: textWidth, language })
    if (isChromakeyMode) {
        // as per requirements, in chromakey mode there should be one extra line of distance between blocks
        linesAmount += 1
    }

    const contentHeight = linesAmount * (fontSize * PARAGRAPH_LINE_HEIGHT_PROPORTION) + PARAGRAPH_VERTICAL_PADDING * 2 + additionalPadding

    if (isChromakeyMode) {
        return contentHeight
    }

    // height of the metadata block (author name + timestamp)
    let metadataHeight = fontSize * PARAGRAPH_AUTHOR_FONT_SIZE_COEFF * PARAGRAPH_AUTHOR_LINE_HEIGHT_COEFF
    if (isTablet || isMobilePhone) {
        metadataHeight += MOBILE_METADATA_GAP
    } else {
        metadataHeight +=
            METADATA_GAP +
            fontSize * PARAGRAPH_TIMESTAMP_FONT_SIZE_COEFF * PARAGRAPH_TIMESTAMP_LINE_HEIGHT_COEFF +
            PARAGRAPH_VERTICAL_PADDING * 2
    }

    return isTablet || isMobilePhone ? contentHeight + metadataHeight : Math.max(contentHeight, metadataHeight)
}
