import { RefObject } from 'react'

export const scrollTo = (
    targetScrollTop: number,
    speedInPixelPerSecond: number,
    element: HTMLElement | null,
    shouldCancel?: RefObject<boolean> | (() => boolean),
) => {
    if (element === null) return Promise.resolve()

    const duration = 1000 * (Math.abs(targetScrollTop - element.scrollTop) / speedInPixelPerSecond)
    const totalScrollDistance = targetScrollTop - element.scrollTop

    return new Promise<void>((resolve, reject) => {
        if (element.scrollTop === targetScrollTop) return resolve()

        let scrollY = element.scrollTop
        let oldTimestamp = Date.now()

        const step = () => {
            const newTimestamp = Date.now()

            if (typeof shouldCancel === 'function' ? shouldCancel() : shouldCancel?.current) {
                return reject('Autoscroll Canceled')
            }

            const maxScrollTop = element.scrollHeight - element.clientHeight
            const safeTargetScrollTop = Math.max(0, Math.min(maxScrollTop, targetScrollTop))
            const prevElementScrollTop = element.scrollTop

            scrollY += (totalScrollDistance * (newTimestamp - oldTimestamp)) / duration
            scrollY = totalScrollDistance > 0 ? Math.min(safeTargetScrollTop, scrollY) : Math.max(safeTargetScrollTop, scrollY)
            element.scrollTop = scrollY

            if (
                element.scrollTop === safeTargetScrollTop ||
                // TODO: explain
                (element.scrollTop === prevElementScrollTop && Math.abs(element.scrollTop - targetScrollTop) < 1)
            ) {
                return resolve()
            }

            oldTimestamp = newTimestamp
            requestAnimationFrame(step)
        }

        requestAnimationFrame(step)
    })
}

export const scrollBy = (
    delta: number,
    speedInPixelPerSecond: number,
    element: HTMLElement | null,
    shouldCancel?: RefObject<boolean> | (() => boolean),
) => scrollTo((element?.scrollTop ?? 0) + delta, speedInPixelPerSecond, element, shouldCancel)

export const scrollToBottomEdge = (
    speedInPixelPerSecond: number,
    element: HTMLElement | null,
    shouldCancel?: RefObject<boolean> | (() => boolean),
) => {
    const { scrollHeight = 0, clientHeight = 0 } = element || {}
    const targetScrollTop = scrollHeight - clientHeight

    return scrollTo(targetScrollTop, speedInPixelPerSecond, element, shouldCancel)
}
