import { Overlay } from '@blueprintjs/core'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { getMobileOverlayTransitionCss } from 'src/global-styles'

import { LanguagesList } from './LanguagesList'
import { LanguageSelectProps } from './types'

const OVERLAY_TRANSITION_NAME = 'language-select-transition'
const OVERLAY_TRANSITION_DURATION = 300

const LanguageSelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme('palette.languageSelect.mobileBgColor')};
    padding: 24px 24px 32px;

    ${getMobileOverlayTransitionCss(OVERLAY_TRANSITION_NAME, OVERLAY_TRANSITION_DURATION)}
`

const LanguageSelectTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    background: ${theme('palette.languageSelect.mobileTitleColor')};
    padding: 16px 16px 8px;

    background-clip: text;
    -webkit-background-clip: text;

    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
`

export function LanguageSelectMobile({ isOpen, onClose }: LanguageSelectProps) {
    return (
        <Overlay
            isOpen={isOpen}
            onClose={onClose}
            canEscapeKeyClose
            canOutsideClickClose
            hasBackdrop
            transitionName={OVERLAY_TRANSITION_NAME}
            transitionDuration={OVERLAY_TRANSITION_DURATION}
            lazy={false}>
            <LanguageSelectContainer>
                <LanguageSelectTitle>Select language</LanguageSelectTitle>
                <LanguagesList />
            </LanguageSelectContainer>
        </Overlay>
    )
}
