import { VerbitLogo } from '@verbit-ai/icons-library'

import { Palette } from './types'

export const paletteDark: Palette = {
    global: {
        focusIndicatorColor: '#40E0D0',
        iconColor: '#ffffff',
    },
    header: {
        logo: <VerbitLogo theme="dark" />,
        titleColor: '#ffffff',
        panelBackgroundColor: '#020219',
        panelFilter: 'none',
        panelBorder: 'transparent transparent #757584',
        linkColor: '#ffffff',
        dropdownTextColor: '#ffffff',
        dropdownIconColor: '#ffffff',
        dropdownBgColor: '#4E4E5F',
        dropdownBorderColor: 'transparent',
    },
    transcript: {
        toolbarOuterBackground: '#020219',
        toolbarInnerBackground: 'linear-gradient(180deg, #070707 0%, rgba(0, 0, 0, 0.00) 100%)',
        titleColor: '#40E0D0',
        speakerColors: ['#40E0D0', '#40E0D0', '#40E0D0', '#40E0D0', '#40E0D0', '#40E0D0'],
        timestampColors: ['#E4E4E5', '#E4E4E5', '#E4E4E5', '#E4E4E5', '#E4E4E5', '#E4E4E5'],
        backgroundColor: '#020219',
        textColors: [
            { colorValue: '#ffffff', colorTitle: 'White' },
            { colorValue: '#F4D7FA', colorTitle: 'Pink' },
            { colorValue: '#4BFBE4', colorTitle: 'Teal' },
            { colorValue: '#E1F2D5', colorTitle: 'Sage' },
            { colorValue: '#FFCC69', colorTitle: 'Orange' },
            { colorValue: '#EFFF76', colorTitle: 'Lime' },
        ],
        textBackgroundColors: [
            { colorValue: '#020219', colorTitle: 'Black' },
            { colorValue: '#046492', colorTitle: 'Blue' },
            { colorValue: '#697A7A', colorTitle: 'Green' },
            { colorValue: '#A52F18', colorTitle: 'Brown' },
            { colorValue: '#4044C6', colorTitle: 'Purple' },
            { colorValue: '#C02469', colorTitle: 'Pink' },
        ],
        loaderColor: '#40E0D0',
        searchResultHighlight: '#757584',
    },
    gradientButton: {
        gradient:
            'linear-gradient(85.75deg, rgba(26, 74, 240, 0.8) -16.77%, rgba(0, 194, 255, 0.8) 47.36%, rgba(40, 216, 197, 0.8) 108.59%)',
        backgroundColor: '#020219',
        hoverBgColor: '#04042f',
        iconColor: '#ffffff',
        textColor: '#ffffff',
    },
    verbitButton: {
        primaryBackgroundColor: '#40E0D0',
        primaryHoverBgColor: '#2196F3',
        primaryBorderColor: '#40E0D0',
        primaryHoverBorderColor: '#40E0D0',
        primaryTextColor: '#030D69',
        secondaryBackgroundColor: 'transparent',
        secondaryHoverBgColor: 'transparent',
        secondaryBorderColor: '#40E0D0',
        secondaryHoverBorderColor: '#40E0D0',
        secondaryTextColor: '#40E0D0',
    },
    search: {
        outerBorderColor: '#30404d',
        innerBorderColor: '#757584',
        inputBgColor: '#020219',
        textColor: '#ffffff',
        placeholderColor: '#ffffff',
        clearButtonColor: '#ffffff',
        navButtonColor: '#ffffff',
        resultsColor: '#ffffff',
    },
    fontSizeControl: {
        backgroundColor: '#30404d',
        iconColor: '#ffffff',
        textColor: '#ffffff',
        optionHoverColor: '#293743',
        delimiterColor: '#e2e8f0',
    },
    themeToggle: {
        backgroundColor: '#30404d',
        iconColor: '#40E0D0',
        textColor: '#ffffff',
        titleColor: '#40E0D0',
        colorOptionsBgColor: '#020219',
        fontOptionsBorderColor: '#757584',
        defaultColorIndicatorStroke: '#757584',
        colorIndicatorActiveColor: '#ffffff',
        mobileColorIndicatorBorderColor: '#B3B3BA',
        mobileButtonBorderColor: '#CCCCD1',
        mobileActiveColor: '#40E0D0',
    },
    languageSelect: {
        desktopTextColor: '#ffffff',
        mobileTextColor: '#FFFFFF',
        iconColor: '#40E0D0',
        separatorColor: '#E4E4E5',
        mobileTitleColor: '#40E0D0',
        mobileBgColor: '#4E4E5F',
    },
    toast: {
        backgroundColor: '#020219',
        borderColor: '#757584',
        textColor: '#ffffff',
        successTextColor: '#40E0D0',
        actionColor: '#40E0D0',
        successIconColor: '#40E0D0',
        infoIconColor: '#ffffff',
        closeIconColor: '#ffffff',
        shadow: '0px 4px 14px 0px rgba(255, 255, 255, 0.20)',
    },
    scrollDownButton: {
        backgroundColor: '#020219',
        borderColor: '#757584',
        shadow: '0px 2px 10px 4px rgb(255 255 255 / 10%)',
        iconColor: '#ffffff',
    },
    routingError: {
        backgroundColor: '#020219',
        textColor: '#ffffff',
        linkColor: '#40E0D0',
    },
    confirmationOverlay: {
        backdropColor: 'rgb(48 64 77 / 90%)',
        panelShadow: '0px 4px 14px 0px rgba(255, 255, 255, 0.1)',
        panelBackground: '#020219',
        textColor: '#ffffff',
    },
}
