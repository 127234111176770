import { isMockActive } from 'src/utils/env'
import { HttpClient } from 'src/network/HttpClient'
import { MockHttpClient } from 'src/mocks/MockHttpClient'
import { MockAiServiceClient } from 'src/mocks/MockAiServiceClient'
import { extname } from 'src/utils/extname'

import { AiServiceClient } from './AiServiceClient'

const urlParams = new URLSearchParams(window.location.search)
const mock = urlParams.get('mocks')!

const shouldUseMocks = isMockActive() && extname(mock) === 'json'

export const httpClient = shouldUseMocks ? new MockHttpClient() : new HttpClient()
export const aiServiceClient = shouldUseMocks ? new MockAiServiceClient() : new AiServiceClient()
