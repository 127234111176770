import { useCallback } from 'react'

import { Icon } from '@blueprintjs/core'
import styled from 'styled-components'

const RecordIconWrapper = styled.div`
    color: red;
    animation: blink 2s linear 0s infinite;

    @keyframes blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }
`

const Button = styled.div`
    margin-left: 8px;
    cursor: pointer;
`

export function MockRecordController() {
    const onDownload = useCallback(() => {
        document.mocks.downloadMock()
    }, [])

    return (
        <>
            <RecordIconWrapper>
                <Icon icon="record" />
            </RecordIconWrapper>
            <Button onClick={onDownload}>
                <Icon icon="download" /> Download Mock file
            </Button>
        </>
    )
}
