import React, { useCallback, useEffect, useRef } from 'react'

import styled, { useTheme } from 'styled-components'
import { RemoveIcon } from '@verbit-ai/icons-library'
import { theme } from 'styled-tools'

const Wrapper = styled.div`
    display: flex;
    position: relative;

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        flex: 1;
    }
`

const TextField = styled.input`
    width: 195px;
    outline: none;
    padding: 0 16px 0 0;
    border: 0;
    color: ${theme('palette.search.textColor')};
    font-size: 14px;
    line-height: 21px;
    background-color: transparent;

    &::placeholder {
        color: ${theme('palette.search.placeholderColor')};
    }

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        width: 100%;
    }
`

const Label = styled.label`
    display: block;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    overflow: hidden;
`

const ClearButton = styled.button`
    position: absolute;
    top: 2px;
    right: 0;

    background: transparent;
    border: 0;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
`

interface SearchFieldProps {
    value: string
    onChange: (value: string) => unknown
    onSearch: (value: string) => unknown
    onFocus?: () => unknown
    onBlur?: () => unknown
    autoFocus?: boolean
    activeIndex: number | null
}

export function SearchField({ value, onChange, onSearch, onFocus, onBlur, autoFocus }: SearchFieldProps) {
    const ref = useRef<HTMLInputElement | null>(null)
    const didAutoFocusRef = useRef(false)
    const didClearRef = useRef(false)
    const {
        palette: {
            search: { clearButtonColor },
        },
    } = useTheme()

    const onTextChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value)
        },
        [onChange],
    )

    const onClearSearch = useCallback(() => {
        onChange('')
        didClearRef.current = true

        setTimeout(() => ref.current?.focus())
        setTimeout(() => (didClearRef.current = false), 200)
    }, [onChange])

    const onClearSearchEnter = useCallback(
        (e: React.KeyboardEvent<HTMLButtonElement>) => {
            if (e.key === 'Enter') {
                onClearSearch()
            }
        },
        [onClearSearch],
    )

    const onEnter = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter' && !didClearRef.current) {
                onSearch(value)
            }
        },
        [onSearch, value],
    )

    const onTextFocus = useCallback(() => {
        if (!didClearRef.current) {
            onFocus?.()
        }
    }, [onFocus])

    const onTextBlur = useCallback(() => {
        onBlur?.()
    }, [onBlur])

    useEffect(() => {
        if (autoFocus && ref.current && !didAutoFocusRef.current) {
            ref.current.focus()
            didAutoFocusRef.current = true
        }
    }, [autoFocus])

    return (
        <Wrapper>
            <Label htmlFor="search-input">Search Transcript</Label>
            <TextField
                ref={ref}
                value={value}
                type="search"
                id="search-input"
                className="hide-clear"
                autoComplete="off"
                placeholder="Find"
                onChange={onTextChange}
                onKeyUp={onEnter}
                onFocus={onTextFocus}
                onBlur={onTextBlur}
            />
            {!!value.length && (
                <ClearButton type="reset" tabIndex={0} aria-label="Clear" onClick={onClearSearch} onKeyDown={onClearSearchEnter}>
                    <RemoveIcon color={clearButtonColor} />
                </ClearButton>
            )}
        </Wrapper>
    )
}
