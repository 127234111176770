export const PROMPT_TEMPLATE_DETAILS_FIELDS = [
    'main_task_definition',
    'guidelines',
    'vertical_explanation',
    'format',
    'examples',
    'max_tokens_for_answer',
] as const
export type PromptTemplateDetailsField = (typeof PROMPT_TEMPLATE_DETAILS_FIELDS)[number]
export type PromptTemplateDetails = {
    [key in PromptTemplateDetailsField]: string
}

export interface PromptInternalTestParams {
    prompt: string
    customer_id?: number
    number_of_transcripts?: number
    job_id?: string
    prompt_details: Partial<PromptTemplateDetails>
    segment?: boolean
}

export interface PromptInternalTestResponse {
    request_id: string
}
