import { LiveEventPlayerIteratorOptions } from 'src/mocks/LiveEventPlayer'
import { StreamMessage, StreamSourceBase, StreamSourceInterface } from 'src/network/StreamClient'

interface StreamMockSourceOptions {
    delayConfig?: LiveEventPlayerIteratorOptions
}

export class StreamMockSource extends StreamSourceBase implements StreamSourceInterface {
    private delayConfig: LiveEventPlayerIteratorOptions | undefined

    constructor({ delayConfig }: StreamMockSourceOptions) {
        super()

        this.delayConfig = delayConfig

        this.start()
    }

    private async start() {
        await document.mocks.isReady()
        const eventPlayer = document.mocks.getLiveEventPlayer()

        if (!eventPlayer) return

        for await (const event of eventPlayer.iterator(this.delayConfig)) {
            this.send(event)
        }
    }

    // because its a mock, we don't need to subscribe to anything
    subscribe() {
        return
    }

    // because its a mock, we don't need to unsubscribe anything
    unsubscribe() {
        return
    }

    close() {}

    async history<T extends StreamMessage['action']>() {
        await document.mocks.isReady()

        return document.mocks.getAsrHistory() as Extract<StreamMessage, { action: T }>[]
    }
}
