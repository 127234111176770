import { useMediaQuery } from 'react-responsive'

import { useTheme } from 'styled-components'

// TODO: refactor to use enum instead
export function useResponsive() {
    const theme = useTheme()

    const isMobilePhone = useMediaQuery({ query: `(max-width: ${theme.breakPoints.mobilePhoneMaxWidth}px)` })
    const isTablet = useMediaQuery({ query: `(max-width: ${theme.breakPoints.tabletMaxWidth}px)` })
    const isLaptop = useMediaQuery({ query: `(max-width: ${theme.breakPoints.laptopMaxWidth}px)` })
    const isDesktop = !isMobilePhone && !isTablet && !isLaptop

    return { isMobilePhone, isTablet, isLaptop, isDesktop }
}
