import { ReactNode } from 'react'

import styled from 'styled-components'
import { theme } from 'styled-tools'
import { Header2 as VerbitHeader } from '@verbit-ai/verbit-ui-library'
import { LogoutIcon, VerbitLogo } from '@verbit-ai/icons-library'

import { useLogout, useUserContext } from 'src/contexts/UserProvider'

const TopBar = styled.div`
    width: 100%;
    height: ${theme('sizes.headerHeight')}px;
    z-index: ${theme('zIndices.appHeader')};
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
`

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

interface InternalTestingTemplateProps {
    header: string
    children: ReactNode
}

export function InternalTestingTemplate({ header, children }: InternalTestingTemplateProps) {
    const { user } = useUserContext()
    const logout = useLogout()

    return (
        <Container>
            <TopBar>
                <VerbitHeader>
                    <VerbitHeader.Logo>
                        <VerbitLogo theme="light" />
                    </VerbitHeader.Logo>
                    <VerbitHeader.Title>{header}</VerbitHeader.Title>
                    <VerbitHeader.Nav sx={{ marginLeft: 'auto' }}>
                        <VerbitHeader.Dropdown2
                            text={user?.userName || user?.email}
                            options={[
                                {
                                    value: 'sign-out',
                                    label: 'Log Out',
                                    icon: <LogoutIcon />,
                                    onClick: logout,
                                },
                            ]}
                            styles={{
                                menu: () => ({
                                    position: 'absolute',
                                    right: 0,
                                }),
                            }}
                        />
                    </VerbitHeader.Nav>
                </VerbitHeader>
            </TopBar>
            {children}
        </Container>
    )
}
