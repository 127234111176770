import { getAiServiceServerUrl } from 'src/utils/env'
import { PromptInternalTestParams, PromptInternalTestResponse } from 'src/models/Prompt'

import { HttpClientBase } from './HttpClientBase'
import { AiServiceClientInterface } from './AiServiceClientInterface'

export class AiServiceClient extends HttpClientBase implements AiServiceClientInterface {
    constructor() {
        super({
            baseURL: `${getAiServiceServerUrl()}/api/v1/`,
        })
    }

    async testPromptInit(data: PromptInternalTestParams): Promise<PromptInternalTestResponse> {
        const response = await this.post<PromptInternalTestResponse>('/test/prompt', {
            data,
            withCredentials: true,
            requiresAuth: true,
        })

        return response
    }

    async testPromptFetchCsv(uuid: string): Promise<string> {
        const response = await this.get<string>(`/test/fetch_csv/${uuid}`, { withCredentials: true, requiresAuth: true })

        return response
    }
}
