import mixpanel from 'mixpanel-browser'

import { User } from 'src/models/User'
import { isJobLive, SessionDeposition, TranscriptMetadata } from 'src/models/TranscriptMetadata'
import { getMixpanelApiKey } from 'src/utils/env'
import {
    AnalyticsMandatoryEventFields,
    AnalyticsTrackDownloadDto,
    AnalyticsTrackPageVisitDto,
    AnalyticsTrackSearchDto,
} from 'src/models/Analytics'

import { ServiceBase } from './ServiceBase'

class AnalyticsService extends ServiceBase {
    private mandatoryEventFields: AnalyticsMandatoryEventFields = {
        customerId: null,
        customerName: null,
        jobType: null,
    }

    constructor() {
        super({ blockedEnvs: ['dev'] })
    }

    init() {
        mixpanel.init(getMixpanelApiKey()!)
    }

    identify(id: string) {
        mixpanel.identify(id)
    }

    track(event: string, props: Record<string, unknown>) {
        mixpanel.track(event, {
            ...props,
            ...this.mandatoryEventFields,
        })
    }

    updateUserProps({ userName, email, companyName, ...userProps }: User) {
        mixpanel.people.set({
            $name: userName,
            $email: email,
            $companyName: companyName,
            ...userProps,
        })
    }

    setMandatoryEventFields(metadata: TranscriptMetadata) {
        this.mandatoryEventFields = {
            customerId: metadata.customerId,
            customerName: metadata.customerName,
            jobType: isJobLive(metadata) ? 'live' : 'post-production',
        }
    }

    trackPageVisit({ user, sessionState, isZoomAppsEnv, downloadable, isAiPanelAvailable }: AnalyticsTrackPageVisitDto) {
        const now = new Date()
        let visitTime = 'after_session_ended'
        if (sessionState?.deposition && sessionState?.startTime) {
            visitTime = [SessionDeposition.FINISHED, SessionDeposition.CANCELED].includes(sessionState.deposition)
                ? 'after_session_ended'
                : now >= sessionState.startTime
                  ? 'during_session'
                  : 'before_session_started'
        }

        this.track('Verbitext 2.0 - page view', {
            isGuest: !user,
            isZoomAppsEnv: isZoomAppsEnv,
            email: user?.email,
            isDownloadButtonVisible: !isZoomAppsEnv && downloadable ? true : false,
            visitTime,
            isAiPanelAvailable,
        })
    }

    trackSearch({ user, query }: AnalyticsTrackSearchDto) {
        this.track('Verbitext 2.0 - user search for terms', {
            email: user?.email,
            searchTerm: query,
        })
    }

    trackDownload({ sessionState, sessionId }: AnalyticsTrackDownloadDto) {
        const now = new Date()
        let downloadTime = 'after_session_ended'
        if (sessionState?.deposition && sessionState?.startTime) {
            downloadTime = [SessionDeposition.FINISHED, SessionDeposition.CANCELED].includes(sessionState.deposition)
                ? 'after_session_ended'
                : now >= sessionState.startTime
                  ? 'during_session'
                  : 'before_session_started'
        }
        this.track('Verbitext 2.0 - user downloaded transcript', {
            $sessionId: sessionId,
            downloadTime,
        })
    }
}

export const analyticsService = new AnalyticsService()
