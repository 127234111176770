import { Language, LanguageWithSpecialFont } from 'src/models/Language'

interface ThemeBreakpoints {
    laptopMaxWidth: number
    laptopMinWidth: number
    tabletMaxWidth: number
    mobilePhoneMaxWidth: number
}

interface ThemeZIndices {
    appHeader: number
    trancriptToolbar: number
}

interface ThemeSizes {
    headerHeight: number
    trancriptToolbarHeight: number
}

export interface ThemeCore {
    breakPoints: ThemeBreakpoints
    zIndices: ThemeZIndices
    sizes: ThemeSizes
}

interface TextColorDescriptor {
    colorValue: string
    colorTitle: string
}

export const TEXT_COLORS_AMOUNT = 6
export const DEFAULT_TEXT_COLOR_INDEX = 0

export const TEXT_BACKGROUND_COLORS_AMOUNT = 6
export const DEFAULT_TEXT_BACKGROUND_COLOR_INDEX = 0

interface PaletteGlobal {
    focusIndicatorColor: string
    iconColor: string
}

interface PaletteHeader {
    logo: JSX.Element
    titleColor: string
    panelBackgroundColor: string
    panelFilter: string
    panelBorder: string
    linkColor: string
    dropdownTextColor: string
    dropdownIconColor: string
    dropdownBgColor: string
    dropdownBorderColor: string
}

interface PaletteTranscript {
    toolbarOuterBackground: string
    toolbarInnerBackground: string
    titleColor: string
    // speaker and timestamp colors actually only change in green chromakey mode,
    // so for other themes they are gonna be the same color TEXT_COLORS_AMOUNT times
    speakerColors: Tuple<string, typeof TEXT_COLORS_AMOUNT>
    timestampColors: Tuple<string, typeof TEXT_COLORS_AMOUNT>
    backgroundColor: string
    textColors: Tuple<TextColorDescriptor, typeof TEXT_COLORS_AMOUNT>
    textBackgroundColors: Tuple<TextColorDescriptor, typeof TEXT_BACKGROUND_COLORS_AMOUNT>
    loaderColor: string
    searchResultHighlight: string
}

interface PaletteGradientButton {
    gradient: string
    backgroundColor: string
    hoverBgColor: string
    iconColor: string
    textColor: string
}

interface PaletteVerbitButton {
    primaryBackgroundColor: string
    primaryHoverBgColor: string
    primaryBorderColor: string
    primaryHoverBorderColor: string
    primaryTextColor: string
    secondaryBackgroundColor: string
    secondaryHoverBgColor: string
    secondaryBorderColor: string
    secondaryHoverBorderColor: string
    secondaryTextColor: string
}

interface PaletteSearch {
    outerBorderColor: string
    innerBorderColor: string
    inputBgColor: string
    textColor: string
    placeholderColor: string
    clearButtonColor: string
    navButtonColor: string
    resultsColor: string
}

interface PaletteFontSizeControl {
    backgroundColor: string
    iconColor: string
    textColor: string
    optionHoverColor: string
    delimiterColor: string
}

interface PaletteThemeToggle {
    backgroundColor: string
    iconColor: string
    textColor: string
    titleColor: string
    colorOptionsBgColor: string
    fontOptionsBorderColor: string
    defaultColorIndicatorStroke: string
    colorIndicatorActiveColor: string
    mobileColorIndicatorBorderColor: string
    mobileButtonBorderColor: string
    mobileActiveColor: string
}

interface PaletteLanguageSelect {
    desktopTextColor: string
    mobileTextColor: string
    iconColor: string
    separatorColor: string
    mobileTitleColor: string
    mobileBgColor: string
}

interface PaletteToast {
    backgroundColor: string
    borderColor: string
    textColor: string
    successTextColor: string
    actionColor: string
    successIconColor: string
    infoIconColor: string
    closeIconColor: string
    shadow: string
}

interface PaletteScrollDownButton {
    backgroundColor: string
    borderColor: string
    shadow: string
    iconColor: string
}

interface PaletteRoutingError {
    backgroundColor: string
    textColor: string
    linkColor: string
}

interface PaletteConfirmationOverlay {
    backdropColor: string
    panelShadow: string
    panelBackground: string
    textColor: string
}

export interface Palette {
    global: PaletteGlobal
    header: PaletteHeader
    transcript: PaletteTranscript
    gradientButton: PaletteGradientButton
    verbitButton: PaletteVerbitButton
    search: PaletteSearch
    fontSizeControl: PaletteFontSizeControl
    themeToggle: PaletteThemeToggle
    languageSelect: PaletteLanguageSelect
    toast: PaletteToast
    scrollDownButton: PaletteScrollDownButton
    routingError: PaletteRoutingError
    confirmationOverlay: PaletteConfirmationOverlay
}

// note: these values should match with `font-family` names provided
// in `@font-face` rules in `index.css`
export const FONT_FAMILIES = ['Arial', 'Inter', 'Roboto', 'SourceSerif', 'NotoSansSC', 'NotoSansJP', 'NotoSansKR', 'Hind'] as const

export type FontFamily = (typeof FONT_FAMILIES)[number]

const FONT_FAMILIES_FOR_SPECIAL_FONTS: Record<LanguageWithSpecialFont, FontFamily> = {
    [Language.Chinese]: 'NotoSansSC',
    [Language.Japanese]: 'NotoSansJP',
    [Language.Korean]: 'NotoSansKR',
    [Language.Hindi]: 'Hind',
}
export const DEFAULT_FONT_FAMILY_BY_LANGUAGE: Record<Language, FontFamily> = {
    // for the majority of languages, default font is Inter
    ...Object.values(Language).reduce((acc, curr) => Object.assign(acc, { [curr]: 'Inter' }), {} as Record<Language, FontFamily>),
    // "exotic" languages, not supported by Inter, have their own dedicated fonts
    ...FONT_FAMILIES_FOR_SPECIAL_FONTS,
}

export interface Theme extends ThemeCore {
    palette: Palette
    fontFamily: FontFamily
    availableFontFamilies: FontFamily[]
    textColorIndex: number
    textBackgroundColorIndex: number
    themeType: ThemeType
}

export const THEME_TYPES = ['default', 'dark', 'highContrast', 'chromakey'] as const

export type ThemeType = (typeof THEME_TYPES)[number]

export const DEFAULT_THEME_TYPE: ThemeType = 'default'

export type ConfigurableThemeFields = Pick<Theme, 'themeType' | 'fontFamily' | 'textColorIndex' | 'textBackgroundColorIndex'>
