import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { usePrevious } from 'src/hooks/usePrevious'
import { SearchResult } from 'src/utils/search'

import { useTranscriptViewerAPI } from './TranscriptViewerProvider'

const SearchContext = React.createContext<{
    searchResult: SearchResult | null
    selectSearchResult: (result: SearchResult | null) => unknown
    hasSeenSearchResult: boolean
    markSearchResultAsSeen: () => unknown
} | null>(null)

export interface SearchProviderProps {
    children: React.ReactNode
}

export function SearchProvider({ children }: SearchProviderProps) {
    const [searchResult, setSearchResult] = useState<SearchResult | null>(null)
    const prevSearchResult = usePrevious(searchResult)
    const [hasSeenSearchResult, setHasSeenSearchResult] = useState<boolean>(false)
    const markSearchResultAsSeen = useCallback(() => setHasSeenSearchResult(true), [])
    const { scrollToParagraphId } = useTranscriptViewerAPI()

    const selectSearchResult = useCallback((r: SearchResult | null) => {
        setSearchResult(r)
        setHasSeenSearchResult(false)
    }, [])

    useEffect(() => {
        if (searchResult && searchResult !== prevSearchResult) {
            scrollToParagraphId(searchResult.paragraph.id)
        }
    }, [prevSearchResult, scrollToParagraphId, searchResult])

    return (
        <SearchContext.Provider
            value={useMemo(
                () => ({
                    searchResult,
                    selectSearchResult,
                    hasSeenSearchResult,
                    markSearchResultAsSeen,
                }),
                [hasSeenSearchResult, markSearchResultAsSeen, searchResult, selectSearchResult],
            )}>
            {children}
        </SearchContext.Provider>
    )
}

export function useSearchResultAPI() {
    const value = useContext(SearchContext)

    if (value === null) {
        throw new Error('You have forgot to use <SearchProvider />, shame on you.')
    }

    return value
}
