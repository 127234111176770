const getScrollbarWidth = () => {
    const outer = document.createElement('div')
    outer.style.visibility = 'hidden'
    outer.style.overflow = 'scroll'
    document.body.appendChild(outer)

    const inner = document.createElement('div')
    outer.appendChild(inner)

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

    document.body.removeChild(outer)

    return scrollbarWidth
}

export const SCROLL_BAR_WIDTH = getScrollbarWidth()

export function isVisibleInViewport(node: HTMLElement) {
    const nodeRect = node.getBoundingClientRect()
    const scrollingParent = getScrollParent(node)

    if (!scrollingParent) {
        return true
    }

    const scrollingParentRect = scrollingParent.getBoundingClientRect()

    return (
        nodeRect.top >= scrollingParentRect.top &&
        nodeRect.bottom <= scrollingParentRect.bottom &&
        nodeRect.left >= scrollingParentRect.left &&
        nodeRect.right <= scrollingParentRect.right
    )
}

export function getScrollParent(node: HTMLElement | null): HTMLElement | null {
    if (node == null) {
        return null
    }

    if (node.scrollHeight > node.clientHeight) {
        return node
    } else {
        return getScrollParent(node.parentNode as HTMLElement)
    }
}
