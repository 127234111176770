import { useCallback, useEffect, useMemo, useState } from 'react'

import { DownloadIcon, FontSizeIcon, LogoutIcon, SearchIcon, QuestionMarkCircleIcon } from '@verbit-ai/icons-library'
import { OptionType, SelectProps, Header2 as VerbitHeader } from '@verbit-ai/verbit-ui-library'
import styled, { useTheme } from 'styled-components'
import { theme } from 'styled-tools'

import { useLogout, useUserContext } from 'src/contexts/UserProvider'
import { useResponsive } from 'src/hooks/useResponsive'
import { useDownload, useIsTranscriptEmpty, useTranscriptMetadata } from 'src/contexts/TranscriptContext'
import { FontSizeControl } from 'src/components/FontSizeControl/FontSizeControl'
import { Search } from 'src/components/Search/Search'
import { GlobeIcon, PaletteIcon } from 'src/components/icons'
import { ThemeToggleMobile } from 'src/components/ThemeToggle/ThemeToggleMobile'
import { isLanguageSelectionAvailable } from 'src/models/TranscriptMetadata'
import { getLanguagePresentation } from 'src/models/Language'
import { LanguageSelectMobile } from 'src/components/LanguageSelect/LanguageSelectMobile'
import { analyticsService } from 'src/services/AnalyticsService'

import { HelpButton, helpButtonStyle, navContainerStyle } from './styles'

declare global {
    interface Window {
        embedded_svc?: any
    }
}

const Bar = styled.div`
    width: 100%;
    height: ${theme('sizes.headerHeight')}px;
    z-index: ${theme('zIndices.appHeader')};
`

export function Topbar() {
    const isTranscriptEmpty = useIsTranscriptEmpty()
    const transcriptMetadata = useTranscriptMetadata()
    const { isMobilePhone, isTablet } = useResponsive()
    const currentTheme = useTheme()
    const {
        palette: {
            header: {
                logo,
                titleColor,
                panelBackgroundColor,
                panelFilter,
                panelBorder,
                dropdownTextColor,
                dropdownBgColor,
                dropdownIconColor,
                dropdownBorderColor,
            },
        },
    } = currentTheme

    const { user } = useUserContext()
    const logout = useLogout()

    const [isFontSizeControlOpen, setIsFontSizeControlOpen] = useState(false)
    const [isSearchOpen, setIsSearchOpen] = useState(false)
    const [isThemeToggleOpen, setIsThemeToggleOpen] = useState(false)
    const [isLanguageSelectOpen, setIsLanguageSelectOpen] = useState(false)

    const { canDownload, downloadTextFile } = useDownload()
    const canSelectLanguage = isLanguageSelectionAvailable(transcriptMetadata)

    const openSalesforceChat = useCallback(() => {
        if (window.embedded_svc) {
            // This is an example function call, replace it with the actual function
            // that opens the Salesforce chatbot
            window.embedded_svc.bootstrapEmbeddedService()
        } else {
            console.error('Salesforce chat script not loaded yet')
        }
    }, [])

    const accountOptions = useMemo(() => {
        const options: OptionType[] = []

        if (isTablet) {
            if (!isTranscriptEmpty) {
                options.push({
                    value: 'search',
                    label: 'Search in text',
                    icon: <SearchIcon color={dropdownTextColor} />,
                    onClick: () => setIsSearchOpen(true),
                })
            }
            options.push({
                value: 'font-size',
                label: 'Font size',
                icon: <FontSizeIcon color={dropdownTextColor} />,
                onClick: () => setIsFontSizeControlOpen(true),
            })
            if (canDownload) {
                options.push({
                    value: 'download',
                    label: 'Download transcript',
                    icon: <DownloadIcon color={dropdownTextColor} />,
                    onClick: downloadTextFile,
                })
            }
            if (canSelectLanguage) {
                options.push({
                    value: 'select-language',
                    label: getLanguagePresentation(transcriptMetadata!.currentLanguageCode, transcriptMetadata!.translations),
                    icon: <GlobeIcon color={dropdownTextColor} />,
                    onClick: () => setIsLanguageSelectOpen(true),
                })
            }
            options.push({
                value: 'theme-toggle',
                label: 'Theme',
                icon: <PaletteIcon color={dropdownTextColor} />,
                onClick: () => setIsThemeToggleOpen(true),
            })
            options.push({
                value: 'help',
                label: 'Help',
                icon: <QuestionMarkCircleIcon color={dropdownTextColor} />,
                onClick: openSalesforceChat,
            })
        }
        options.push({
            value: 'sign-out',
            label: 'Log Out',
            icon: <LogoutIcon color={dropdownTextColor} />,
            onClick: logout,
        })

        return options
    }, [
        isTablet,
        dropdownTextColor,
        logout,
        isTranscriptEmpty,
        canDownload,
        canSelectLanguage,
        openSalesforceChat,
        downloadTextFile,
        transcriptMetadata,
    ])

    // https://github.com/JedWatson/react-select/issues/3491
    const dropdownStyles = useMemo<SelectProps['styles']>(
        () => ({
            menu: () => ({
                position: 'absolute',
                right: 0,
                background: 'transparent',
            }),
            singleValue: () => ({
                color: `${dropdownTextColor} !important`,
            }),
            menuList: () => ({
                background: dropdownBgColor,
                border: `1px solid ${dropdownBorderColor}`,
                borderRadius: '4px',
                '& .is-focused': {
                    background: 'inherit !important',
                },
                '& .verbit-select__option': {
                    color: dropdownTextColor,
                },
            }),
            valueContainer: () => ({
                '& svg': {
                    stroke: dropdownIconColor,
                },
            }),
        }),
        [dropdownBgColor, dropdownBorderColor, dropdownIconColor, dropdownTextColor],
    )

    useEffect(() => {
        if (isLanguageSelectOpen) {
            analyticsService.track('TranslationToggled', {})
        }
    }, [isLanguageSelectOpen])

    return (
        <Bar>
            <VerbitHeader
                bg={panelBackgroundColor}
                filter={panelFilter}
                borderWidth={1}
                borderStyle="solid"
                borderColor={panelBorder}
                alignItems="center">
                <VerbitHeader.Logo>{logo}</VerbitHeader.Logo>
                <VerbitHeader.Title color={titleColor} overflow="hidden" textOverflow="ellipsis" display="block" whiteSpace="nowrap">
                    {transcriptMetadata?.sessionName}
                </VerbitHeader.Title>
                {isMobilePhone
                    ? null
                    : window.embedded_svc && (
                          <HelpButton sx={helpButtonStyle(currentTheme)} onClick={openSalesforceChat}>
                              Help
                          </HelpButton>
                      )}
                <VerbitHeader.Nav sx={navContainerStyle(isMobilePhone)}>
                    {isMobilePhone ? (
                        <VerbitHeader.MobileDropdown options={accountOptions} styles={dropdownStyles} />
                    ) : (
                        <VerbitHeader.Dropdown2 text={user?.userName || user?.email} options={accountOptions} styles={dropdownStyles} />
                    )}
                </VerbitHeader.Nav>
            </VerbitHeader>

            {isTablet && (
                <>
                    <FontSizeControl isOpen={isFontSizeControlOpen} onClose={() => setIsFontSizeControlOpen(false)} />
                    {!isTranscriptEmpty && <Search isOpen={isSearchOpen} onToggle={setIsSearchOpen} />}
                    <ThemeToggleMobile isOpen={isThemeToggleOpen} onClose={() => setIsThemeToggleOpen(false)} />
                    {canSelectLanguage && (
                        <LanguageSelectMobile isOpen={isLanguageSelectOpen} onClose={() => setIsLanguageSelectOpen(false)} />
                    )}
                </>
            )}
        </Bar>
    )
}
