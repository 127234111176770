import { ThemeType } from 'src/themes/types'

export type Env = 'dev' | 'qa' | 'staging' | 'production'
export type SourceOfTruth = 'platform' | 'trax'

export const getEnv = (): Env => import.meta.env.VITE_ENV ?? 'dev'

export const getPlatformServerUrl = () => import.meta.env.VITE_PLATFORM_SERVER_URL

export const getVerbitAuthServerUrl = () => import.meta.env.VITE_VERBIT_AUTH_SERVER_URL

export const getAiServiceServerUrl = () => import.meta.env.VITE_AI_SERVICE_SERVICE_URL

export const getLogRocketApiKey = () => import.meta.env.VITE_LOG_ROCKET_API_KEY

export const getMixpanelApiKey = () => import.meta.env.VITE_MIXPANEL_API_KEY

export const getRollbarToken = () => import.meta.env.VITE_ROLLBAR_TOKEN

export function logger(...data: any[]) {
    if (getEnv() !== 'production') {
        console.log(...data)
    }
}

export const getTranscriptJobId = (): string => {
    if (isMockActive()) {
        return document.mocks.getTranscriptJobId()
    }

    const urlParams = new URLSearchParams(window.location.search)

    return urlParams.get('transcriptJobId') ?? ''
}

export const getTraxSessionId = (): string => {
    const urlParams = new URLSearchParams(window.location.search)

    return urlParams.get('traxSessionId') ?? ''
}

export const getSourceOfTruth = (): SourceOfTruth => {
    const urlParams = new URLSearchParams(window.location.search)
    const sotValue = urlParams.get('sot') || 'platform'

    return sotValue === 'trax' ? 'trax' : 'platform'
}

export const isAIPanelOpenInitially = (): boolean => {
    const urlParams = new URLSearchParams(window.location.search)
    let isOpen = false

    try {
        isOpen = !!JSON.parse(urlParams.get('isAIPanelOpen') || '')
    } catch (e) {}

    return isOpen
}
export const isAIPanelSuppressed = (): boolean => {
    const urlParams = new URLSearchParams(window.location.search)
    let canBeShown = true

    try {
        canBeShown = !!JSON.parse(urlParams.get('isAIPanelShown') || '')
    } catch (e) {}

    return !canBeShown
}

export const isMockActive = () => {
    const urlParams = new URLSearchParams(window.location.search)

    return urlParams.get('mocks') !== null
}

export const THEME_TYPE_QUERY_PARAM = 'themeType'
/**
 * When chromakey mode is active:
 *  - chromakey theme is selected, regardless of saved user preferences;
 *  - theme type cannot be changed, and text color preferences are not saved to local storage;
 *  - following UI elements are not displayed: header, search button, download button,
 *    speaker names, timestamps, AI panel (even if enabled for current customer);
 *  - font size and text color controls look differently
 */
export const isChromakeyModeActive = (): boolean => {
    const urlParams = new URLSearchParams(window.location.search)
    const chromakeyThemeType: ThemeType = 'chromakey'

    return urlParams.get(THEME_TYPE_QUERY_PARAM) === chromakeyThemeType
}

export const isAppOpenedFromEmailLink = () => {
    const urlParams = new URLSearchParams(window.location.search)

    return urlParams.get('source') === 'email'
}

export const isZoomAppsEnv = () => navigator.userAgent.includes('ZoomApps')

export const getFlagsmithApiKey = () => import.meta.env.VITE_FLAGSMITH_API_KEY

export const getMinTokensAmountToRunQueries = () => Number(import.meta.env.VITE_MIN_TOKENS_AMOUNT_TO_RUN_QUERIES)

export const REQUESTED_LANGUAGE_PARAM = 'language'
export const getRequestedLanguageCode = () => {
    const urlParams = new URLSearchParams(window.location.search)

    return urlParams.get(REQUESTED_LANGUAGE_PARAM) || ''
}
