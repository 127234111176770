const STATUS_ELEMENT_ID = 'statusMessage'

/**
 * This method is used to put given text inside a div with role="status".
 * When content is dynamically updated in an element with this role, it will be
 * announced by screen readers. This way we make sure that we convey status
 * notifications to visually impaired users.
 * Important note: to work correctly, live regions (like status) need to exist
 * in the HTML at the time of page load (preferrably in the singular). That's why
 * the element with specified ID and role is put directly in `index.html`, and
 * visually hidden with styles.
 */
export const announceText = (text: string) => {
    console.debug('Announcing:', text)
    const statusElement = document.getElementById(STATUS_ELEMENT_ID)
    if (statusElement) {
        // keep in mind: if text has newline symbols, they will be replaced
        // with <br /> (and screen readers will presumably make a pause there)
        statusElement.innerText = text
    }
}
