import { SCROLL_BAR_WIDTH } from 'src/utils/dom'

export const TRANSCRIPT_FULL_WIDTH_PADDING = 24

interface GetTranscriptDimensionsParams {
    isDiarizationSupported: boolean
    fontSizePercentage: number
    isFullWidthMode: boolean
    containerWidth: number
}
interface TranscriptDimensions {
    transcriptParagraphWidth: number
    textMaxWidth: number
    timestampWidth: number
}
export const getTranscriptDimensions = ({
    isDiarizationSupported,
    fontSizePercentage,
    containerWidth,
    isFullWidthMode,
}: GetTranscriptDimensionsParams): TranscriptDimensions => {
    const timestampPadding = 30
    const timestampWidthFull = 120
    const timestampWidthCondensed = 40
    const timestampWidth = Math.min(
        Math.round(((isDiarizationSupported ? timestampWidthFull : timestampWidthCondensed) * fontSizePercentage) / 100) + timestampPadding,
        230,
    )

    const transcriptParagraphFixedWidth = 880
    const transcriptParagraphWidth = isFullWidthMode
        ? containerWidth - SCROLL_BAR_WIDTH - TRANSCRIPT_FULL_WIDTH_PADDING * 2
        : transcriptParagraphFixedWidth

    const textMaxWidth = transcriptParagraphWidth - timestampWidth

    return { transcriptParagraphWidth, textMaxWidth, timestampWidth }
}

export const AUTO_SCROLL_THRESHOLD = 1500

export const AI_PANEL_OPENED_WIDTH = 444
export const AI_PANEL_CLOSED_WIDTH = 30
export const NEW_AI_PANEL_OPENED_WIDTH = 666
export const NEW_AI_PANEL_CLOSED_WIDTH = 0

export const AI_PANEL_TRANSITION_DURATION = 0.5
export const AI_PANEL_TRANSITION_EASING = 'ease-in-out'

export const AI_PANEL_TABLET_HEIGHT = 136

export const AI_PANEL_PROMPTS_BAR_WIDTH = 70
