import { Env, getEnv } from 'src/utils/env'

interface ServiceBaseConfig {
    blockedEnvs: Env[]
}

export abstract class ServiceBase {
    constructor(baseConfig: ServiceBaseConfig) {
        return new Proxy(this, {
            get(target, propName) {
                if (baseConfig.blockedEnvs.includes(getEnv())) {
                    const className = target.constructor.name
                    console.log(`${className}.${String(propName)} blocked on env ${getEnv()}`)

                    return () => {}
                }

                return target[propName as keyof ServiceBase]
            },
        })
    }

    abstract init(): void
    abstract identify(id: string): void
}
