import { rollbarService } from 'src/services/RollbarService'

export enum Language {
    English = 'English',
    Afrikaans = 'Afrikaans',
    Arabic = 'Arabic',
    Albanian = 'Albanian',
    Armenian = 'Armenian',
    Bashkir = 'Bashkir',
    Belarusian = 'Belarusian',
    Bulgarian = 'Bulgarian',
    Catalan = 'Catalan',
    Chinese = 'Chinese',
    Czech = 'Czech',
    Danish = 'Danish',
    Esperanto = 'Esperanto',
    German = 'German',
    Greek = 'Greek',
    Spanish = 'Spanish',
    Estonian = 'Estonian',
    Persian = 'Persian',
    Finnish = 'Finnish',
    French = 'French',
    Galician = 'Galician',
    Hebrew = 'Hebrew',
    Hindi = 'Hindi',
    Croatian = 'Croatian',
    Hungarian = 'Hungarian',
    Indonesian = 'Indonesian',
    Interlingua = 'Interlingua',
    Italian = 'Italian',
    Japanese = 'Japanese',
    Korean = 'Korean',
    Lithuanian = 'Lithuanian',
    Latvian = 'Latvian',
    Malay = 'Malay',
    Dutch = 'Dutch',
    Norwegian = 'Norwegian',
    Polish = 'Polish',
    Portuguese = 'Portuguese',
    Romanian = 'Romanian',
    Russian = 'Russian',
    Slovak = 'Slovak',
    Slovenian = 'Slovenian',
    Swedish = 'Swedish',
    Thai = 'Thai',
    Turkish = 'Turkish',
    Ukrainian = 'Ukrainian',
    Vietnamese = 'Vietnamese',
    Azerbaijani = 'Azerbaijani',
    Amharic = 'Amharic',
    Basque = 'Basque',
    Bosnian = 'Bosnian',
    Bengali = 'Bengali',
    Burmese = 'Burmese',
    Filipino = 'Filipino',
    Georgian = 'Georgian',
    Gujarati = 'Gujarati',
    Icelandic = 'Icelandic',
    Javanese = 'Javanese',
    Kannada = 'Kannada',
    Khmer = 'Khmer',
    Lao = 'Lao',
    Macedonian = 'Macedonian',
    Malayalam = 'Malayalam',
    Marathi = 'Marathi',
    Mongolian = 'Mongolian',
    Nepali = 'Nepali',
    Punjabi = 'Punjabi',
    Serbian = 'Serbian',
    Sinhala = 'Sinhala',
    Swahili = 'Swahili',
    Sundanese = 'Sundanese',
    Tamil = 'Tamil',
    Telugu = 'Telugu',
    Urdu = 'Urdu',
    Uzbek = 'Uzbek',
    Zulu = 'Zulu',
    Tagalog = 'Tagalog',
    Uyghur = 'Uyghur',
    Welsh = 'Welsh',
}

export const DEFAULT_LANGUAGE = Language.English
export const DEFAULT_LANGUAGE_CODE = 'en-US'

export const LANGUAGES_WITH_SPECIAL_FONTS = [Language.Chinese, Language.Japanese, Language.Korean, Language.Hindi] as const
export type LanguageWithSpecialFont = (typeof LANGUAGES_WITH_SPECIAL_FONTS)[number]

export const LANGUAGES_WITHOUT_WHITESPACES = [Language.Chinese, Language.Japanese] as const
export type LanguageWithoutWhitespaces = (typeof LANGUAGES_WITHOUT_WHITESPACES)[number]

// by ISO 639-1 (except for Filipino, which doesn't have one, so for it Set 2 code is provided instead)
// taken from https://en.wikipedia.org/wiki/List_of_ISO_639_language_codes
export const LANGUAGE_LOCALES: Record<Language, string[]> = {
    [Language.English]: ['en'],
    [Language.Arabic]: ['ar'],
    [Language.Afrikaans]: ['af'],
    [Language.Albanian]: ['sq'],
    [Language.Armenian]: ['hy'],
    [Language.Bashkir]: ['ba'],
    [Language.Belarusian]: ['be'],
    [Language.Bulgarian]: ['bg'],
    [Language.Catalan]: ['ca'],
    [Language.Chinese]: ['zh', 'cmn', 'yue'],
    [Language.Czech]: ['cs'],
    [Language.Danish]: ['da'],
    [Language.Esperanto]: ['eo'],
    [Language.German]: ['de'],
    [Language.Greek]: ['el'],
    [Language.Spanish]: ['es'],
    [Language.Estonian]: ['et'],
    [Language.Persian]: ['fa', 'per'],
    [Language.Finnish]: ['fi'],
    [Language.French]: ['fr'],
    [Language.Galician]: ['gl'],
    [Language.Hindi]: ['hi'],
    [Language.Croatian]: ['hr'],
    [Language.Hungarian]: ['hu'],
    [Language.Indonesian]: ['id'],
    [Language.Interlingua]: ['ia'],
    [Language.Italian]: ['it'],
    [Language.Japanese]: ['ja'],
    [Language.Korean]: ['ko'],
    [Language.Lithuanian]: ['lt'],
    [Language.Latvian]: ['lv'],
    [Language.Malay]: ['ms'],
    [Language.Dutch]: ['nl'],
    [Language.Norwegian]: ['no', 'nb', 'nn'],
    [Language.Polish]: ['pl'],
    [Language.Portuguese]: ['pt'],
    [Language.Romanian]: ['ro'],
    [Language.Russian]: ['ru'],
    [Language.Slovak]: ['sk'],
    [Language.Slovenian]: ['sl'],
    [Language.Swedish]: ['sv'],
    [Language.Thai]: ['th'],
    [Language.Turkish]: ['tr'],
    [Language.Ukrainian]: ['uk'],
    [Language.Vietnamese]: ['vi'],
    [Language.Hebrew]: ['he'],
    [Language.Azerbaijani]: ['az'],
    [Language.Amharic]: ['am'],
    [Language.Basque]: ['eu'],
    [Language.Bosnian]: ['bs'],
    [Language.Bengali]: ['bn'],
    [Language.Burmese]: ['my'],
    [Language.Filipino]: ['fil'],
    [Language.Georgian]: ['ka'],
    [Language.Gujarati]: ['gu'],
    [Language.Icelandic]: ['is'],
    [Language.Javanese]: ['jv'],
    [Language.Kannada]: ['kn'],
    [Language.Khmer]: ['km'],
    [Language.Lao]: ['lo'],
    [Language.Macedonian]: ['mk'],
    [Language.Malayalam]: ['ml'],
    [Language.Marathi]: ['mr'],
    [Language.Mongolian]: ['mn'],
    [Language.Nepali]: ['ne'],
    [Language.Punjabi]: ['pa'],
    [Language.Serbian]: ['sr'],
    [Language.Sinhala]: ['si'],
    [Language.Swahili]: ['sw'],
    [Language.Sundanese]: ['su'],
    [Language.Tamil]: ['ta'],
    [Language.Telugu]: ['te'],
    [Language.Urdu]: ['ur'],
    [Language.Uzbek]: ['uz'],
    [Language.Zulu]: ['zu'],
    [Language.Tagalog]: ['tl'],
    [Language.Uyghur]: ['ug'],
    [Language.Welsh]: ['cy'],
}

export const getLanguageByCode = (code: string): Language => {
    if (!code) {
        rollbarService.error('[TranscriptMetadata] Missing language code in configuration')

        return DEFAULT_LANGUAGE
    }

    const codesMap: Record<string, Language> = Object.entries(LANGUAGE_LOCALES).reduce((acc, [lang, codes]) => {
        const curr: Record<string, Language> = {}
        codes.forEach((langCode) => {
            curr[langCode] = lang as Language
        })

        return {
            ...acc,
            ...curr,
        }
    }, {})

    // the code is expected to be coming from BE as a combination of ISO 639-1 (language) and
    // ISO 3166-1 Alpha-2 (country) - e.g. "en-US" - of which we only care about the language
    const langPart = code.split('-')[0]
    const language = codesMap[langPart]
    if (!language) {
        rollbarService.error(`[TranscriptMetadata] Unexpected language code: ${code}`)

        return DEFAULT_LANGUAGE
    }

    return language
}

export interface Translation {
    code: string
    name: string
}

export const getLanguagePresentation = (code: string, translations: Translation[]) => {
    const targetTranslation = translations.find((t) => t.code === code)

    return targetTranslation?.name || ''
}
