import { Transcript } from 'src/models/Transcript'
import { SessionDeposition, TranscriptMetadata } from 'src/models/TranscriptMetadata'
import { StreamMessage } from 'src/network/StreamClient'
import { isMockActive } from 'src/utils/env'
import { PromptInternalTestResponse } from 'src/models/Prompt'
import { DEFAULT_LANGUAGE, DEFAULT_LANGUAGE_CODE } from 'src/models/Language'
import { extname } from 'src/utils/extname'

import { LiveEventPlayer } from './LiveEventPlayer'

interface MockData {
    transcriptJobId: string
    transcriptMetadata: TranscriptMetadata
    initialTranscript: Transcript
    asrHistory: StreamMessage[]
    liveEvents: StreamMessage[]
    // AI service
    promptTestResult: PromptInternalTestResponse
    promptTestCsv: string
}

type Mode = 'record' | 'replay'

export class MockManager {
    private mode: Mode = 'record'
    private data: MockData
    private liveEventPlayer = new LiveEventPlayer()
    private _isAnonymousUser = false

    private readyPromise: Promise<void>

    constructor(mode: Mode, file: string, isAnonymousUser = false) {
        this.data = this.getinitialMockData()
        this.mode = mode
        this._isAnonymousUser = isAnonymousUser

        if (mode === 'replay') {
            this.readyPromise = this.loadReplayFile(file)
        } else {
            this.readyPromise = Promise.resolve()
        }
    }

    isReady() {
        return this.readyPromise
    }

    getMode() {
        return this.mode
    }

    static async fetchFile(file: string) {
        try {
            return (await (await fetch(file)).json()) as MockData
        } catch (e) {
            throw new Error(`[MOCK] Could not load replay file from "${file}".`)
        }
    }

    private async loadReplayFile(file: string) {
        const data = await MockManager.fetchFile(file)

        if (data) {
            this.data = data
            this.liveEventPlayer.addEvents(data.liveEvents)
        }
    }

    private getinitialMockData(): MockData {
        return {
            transcriptJobId: '',
            transcriptMetadata: {
                ablyClientOptions: {},
                ablyTokenUrl: '',
                scheduledEventId: 9999999,
                sessionId: 999999,
                downloadable: true,
                sessionFinished: new Date(),
                transcriptionJobId: 999999,
                customerId: 999999,
                customerName: 'John Doe',
                sessionState: {
                    startTime: new Date(),
                    deposition: SessionDeposition.LIVE,
                },
                transcriptType: 'asr_only',
                sessionName: 'sessionName',
                suppressSpeakerName: false,
                generativeAiEnabled: false,
                vertical: 'education',
                originalLanguageCode: DEFAULT_LANGUAGE_CODE,
                translations: [],
                currentLanguageCode: DEFAULT_LANGUAGE_CODE,
                currentLanguage: DEFAULT_LANGUAGE,
            },
            initialTranscript: {
                paragraphs: [],
                asrParagraphs: [],
                authors: {},
                isDiarizationSupported: false,
                words: {},
                processedTime: 0,
                asrProcessedTime: 0,
                recordingStartedAt: new Date(),
            },
            asrHistory: [],
            liveEvents: [],
            promptTestResult: {
                request_id: '',
            },
            promptTestCsv: '',
        }
    }

    getTranscriptJobId() {
        return this.data.transcriptJobId
    }

    getTranscriptMetadata() {
        return this.data.transcriptMetadata
    }

    saveTranscriptMetadata(metadata: TranscriptMetadata) {
        return (this.data.transcriptMetadata = metadata)
    }

    getInitialTranscript() {
        return this.data.initialTranscript
    }

    saveInitialTranscript(transcript: Transcript) {
        return (this.data.initialTranscript = transcript)
    }

    getAsrHistory() {
        return this.data.asrHistory
    }

    saveAsrHistory(asrHistory: StreamMessage[]) {
        return (this.data.asrHistory = asrHistory)
    }

    saveLiveEvent(events: StreamMessage | StreamMessage[]) {
        events = Array.isArray(events) ? events : [events]
        this.data.liveEvents.push(...events)
    }

    getLiveEvents() {
        return this.data.liveEvents
    }

    getLiveEventPlayer() {
        return this.liveEventPlayer
    }

    getPromptTestInitResult() {
        return this.data.promptTestResult
    }

    getPromptTestCsv() {
        return this.data.promptTestCsv
    }

    downloadMock() {
        const element = document.createElement('a')
        element.setAttribute('href', `data:application/json;charset=utf-8,${encodeURIComponent(JSON.stringify(this.data))}`)
        element.setAttribute('download', 'mock.json')
        element.style.display = 'none'
        document.body.append(element)

        element.click()
        document.body.removeChild(element)
    }

    isAnonymousUser() {
        return this._isAnonymousUser
    }
}

export const initMockManager = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const mock = urlParams.get('mocks')! // we can omit the null here because of the conditional instantiation below that only instantiates, if the mocks are active
    const isAnonymousUser = urlParams.get('anonymous') !== null

    if (isMockActive()) {
        document.mocks = new MockManager(extname(mock) === 'json' ? 'replay' : 'record', mock, isAnonymousUser)
    }
}
initMockManager()

declare global {
    interface Document {
        mocks: MockManager
    }
}

export {}
