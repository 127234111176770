import styled, { css, useTheme } from 'styled-components'
import { CheckIcon } from '@verbit-ai/icons-library'
import { theme } from 'styled-tools'

import { useTranscriptMetadata } from 'src/contexts/TranscriptContext'
import { getLanguagePresentation } from 'src/models/Language'
import { REQUESTED_LANGUAGE_PARAM } from 'src/utils/env'
import { analyticsService } from 'src/services/AnalyticsService'

const Panel = styled.div`
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    width: 240px;

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        padding: 0;
        width: 100%;
    }
`

const TextContent = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
`

const BasicBlock = css`
    color: ${theme('palette.languageSelect.desktopTextColor')};
    font-size: 14px;
    line-height: 21px;
    padding: 8px 40px 8px 16px;
    width: 100%;
    text-align: left;
    position: relative;

    svg {
        position: absolute;
        top: 11px;
        right: 16px;
        stroke-width: 2px;
    }

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        padding: 16px 40px 16px 16px;
        font-size: 16px;
        line-height: 24px;
        color: ${theme('palette.languageSelect.mobileTextColor')};

        svg {
            top: unset;
            bottom: 20px;
        }
    }
`

const DefaultLanguage = styled.span`
    ${BasicBlock}
    padding-bottom: 16px;
    border-bottom: 1px solid ${theme('palette.languageSelect.separatorColor')};

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        padding-top: 24px;
    }
`

const LanguageButton = styled.button`
    ${BasicBlock}
`

export function LanguagesList() {
    const { currentLanguageCode, originalLanguageCode, translations } = useTranscriptMetadata()!
    const {
        palette: {
            languageSelect: { iconColor },
        },
    } = useTheme()
    const isOriginalLanguageSelected = currentLanguageCode === originalLanguageCode

    const onLanguageSelected = (languageCode: string) => {
        analyticsService.track('TranslationLanguageSelected', {
            source: originalLanguageCode,
            target: languageCode,
        })

        const newUrl = new URL(window.location.href)
        if (languageCode === originalLanguageCode) {
            newUrl.searchParams.delete(REQUESTED_LANGUAGE_PARAM)
        } else {
            newUrl.searchParams.set(REQUESTED_LANGUAGE_PARAM, languageCode)
        }
        window.location.assign(newUrl)
    }

    return (
        <Panel>
            <DefaultLanguage>
                <TextContent>{getLanguagePresentation(originalLanguageCode, translations)}</TextContent>
                {isOriginalLanguageSelected && <CheckIcon color={iconColor} />}
            </DefaultLanguage>
            {translations.map(({ name, code }) => {
                const isSelected = code === currentLanguageCode

                return (
                    <LanguageButton key={code} onClick={() => onLanguageSelected(code)}>
                        <TextContent>{name}</TextContent>
                        {isSelected && <CheckIcon color={iconColor} />}
                    </LanguageButton>
                )
            })}
        </Panel>
    )
}
