import { useEffect, useMemo, useRef } from 'react'

import styled, { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'

import { isVisibleInViewport } from 'src/utils/dom'
import { TranscriptWord } from 'src/models/TranscriptWord'
import { useSearchResultAPI } from 'src/contexts/SearchContext'

const WordContainer = styled.span<{ $highlight: boolean }>`
    font-family: inherit;
    ${ifProp(
        '$highlight',
        css`
            background: ${theme('palette.transcript.searchResultHighlight')};
            border-radius: 1px;
        `,
    )}
`

interface WordViewProps {
    word: TranscriptWord
    index: number
    isFirst: boolean
}

export const WordView = ({ word, isFirst, index }: WordViewProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const { searchResult, hasSeenSearchResult, markSearchResultAsSeen } = useSearchResultAPI()

    const isSearchResult = useMemo(
        () =>
            !!searchResult &&
            searchResult.paragraph.id === word.paragraphId &&
            index >= searchResult.match.startOffset &&
            index <= searchResult.match.endOffset,
        [index, word.paragraphId, searchResult],
    )

    useEffect(() => {
        if (isSearchResult && !hasSeenSearchResult) {
            if (ref.current && !isVisibleInViewport(ref.current)) {
                requestAnimationFrame(() => ref.current?.scrollIntoView({ block: 'center' }))
            }

            markSearchResultAsSeen()
        }
    }, [isSearchResult, hasSeenSearchResult, markSearchResultAsSeen])

    return (
        <WordContainer $highlight={isSearchResult} ref={ref}>
            {TranscriptWord.shouldAddSpace(word, { first: isFirst }) ? ' ' : ''}
            {word.text}
        </WordContainer>
    )
}
