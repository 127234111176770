import Draggable from 'react-draggable'

import styled from 'styled-components'

import { MockRecordController } from './MockRecordController'
import { MockReplayController } from './MockReplayController'

const AbsoluteContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 999999;

    background: #fff;
    padding: 8px;
    border-radius: 2px;
    box-shadow: 0 2px 10px rgb(0 0 0 / 25%);

    display: flex;
`

export function MockControls() {
    return (
        <Draggable>
            <AbsoluteContainer>
                {document.mocks.getMode() === 'replay' ? <MockReplayController /> : <MockRecordController />}
            </AbsoluteContainer>
        </Draggable>
    )
}
