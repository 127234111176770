import styled from 'styled-components'
import { prop, theme } from 'styled-tools'

import { GRADIENT_BUTTON_CLASSNAME } from 'src/components/GradientButton/GradientButton'
import { LANGUAGE_SELECT_CLASSNAME } from 'src/components/LanguageSelect/LanguageSelect'
import { FONT_SIZE_CONTROL_CLASSNAME } from 'src/components/FontSizeControl/FontSizeControl'

export const TOGGLE_THEME_BUTTON_CLASSNAME = 'palette'

export const TranscriptToolbarLimiter = styled.div<{
    $textMaxWidth: number
    $paragraphTotalWidth: number
    $isPanelOpen: boolean
}>`
    width: 100%;
    max-width: ${prop('$paragraphTotalWidth')}px;
    height: 100%;
    background: ${theme('palette.transcript.toolbarInnerBackground')};
    display: flex;
    align-items: center;
    justify-content: 'flex-end';
    margin: 0 auto;

    .${GRADIENT_BUTTON_CLASSNAME} {
        margin: 0 8px;
        &.${TOGGLE_THEME_BUTTON_CLASSNAME} {
            margin-right: 0;
        }
    }

    .${LANGUAGE_SELECT_CLASSNAME}, .${FONT_SIZE_CONTROL_CLASSNAME} {
        margin: 0 8px;
        .${GRADIENT_BUTTON_CLASSNAME} {
            margin: 0;
        }
    }

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        max-width: ${prop('$textMaxWidth')}px;
    }

    @media (max-width: ${theme('breakPoints.mobilePhoneMaxWidth')}px) {
        max-width: unset;
    }
`
