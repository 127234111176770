import { styled } from 'styled-components'

const Container = styled.span`
    max-width: 400px;
    display: flex;
    flex-direction: column;
`

const Title = styled.span`
    font-weight: 600;
`

const Description = styled.span``

export function OfflineMessage() {
    return (
        <Container>
            <Title>You appear to be offline</Title>
            <Description>We&apos;ll update the content once the connection is restored</Description>
        </Container>
    )
}
