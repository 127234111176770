import { EventEmitter } from 'events'

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { useShallowMemo } from 'src/hooks/useShallowMemo'

type QueueExecuteFn = () => unknown

interface QueueBaseProps {
    execute: QueueExecuteFn
    size: number
}

type QueueEvents = {
    size_change: (queue: string, size: number) => void
}

interface QueueContextProps {
    register: (queue: string, executeFn: QueueExecuteFn, initialSize: number) => unknown
    unregister: (queue: string) => unknown
    subscribe: EventEmitter<QueueEvents>['on']
    unsubscribe: EventEmitter<QueueEvents>['off']
    execute: (queue: string) => unknown
    getQueueSize: (queue: string) => number | null
    setQueueSize: (queue: string, size: number) => unknown
}

const QueueContext = React.createContext<QueueContextProps | null>(null)

interface QueueProviderProps {
    children: React.ReactNode
}

export function QueueProvider({ children }: QueueProviderProps) {
    const emitter = useMemo(() => new EventEmitter<QueueEvents>(), [])
    const queues = useMemo<Partial<Record<string, QueueBaseProps>>>(() => ({}), [])

    const getQueue = useCallback((queue: string) => queues[queue], [queues])

    const register = useCallback(
        (queue: string, executeFn: QueueExecuteFn, initialSize: number) => {
            if (!queues[queue]) {
                queues[queue] = { execute: executeFn, size: initialSize }
            }

            const q = getQueue(queue)
            if (q) q.execute = executeFn
        },
        [getQueue, queues],
    )

    const unregister = useCallback(
        (queue: string) => {
            delete queues[queue]
        },
        [queues],
    )

    const execute = useCallback(
        (queue: string) => {
            const q = getQueue(queue)
            if (q) {
                q.execute()
            }
        },
        [getQueue],
    )

    const setQueueSize = useCallback(
        (queue: string, size: number) => {
            const q = getQueue(queue)

            if (q) {
                queues[queue] = { ...q, size }
                emitter.emit('size_change', queue, size)
            }
        },
        [emitter, getQueue, queues],
    )

    const getQueueSize = useCallback((queue: string) => getQueue(queue)?.size ?? null, [getQueue])

    return (
        <QueueContext.Provider
            value={useShallowMemo({
                subscribe: emitter.on.bind(emitter),
                unsubscribe: emitter.off.bind(emitter),
                register,
                unregister,
                execute,
                getQueueSize,
                queues,
                setQueueSize,
            })}>
            {children}
        </QueueContext.Provider>
    )
}

export function useQueue(queue: string, executeFn: QueueExecuteFn, initialSize = 0) {
    const value = useContext(QueueContext)
    const queueRef = useRef<string>(queue)

    if (value === null) {
        throw new Error('You have forgot to use <QueueProvider />, shame on you.')
    }

    useEffect(() => {
        value.register(queue, executeFn, initialSize)

        if (queueRef.current !== queue) {
            value.unregister(queue)
        }
        queueRef.current = queue
    }, [queue, executeFn, value, initialSize])

    const setQueueSize = useCallback(
        (size: number) => {
            value.setQueueSize(queueRef.current, size)
        },
        [value],
    )

    return setQueueSize
}

export function useQueueConsumer(queue: string): [size: number | null, execute: QueueExecuteFn] {
    const value = useContext(QueueContext)

    if (value === null) {
        throw new Error('You have forgot to use <QueueProvider />, shame on you.')
    }

    const [size, setSize] = useState(value.getQueueSize(queue))

    const execute = useCallback(() => {
        value.execute(queue)
    }, [queue, value])

    useEffect(() => {
        const onSizeChange = (q: string, qSize: number) => {
            if (q === queue) {
                setSize(qSize)
            }
        }

        value.subscribe('size_change', onSizeChange)

        return () => {
            value.unsubscribe('size_change', onSizeChange)
        }
    }, [queue, value])

    return useMemo(() => [size, execute], [execute, size])
}
