import { MutableRefObject } from 'react'

import { OverlayToaster, OverlayToasterProps, ToastProps } from '@blueprintjs/core'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { getTextFromReactNode } from 'src/utils/text'
import { announceText } from 'src/utils/announce'

export const styledToastClassName = 'ai-panel-styled-toast'
export const styledToastWithClose = 'with-close'

export const ToastStyles = styled.div`
    .${styledToastClassName} {
        min-width: unset;
        align-items: center;
        gap: 4px;
        padding: 7px 13px 7px 7px;
        background-color: ${theme('palette.toast.backgroundColor')};
        border: 1px solid ${theme('palette.toast.borderColor')};
        box-shadow: ${theme('palette.toast.shadow')};

        .bp5-toast-message {
            padding: 0;
            color: ${theme('palette.toast.textColor')};
            font-size: 14px;
            font-style: normal;
            line-height: 21px;
        }
        &.bp5-intent-success {
            .bp5-toast-message {
                color: ${theme('palette.toast.successIconColor')};
            }
        }

        .bp5-button-group {
            padding: 0;
            a[role='button'] {
                padding: 0;
                font-size: 14px;
                line-height: 21px;
                min-height: auto;
                background: none;
                text-decoration: underline;
                text-underline-offset: 2px;
                color: ${theme('palette.toast.actionColor')};
            }
            button {
                display: none;
            }
        }

        & > svg {
            margin-right: 4px;
        }

        &.${styledToastWithClose} {
            .bp5-button-group {
                padding-left: 8px;
                button {
                    display: flex;
                    min-width: 16px;
                    min-height: 21px;
                    padding: 0 0 5px;
                    &:hover {
                        background: initial;
                    }
                    .bp5-icon {
                        margin: 0;
                        color: ${theme('palette.toast.closeIconColor')};
                    }
                }
            }
        }
    }
`

// the purpose of this proxy is to intercept `Toaster.show` calls throughout the app,
// gather the text from the toast and announce it automatically (to avoid calling
// `announceText` manually alongside with every toast)
const ToasterProxy = new Proxy(OverlayToaster, {
    construct(target, argArray) {
        const toaster = new target(argArray[0])
        const proxy = new Proxy(toaster, {
            get(getTarget, propKey, receiver) {
                const targetValue = Reflect.get(getTarget, propKey, receiver)
                if (propKey === 'show') {
                    return function (this: OverlayToaster, props: ToastProps) {
                        const message = getTextFromReactNode(props.message)
                        announceText(message)

                        return targetValue.apply(this, [props])
                    }
                } else {
                    return targetValue
                }
            },
        })

        return proxy
    },
})

interface StyledToastWrapperProps extends OverlayToasterProps {
    toasterRef: MutableRefObject<OverlayToaster | null>
}

export function StyledToastWrapper(props: StyledToastWrapperProps) {
    const { toasterRef, ...toasterProps } = props

    return (
        <ToastStyles>
            <ToasterProxy ref={toasterRef} {...toasterProps} />
        </ToastStyles>
    )
}
