import { useEffect, useState } from 'react'

import styled, { useTheme } from 'styled-components'
import { Overlay } from '@blueprintjs/core'
import { CheckIcon, RightChevronIcon } from '@verbit-ai/icons-library'
import { theme } from 'styled-tools'

import { useSetThemeField } from 'src/contexts/ThemeContext'
import { analyticsService } from 'src/services/AnalyticsService'
import { THEME_TYPES } from 'src/themes/types'
import { getMobileOverlayTransitionCss } from 'src/global-styles'

import { THEMES_PRESENTATION, ThemeToggleProps } from './types'
import { ThemeButton } from './ThemeButton'
import { ThemeTextCustomization } from './ThemeTextCustomization'

const OVERLAY_TRANSITION_NAME = 'theme-toggle-transition'
const OVERLAY_TRANSITION_DURATION = 300

const ThemeToggleContainer = styled.div`
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme('palette.themeToggle.backgroundColor')};
    padding: 24px 24px 32px;

    ${getMobileOverlayTransitionCss(OVERLAY_TRANSITION_NAME, OVERLAY_TRANSITION_DURATION)}
`

const ThemeSelectionTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    background: ${theme('palette.themeToggle.titleColor')};
    padding: 16px 0;

    background-clip: text;
    -webkit-background-clip: text;

    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
`

type ThemeToggleMobileStage = 'themesList' | 'textCustomization'

const componentTitles: Record<ThemeToggleMobileStage, string> = {
    themesList: 'Select a theme',
    textCustomization: 'Custom text',
}

export function ThemeToggleMobile({ isOpen, onClose }: ThemeToggleProps) {
    const {
        palette: {
            themeToggle: { iconColor },
        },
        themeType: activeThemeType,
    } = useTheme()
    const setThemeField = useSetThemeField()
    const [stage, setStage] = useState<ThemeToggleMobileStage>('themesList')

    useEffect(() => {
        if (isOpen) {
            analyticsService.track('Accessibility panel opened', {})
        }
    }, [isOpen])

    const templatesByStage: Record<ThemeToggleMobileStage, JSX.Element> = {
        themesList: (
            <>
                {THEME_TYPES.filter((themeType) => THEMES_PRESENTATION[themeType].isVisible).map((themeType) => (
                    <ThemeButton key={themeType} onClick={() => setThemeField('themeType', themeType)}>
                        {THEMES_PRESENTATION[themeType].name}
                        {themeType === activeThemeType && <CheckIcon color={iconColor} />}
                    </ThemeButton>
                ))}
                <ThemeButton onClick={() => setStage('textCustomization')}>
                    Custom text <RightChevronIcon color={iconColor} />
                </ThemeButton>
            </>
        ),
        textCustomization: <ThemeTextCustomization />,
    }

    const onOverlayClose = () => {
        onClose()
        setStage('themesList')
    }

    return (
        <Overlay
            isOpen={isOpen}
            onClose={onOverlayClose}
            canEscapeKeyClose
            canOutsideClickClose
            hasBackdrop
            transitionName={OVERLAY_TRANSITION_NAME}
            transitionDuration={OVERLAY_TRANSITION_DURATION}
            lazy={false}>
            <ThemeToggleContainer>
                <ThemeSelectionTitle>{componentTitles[stage]}</ThemeSelectionTitle>
                {templatesByStage[stage]}
            </ThemeToggleContainer>
        </Overlay>
    )
}
