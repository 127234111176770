import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import flagsmith from 'flagsmith'
import { IFlags } from 'flagsmith/types'

import { getFlagsmithApiKey } from 'src/utils/env'
import { TranscriptMetadata } from 'src/models/TranscriptMetadata'

import { useTranscriptMetadata } from './TranscriptContext'

export type FeatureName = 'prompt_result_editing' | 'new_insights_panel' | 'insights_panel_init_open_state'

export type FeatureFlagContextType = IFlags<FeatureName> | null

export type FlagsmithUserTraits = Pick<TranscriptMetadata, 'customerId'>

export const FeatureFlagContext = createContext<FeatureFlagContextType>(null)

interface FeatureFlagProviderProps {
    children: ReactNode
}

export const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps) => {
    const [flags, setFlags] = useState<FeatureFlagContextType>(null)
    const metadata = useTranscriptMetadata()
    const environmentID = getFlagsmithApiKey()

    useEffect(() => {
        if (!environmentID) {
            console.warn('Failed to init flagsmith: API key not provided')

            return
        }
        flagsmith.init({
            environmentID,
            cacheFlags: true,
            enableAnalytics: true, // See https://docs.flagsmith.com/flag-analytics/ for more info
            onChange: () => setFlags(flagsmith.getAllFlags()),
        })
    }, [environmentID])

    useEffect(() => {
        if (metadata?.customerId) {
            const traits: FlagsmithUserTraits = {
                customerId: metadata.customerId,
            }
            flagsmith.identify(metadata.customerId.toString(), traits)
        }
    }, [metadata?.customerId])

    return <FeatureFlagContext.Provider value={flags}>{children}</FeatureFlagContext.Provider>
}

export const useFeatureFlag = (featureName: FeatureName) => {
    const flags = useContext(FeatureFlagContext)

    return flags?.[featureName]?.enabled || false
}
