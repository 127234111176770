import { Transcript } from 'src/models/Transcript'
import { TranscriptMetadata } from 'src/models/TranscriptMetadata'
import { TranscriptWord } from 'src/models/TranscriptWord'

export function getUniqueFilename(prefix: string) {
    const now = new Date()
    const timeString = now.toISOString().replace(/[:T]/g, '-').replace(/\..+/g, '')

    return `${prefix}-${timeString}`
}

interface DownloadTextFileParams {
    text: string
    filename: string
    mimetype?: string
    ext?: string
}
export function downloadTextFile({ text, filename, mimetype, ext }: DownloadTextFileParams) {
    // Create a text file with the transcript text
    const blob = new Blob([text], { type: mimetype || 'text/plain' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    link.download = `${filename}.${ext || 'txt'}`
    link.href = url
    link.click()

    URL.revokeObjectURL(url)
    link.remove()
}

export function downloadTranscriptFile(transcript: Transcript, metadata: TranscriptMetadata) {
    // Create an empty array for the lines of text
    const lines = []

    const totalParagraphCount = Transcript.getTotalParagraphCount(transcript)

    // Iterate over the items in the paragraphs array
    for (let i = 0; i < totalParagraphCount; i++) {
        const paragraph = Transcript.getParagraphByIndex(transcript, i)

        // Create a line with the speaker's name and their speech
        let line = `Speaker: ${TranscriptWord.concat(paragraph.words)}`

        // Add the line to the array
        lines.push(line)

        // Add empty line between paragraphs
        lines.push('')
    }

    // Join the lines into a single string with line breaks
    const text = lines.join('\n')

    downloadTextFile({
        text,
        filename: metadata.sessionName,
    })
}
