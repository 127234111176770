import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { FONT_SIZE_PERCENTAGE_DEFAULT, FONT_SIZE_PERCENTAGE_OPTIONS, UIConfiguration } from 'src/models/UIConfiguration'
import { isChromakeyModeActive } from 'src/utils/env'

const LOCAL_STORAGE_KEY = 'verbitext_ui_configuration_v2'

type ConfigSetFn = <T extends keyof UIConfiguration>(
    prop: T,
    value: UIConfiguration[T] | ((oldVal: UIConfiguration[T]) => UIConfiguration[T]),
) => unknown

interface ConfigurationContextProps {
    config: UIConfiguration
    setProp: ConfigSetFn
}

const ConfigurationContext = React.createContext<ConfigurationContextProps | null>(null)

interface ConfigurationProviderProps {
    children: React.ReactNode
}

function getUiConfigOverrides(): Partial<UIConfiguration> {
    const urlParams = new URLSearchParams(window.location.search)
    const overrides: Partial<UIConfiguration> = {}

    const fontSizePercentageParam = parseInt(urlParams.get('fontSizePercentage') || '')
    if (FONT_SIZE_PERCENTAGE_OPTIONS.includes(fontSizePercentageParam)) {
        overrides.fontSizePercentage = fontSizePercentageParam
    }

    let isHeaderHiddenParam: any
    try {
        isHeaderHiddenParam = JSON.parse(urlParams.get('isHeaderHidden') || '')
    } catch (e) {}
    if (typeof isHeaderHiddenParam === 'boolean') {
        overrides.isHeaderHidden = isHeaderHiddenParam
    }

    return overrides
}

export function ConfigurationProvider({ children }: ConfigurationProviderProps) {
    const [config, setConfig] = useState<UIConfiguration>(() => {
        const defaultConfig: UIConfiguration = {
            fontSizePercentage: FONT_SIZE_PERCENTAGE_DEFAULT,
            isHeaderHidden: false,
            isFullWidthMode: false,
        }

        // access to local storage might be blocked in incognito mode
        try {
            const savedConfig = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '')
            if (savedConfig) {
                Object.assign(defaultConfig, savedConfig)
            }
        } catch (e) {}

        const configOverrides = getUiConfigOverrides()
        Object.assign(defaultConfig, configOverrides)

        return defaultConfig
    })

    const setProp = useCallback<ConfigSetFn>((prop, value) => {
        setConfig((oldConfig) => ({
            ...oldConfig,
            [prop]: sanitise(prop, typeof value === 'function' ? value(oldConfig[prop]) : value),
        }))
    }, [])

    const isChromakeyMode = isChromakeyModeActive()
    useEffect(() => {
        if (isChromakeyMode) {
            return
        }
        try {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(config))
        } catch (e) {}
    }, [config, isChromakeyMode])

    return (
        <ConfigurationContext.Provider value={useMemo(() => ({ config, setProp }), [config, setProp])}>
            {children}
        </ConfigurationContext.Provider>
    )
}

export function useConfig(): [UIConfiguration, ConfigSetFn] {
    const ctx = useContext(ConfigurationContext)

    if (!ctx) {
        throw new Error('You forgot to use <ConfigurationProvider />, shame on you!')
    }

    const { config, setProp } = ctx

    return [config, setProp]
}

function sanitise<T extends keyof UIConfiguration>(prop: T, value: UIConfiguration[T]): UIConfiguration[T] {
    switch (prop) {
        case 'fontSizePercentage':
            return FONT_SIZE_PERCENTAGE_OPTIONS.includes(value as number) ? value : (FONT_SIZE_PERCENTAGE_DEFAULT as UIConfiguration[T])
        default:
            return value
    }
}
