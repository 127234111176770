import { EventEmitter } from 'events'

import { StreamClient, StreamMessage } from './StreamClient'

export class StreamQueue<T extends StreamMessage['action']> {
    private queue: Extract<StreamMessage, { action: T }>[] = []
    private client: StreamClient
    private readonly eventName: T
    private emitter = new EventEmitter()

    constructor(client: StreamClient, event: T) {
        this.client = client
        this.eventName = event

        client.on(event, this.onCallback)
    }

    private onCallback = (message: Extract<StreamMessage, { action: T }>) => {
        this.queue.push(message)
        this.emitter.emit('size_change', this.queue.length)
    }

    on(event: string, callback: (value: any) => void) {
        this.emitter.on(event, callback)
    }

    drain(): Extract<StreamMessage, { action: T }>[] {
        const queue = this.queue
        this.queue = []
        this.emitter.emit('size_change', 0)

        return queue
    }

    close() {
        this.client.off(this.eventName, this.onCallback)
    }
}
