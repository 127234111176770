import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { useIdle } from 'src/hooks/useIdle'
import { FontSizeControlChromakey } from 'src/components/FontSizeControl/FontSizeControlChromakey'
import { ThemeTextCustomizationChromakey } from 'src/components/ThemeToggle/ThemeTextCustomizationChromakey'
import { useTranscript } from 'src/contexts/TranscriptContext'
import { getTranscriptDimensions } from 'src/components/constants'
import { useConfig } from 'src/contexts/ConfigurationContext'

import { TranscriptToolbarBar } from './TranscriptToolbarBar'
import { TranscriptToolbarLimiter } from './TranscriptToolbarLimiter'

const Panel = styled.div<{ $isOpaque: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    opacity: ${ifProp('$isOpaque', 1, 0)};
    pointer-events: ${ifProp('$isOpaque', 'all', 'none')};
    transition: opacity 0.3s ease-in-out;
`

interface TranscriptToolbarChromakeyProps {
    containerWidth: number
}

export function TranscriptToolbarChromakey({ containerWidth }: TranscriptToolbarChromakeyProps) {
    const isIdle = useIdle(5000)
    const transcript = useTranscript()
    const [{ fontSizePercentage, isFullWidthMode }] = useConfig()
    const { textMaxWidth, transcriptParagraphWidth } = getTranscriptDimensions({
        isDiarizationSupported: transcript.isDiarizationSupported,
        fontSizePercentage,
        isFullWidthMode,
        containerWidth,
    })

    return (
        <TranscriptToolbarBar $keepOnTablet>
            <TranscriptToolbarLimiter $textMaxWidth={textMaxWidth} $paragraphTotalWidth={transcriptParagraphWidth} $isPanelOpen={false}>
                <Panel $isOpaque={!isIdle}>
                    <FontSizeControlChromakey />
                    <ThemeTextCustomizationChromakey />
                </Panel>
            </TranscriptToolbarLimiter>
        </TranscriptToolbarBar>
    )
}
