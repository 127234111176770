import { AriaAttributes, MouseEventHandler, MutableRefObject } from 'react'

import styled from 'styled-components'
import { prop, theme } from 'styled-tools'

interface GradientButtonProps {
    children?: React.ReactNode
    className?: string
    isFocusable: boolean
    ariaLabel: string
    ariaHasPopup?: AriaAttributes['aria-haspopup']
    innerRef?: MutableRefObject<HTMLButtonElement | null>
    onClick?: MouseEventHandler
}

const Button = styled.button<{ $borderWidth: number }>`
    position: relative;
    padding: 11px;
    box-sizing: border-box;
    cursor: pointer;

    background-color: ${theme('palette.gradientButton.backgroundColor')};
    background-clip: padding-box;
    border: solid ${prop('$borderWidth')}px transparent;
    border-radius: 4px;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -${prop('$borderWidth')}px;
        border-radius: inherit;
        background: ${theme('palette.gradientButton.gradient')};
    }

    &:hover {
        background-color: ${theme('palette.gradientButton.hoverBgColor')};
    }
`

export const GRADIENT_BUTTON_CLASSNAME = 'gradient-button'

export function GradientButton({ children, className, isFocusable, ariaLabel, ariaHasPopup, innerRef, onClick }: GradientButtonProps) {
    return (
        <Button
            ref={innerRef}
            className={`${GRADIENT_BUTTON_CLASSNAME} ${className || ''}`}
            $borderWidth={1}
            tabIndex={isFocusable ? 0 : -1}
            aria-label={ariaLabel}
            aria-haspopup={ariaHasPopup}
            onClick={onClick}>
            {children}
        </Button>
    )
}
