import styled from 'styled-components'
import { Header2 } from '@verbit-ai/verbit-ui-library'

import { Theme } from 'src/themes/types'

export const HelpButton = styled(Header2.Link)`
    margin-left: auto;
`

export const helpButtonStyle = (theme: Theme) => ({
    color: theme.palette.header.linkColor,
    '&:hover': {
        color: theme.palette.header.linkColor,
    },
})

export const navContainerStyle = (isMobilePhone: boolean) => ({
    marginLeft: isMobilePhone ? 'auto' : '0',
})
