import { User } from 'src/models/User'

import { ServiceBase } from './ServiceBase'

class ProductAdoptionService extends ServiceBase {
    constructor() {
        super({ blockedEnvs: ['dev'] })
    }

    init(): void {
        // no init needed
    }

    identify(id?: string, props?: User): void {
        if (id) {
            window.Appcues?.identify(id, props)
        } else {
            window.Appcues?.anonymous()
        }
    }

    // note: certain events are also triggers for displaying modal windows
    // https://docs.appcues.com/en_US/user-experiences-studio/event-triggering
    track(event: string, data?: object) {
        window.Appcues?.track(event, data)
    }

    trackOpenFeedbackModal() {
        this.track('OPEN_FEEDBACK_MODAL')
    }

    trackOpenAiIntroNotification() {
        this.track('OPEN_GENERATIVE_NOTICE')
    }
}

export const productAdoptionService = new ProductAdoptionService()
