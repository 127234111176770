import { Palette } from './types'

export const paletteChromakey: Palette = {
    global: {
        focusIndicatorColor: 'transparent',
        iconColor: '',
    },
    header: {
        logo: <></>,
        titleColor: '',
        panelBackgroundColor: '',
        panelFilter: '',
        panelBorder: '',
        linkColor: '',
        dropdownTextColor: '',
        dropdownIconColor: '',
        dropdownBgColor: '',
        dropdownBorderColor: '',
    },
    transcript: {
        toolbarOuterBackground: '#00B140',
        toolbarInnerBackground: 'transparent',
        titleColor: '',
        speakerColors: ['#020219', '#ffffff', '', '', '', ''],
        timestampColors: ['#4E4E5F', '#E4E4E5', '', '', '', ''],
        backgroundColor: '#00B140',
        textColors: [
            { colorValue: '#000000', colorTitle: 'Black' },
            { colorValue: '#ffffff', colorTitle: 'White' },
            { colorValue: '', colorTitle: '' },
            { colorValue: '', colorTitle: '' },
            { colorValue: '', colorTitle: '' },
            { colorValue: '', colorTitle: '' },
        ],
        textBackgroundColors: [
            { colorValue: '#00B140', colorTitle: 'Green' },
            { colorValue: '', colorTitle: '' },
            { colorValue: '', colorTitle: '' },
            { colorValue: '', colorTitle: '' },
            { colorValue: '', colorTitle: '' },
            { colorValue: '', colorTitle: '' },
        ],
        loaderColor: '#000000',
        searchResultHighlight: '',
    },
    gradientButton: {
        gradient: '',
        backgroundColor: '',
        hoverBgColor: '',
        iconColor: '',
        textColor: '',
    },
    verbitButton: {
        primaryBackgroundColor: '',
        primaryHoverBgColor: '',
        primaryBorderColor: '',
        primaryHoverBorderColor: '',
        primaryTextColor: '',
        secondaryBackgroundColor: '',
        secondaryHoverBgColor: '',
        secondaryBorderColor: '',
        secondaryHoverBorderColor: '',
        secondaryTextColor: '',
    },
    search: {
        outerBorderColor: '',
        innerBorderColor: '',
        inputBgColor: '',
        textColor: '',
        placeholderColor: '',
        clearButtonColor: '',
        navButtonColor: '',
        resultsColor: '',
    },
    fontSizeControl: {
        backgroundColor: '',
        iconColor: '',
        textColor: '',
        optionHoverColor: '',
        delimiterColor: '',
    },
    themeToggle: {
        backgroundColor: '',
        iconColor: '',
        textColor: '',
        titleColor: '',
        colorOptionsBgColor: '',
        fontOptionsBorderColor: '',
        defaultColorIndicatorStroke: '',
        colorIndicatorActiveColor: '',
        mobileColorIndicatorBorderColor: '',
        mobileButtonBorderColor: '',
        mobileActiveColor: '',
    },
    languageSelect: {
        desktopTextColor: '',
        mobileTextColor: '',
        iconColor: '',
        separatorColor: '',
        mobileTitleColor: '',
        mobileBgColor: '',
    },
    toast: {
        backgroundColor: '',
        borderColor: '',
        textColor: '',
        successTextColor: '',
        actionColor: '',
        successIconColor: '',
        infoIconColor: '',
        closeIconColor: '',
        shadow: '',
    },
    scrollDownButton: {
        backgroundColor: '#00B140',
        borderColor: '#757584',
        shadow: '0px 2px 10px 4px rgb(255 255 255 / 10%)',
        iconColor: '#ffffff',
    },
    routingError: {
        backgroundColor: '',
        textColor: '',
        linkColor: '',
    },
    confirmationOverlay: {
        backdropColor: '',
        panelShadow: '',
        panelBackground: '',
        textColor: '',
    },
}
