import styled, { css, useTheme } from 'styled-components'
import { ifProp, prop, theme } from 'styled-tools'
import { CheckIcon } from '@verbit-ai/icons-library'

import { DEFAULT_FONT_FAMILY_BY_LANGUAGE } from 'src/themes/types'
import { useSetThemeField } from 'src/contexts/ThemeContext'
import { useResponsive } from 'src/hooks/useResponsive'
import { useTranscriptMetadata } from 'src/contexts/TranscriptContext'

import { FONT_FAMILIES_PRESENTATION } from './types'
import { ThemeButton } from './ThemeButton'

const Container = styled.div`
    padding-bottom: 2px;
`

const SectionTitle = styled.div`
    padding: 16px 16px 8px 16px;
    font-size: 14px;
    line-height: 21px;
    color: ${theme('palette.themeToggle.textColor')};

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        padding: 0 0 10px 0;
        font-size: 16px;
        line-height: 24px;
    }
`

const ColorsSelection = styled.div`
    padding: 8px 0 16px;
    margin: 0 2px;
    background-color: ${theme('palette.themeToggle.colorOptionsBgColor')};
    width: 190px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 8px;

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        width: calc(100% + 44px);
        position: relative;
        left: -24px;
        column-gap: 12px;
        padding: 2px 24px 16px 24px;
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-x: scroll;
        background-color: transparent;
    }
`

const ColorOption = styled.button`
    width: 68px;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    color: ${theme('palette.themeToggle.textColor')};

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        width: auto;
        flex-direction: column;
        gap: 6px;
        font-size: 10px;
        line-height: 14px;
    }
`

const ColorIndicator = styled.span<{ $colorValue: string; $isActive: boolean; $stroke: boolean }>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${prop('$colorValue')};
    ${ifProp(
        '$stroke',
        css`
            border: 1px solid ${theme('palette.themeToggle.defaultColorIndicatorStroke')};
        `,
    )};
    position: relative;

    &:before {
        display: ${ifProp('$isActive', 'block', 'none')};
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        border: 1px solid ${theme('palette.themeToggle.colorIndicatorActiveColor')};
        border-radius: 50%;
    }

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        width: 72px;
        height: 72px;
        border-radius: 8px;
        border: 1px solid ${theme('palette.themeToggle.mobileColorIndicatorBorderColor')};
        &:before {
            border-radius: 8px;
            top: -3px;
            left: -3px;
            right: -3px;
            bottom: -3px;
            border-color: ${theme('palette.themeToggle.mobileActiveColor')};
        }
    }
`

const FontsSelection = styled.div`
    border-top: 1px solid ${theme('palette.themeToggle.fontOptionsBorderColor')};
    border-bottom: 1px solid ${theme('palette.themeToggle.fontOptionsBorderColor')};

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        display: flex;
        gap: 12px;
        width: calc(100% + 44px);
        position: relative;
        left: -24px;
        padding: 2px 24px 18px;
        overflow-x: scroll;
        border: none;
    }
`

const fontOptionActiveClassname = 'active'
const FontOption = styled(ThemeButton)`
    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        padding: 16px 30px;
        line-height: 21px;
        white-space: nowrap;
        border-radius: 4px;
        border: 1px solid ${theme('palette.themeToggle.mobileButtonBorderColor')};
        position: relative;
        &.${fontOptionActiveClassname}:before {
            display: block;
            content: '';
            position: absolute;
            top: -3px;
            left: -3px;
            right: -3px;
            bottom: -3px;
            border: 1px solid ${theme('palette.themeToggle.mobileActiveColor')};
            border-radius: 4px;
        }
    }
`

export function ThemeTextCustomization() {
    const {
        palette: {
            transcript: { textColors, textBackgroundColors },
            themeToggle: { iconColor, colorOptionsBgColor },
        },
        fontFamily: activeFontFamily,
        availableFontFamilies,
        textColorIndex,
        textBackgroundColorIndex,
    } = useTheme()
    const setThemeField = useSetThemeField()
    const { isTablet } = useResponsive()

    const metadata = useTranscriptMetadata()
    const defaultFontFamily = DEFAULT_FONT_FAMILY_BY_LANGUAGE[metadata!.currentLanguage]

    return (
        <Container>
            <SectionTitle>Text background color</SectionTitle>
            <ColorsSelection>
                {textBackgroundColors.map(({ colorValue, colorTitle }, index) => (
                    <ColorOption key={colorTitle} onClick={() => setThemeField('textBackgroundColorIndex', index)}>
                        <ColorIndicator
                            $colorValue={colorValue}
                            $isActive={index === textBackgroundColorIndex}
                            $stroke={colorValue.toLowerCase() === colorOptionsBgColor.toLowerCase()}
                        />
                        {colorTitle}
                    </ColorOption>
                ))}
            </ColorsSelection>
            {isTablet && <SectionTitle>Font type</SectionTitle>}
            <FontsSelection>
                {availableFontFamilies.map((fontFamily) => (
                    <FontOption
                        key={fontFamily}
                        className={fontFamily === activeFontFamily && isTablet ? fontOptionActiveClassname : ''}
                        onClick={() => setThemeField('fontFamily', fontFamily)}>
                        {FONT_FAMILIES_PRESENTATION[fontFamily]}
                        {fontFamily === defaultFontFamily && ' (default)'}
                        {fontFamily === activeFontFamily && !isTablet && <CheckIcon color={iconColor} />}
                    </FontOption>
                ))}
            </FontsSelection>
            <SectionTitle>Text color</SectionTitle>
            <ColorsSelection>
                {textColors.map(({ colorValue, colorTitle }, index) => (
                    <ColorOption key={colorTitle} onClick={() => setThemeField('textColorIndex', index)}>
                        <ColorIndicator $colorValue={colorValue} $isActive={index === textColorIndex} $stroke={false} />
                        {colorTitle}
                    </ColorOption>
                ))}
            </ColorsSelection>
        </Container>
    )
}
