import { useTheme } from 'styled-components'

const BLUEPRINTJS_DARK_THEME_CLASS = 'bp5-dark'

/**
 * BlueprintJS lib doesn't accept any styling props,
 * but provides its own dark theme; so, while we cannot update styles
 * for components like Popover or Tooltip directly (only for their content),
 * we can at least make sure that they are using dark mode properly
 * https://blueprintjs.com/docs/versions/3/#popover2-package/popover2.style
 */
export function useBlueprintThemeClass() {
    const { themeType } = useTheme()

    return themeType === 'dark' ? BLUEPRINTJS_DARK_THEME_CLASS : ''
}
