import { MouseEvent, TouchEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { Icon } from '@blueprintjs/core'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { LiveState } from 'src/mocks/LiveEventPlayer'

const IconWrapper = styled.button<{ disabled?: boolean }>`
    cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
    padding: 0px 4px;
    margin: 0px 4px;

    background: inherit;
    border: 0;

    opacity: ${ifProp('disabled', '0.5', '1')};
`

export function MockReplayController() {
    const player = useMemo(() => document.mocks.getLiveEventPlayer(), [])
    const [isPlaying, setIsPlaying] = useState(player?.getCurrentPlayerState() === 'playing')
    const [liveState, setLiveState] = useState<LiveState>({ index: 0, length: 0 })

    const onPlayPauseToggle = useCallback(
        (e: MouseEvent | TouchEvent) => {
            e.preventDefault()
            e.stopPropagation()

            if (isPlaying) {
                player.pause()
            } else {
                player.play()
            }
        },
        [isPlaying, player],
    )

    const onStepForward = useCallback(
        (e: MouseEvent | TouchEvent, count?: number) => {
            e.preventDefault()
            e.stopPropagation()

            if (isPlaying) return

            player.step(count)
        },
        [isPlaying, player],
    )

    const onStep10StepsForward = useCallback((e: MouseEvent | TouchEvent) => onStepForward(e, 100), [onStepForward])

    useEffect(() => {
        const onPlay = () => setIsPlaying(true)
        const onPause = () => setIsPlaying(false)

        player.on('play', onPlay)
        player.on('pause', onPause)
        player.on('live_state_update', setLiveState)

        return () => {
            player.off('play', onPlay)
            player.off('pause', onPause)
            player.off('live_state_update', setLiveState)
        }
    }, [player])

    return (
        <>
            <div>
                {liveState.index} / {liveState.length}
            </div>

            <IconWrapper onClick={onPlayPauseToggle} onTouchStart={onPlayPauseToggle} disabled={liveState.index + 1 >= liveState.length}>
                <Icon icon={isPlaying ? 'pause' : 'play'} />
            </IconWrapper>

            <IconWrapper
                onClick={onStepForward}
                onTouchStart={onStepForward}
                disabled={isPlaying || liveState.index + 1 >= liveState.length}>
                <Icon icon="step-forward" />
            </IconWrapper>

            <IconWrapper
                onClick={onStep10StepsForward}
                onTouchStart={onStep10StepsForward}
                disabled={isPlaying || liveState.index + 10 >= liveState.length}>
                <Icon icon="step-forward" />
                <Icon icon="step-forward" />
            </IconWrapper>

            <div>
                <i>next:</i>{' '}
                {liveState.index + 1 === liveState.length ? '-' : document.mocks.getLiveEvents()?.[liveState.index + 1]?.action}
            </div>
        </>
    )
}
