import { FontFamily, ThemeType } from 'src/themes/types'

interface ThemePresentation {
    name: string
    isVisible: boolean
}
export const THEMES_PRESENTATION: Record<ThemeType, ThemePresentation> = {
    default: {
        name: 'Default theme',
        isVisible: true,
    },
    dark: {
        name: 'Dark theme',
        isVisible: true,
    },
    highContrast: {
        name: 'Increase contrast',
        isVisible: true,
    },
    chromakey: {
        name: 'Green chromakey',
        isVisible: false,
    },
}

export const FONT_FAMILIES_PRESENTATION: Record<FontFamily, string> = {
    Arial: 'Arial',
    Inter: 'Inter',
    Roboto: 'Roboto',
    SourceSerif: 'Source Serif',
    NotoSansSC: 'Noto Sans',
    NotoSansJP: 'Noto Sans',
    NotoSansKR: 'Noto Sans',
    Hind: 'Hind',
}

export interface ThemeToggleProps {
    isOpen: boolean
    onClose: () => void
    children?: React.ReactNode
}
