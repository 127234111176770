import { HttpClientInterface } from 'src/network/HttpClientInterface'
import { Transcript } from 'src/models/Transcript'
import { TranscriptMetadata } from 'src/models/TranscriptMetadata'

export class MockHttpClient implements HttpClientInterface {
    async getTranscript(): Promise<Transcript> {
        await document.mocks.isReady()

        return this.hydrateTranscript(document.mocks.getInitialTranscript())
    }

    async getTranscriptMetadata(): Promise<TranscriptMetadata> {
        await document.mocks.isReady()

        return this.hydrateMetadata(document.mocks.getTranscriptMetadata())
    }

    async identify() {
        await document.mocks.isReady()

        if (document.mocks.isAnonymousUser()) {
            throw new Error('No user')
        } else {
            return {
                email: 'user@mail.com',
                rules: ['customer_admin'],
                companyName: 'company',
                customerId: null,
                userName: 'user',
                userId: 1337,
            }
        }
    }

    private hydrateMetadata(metadata: TranscriptMetadata) {
        if (metadata.sessionState?.startTime) {
            metadata.sessionState.startTime = new Date(metadata.sessionState.startTime)
        }

        return metadata
    }

    private hydrateTranscript(transcript: Transcript) {
        transcript.recordingStartedAt = new Date(transcript.recordingStartedAt)

        return transcript
    }
}
