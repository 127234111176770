import { VerbitLogo } from '@verbit-ai/icons-library'

import { Palette } from './types'

export const paletteDefault: Palette = {
    global: {
        focusIndicatorColor: '#1A4AF0',
        iconColor: '#304571',
    },
    header: {
        logo: <VerbitLogo theme="light" />,
        titleColor: '#030d69',
        panelBackgroundColor: '#ffffff',
        panelFilter: 'drop-shadow(0px 4px 20px rgba(0,0,0,0.1))',
        panelBorder: 'transparent',
        linkColor: '#1A4AF0',
        dropdownTextColor: '#030d69',
        dropdownIconColor: '#304571',
        dropdownBgColor: '#ffffff',
        dropdownBorderColor: 'transparent',
    },
    transcript: {
        toolbarOuterBackground: '#ffffff',
        toolbarInnerBackground: 'transparent',
        titleColor: '#573aa6',
        speakerColors: ['#1a4af0', '#1a4af0', '#1a4af0', '#1a4af0', '#1a4af0', '#1a4af0'],
        timestampColors: ['#757584', '#757584', '#757584', '#757584', '#757584', '#757584'],
        backgroundColor: '#ffffff',
        textColors: [
            { colorValue: '#000000', colorTitle: 'Black' },
            { colorValue: '#4C2C92', colorTitle: 'Purple' },
            { colorValue: '#046492', colorTitle: 'Blue' },
            { colorValue: '#174E22', colorTitle: 'Green' },
            { colorValue: '#981B1E', colorTitle: 'Red' },
            { colorValue: '#5A3A44', colorTitle: 'Brown' },
        ],
        textBackgroundColors: [
            { colorValue: '#ffffff', colorTitle: 'White' },
            { colorValue: '#B1FDFF', colorTitle: 'Teal' },
            { colorValue: '#DBE8C7', colorTitle: 'Green' },
            { colorValue: '#D0D9FC', colorTitle: 'Blue' },
            { colorValue: '#FAC6D7', colorTitle: 'Pink' },
            { colorValue: '#D2B48C', colorTitle: 'Tan' },
        ],
        loaderColor: '#000000',
        searchResultHighlight: '#6EB5FF',
    },
    gradientButton: {
        gradient: 'linear-gradient(to right, #543aa8cc 0%, #1a4af0cc 50%, #28d8c5cc 100%)',
        backgroundColor: '#fff',
        hoverBgColor: '#f4f6fe',
        iconColor: '#1a4af0',
        textColor: '#1a4af0',
    },
    verbitButton: {
        primaryBackgroundColor: '#1a4af0',
        primaryHoverBgColor: '#486ef3',
        primaryBorderColor: '#1a4af0',
        primaryHoverBorderColor: '#1a4af0',
        primaryTextColor: '#ffffff',
        secondaryBackgroundColor: '#ffffff',
        secondaryHoverBgColor: '#ffffff',
        secondaryBorderColor: '#1a4af0',
        secondaryHoverBorderColor: '#1a4af0',
        secondaryTextColor: '#1a4af0',
    },
    search: {
        outerBorderColor: '#ffffff',
        innerBorderColor: 'transparent',
        inputBgColor: '#f5f5f6',
        textColor: '#020219',
        placeholderColor: '#020219',
        clearButtonColor: '#757584',
        navButtonColor: '#1a4af0',
        resultsColor: '#1a4af0',
    },
    fontSizeControl: {
        backgroundColor: '#ffffff',
        iconColor: '#1a4af0',
        textColor: '#1a4af0',
        optionHoverColor: '#F5F5F6',
        delimiterColor: '#e4e4e5',
    },
    themeToggle: {
        backgroundColor: '#ffffff',
        iconColor: '#1a4af0',
        textColor: '#020219',
        titleColor: 'linear-gradient(270.09deg, #030d69 -2%, #194ddd 57.73%, #573aa6 96.88%)',
        colorOptionsBgColor: '#ffffff',
        fontOptionsBorderColor: '#E4E4E5',
        defaultColorIndicatorStroke: '#E4E4E5',
        colorIndicatorActiveColor: '#1A4AF0',
        mobileColorIndicatorBorderColor: '#B3B3BA',
        mobileButtonBorderColor: '#CCCCD1',
        mobileActiveColor: '#1A4AF0',
    },
    languageSelect: {
        desktopTextColor: '#020219',
        mobileTextColor: '#1a4af0',
        iconColor: '#1a4af0',
        separatorColor: '#e4e4e5',
        mobileTitleColor: 'linear-gradient(270deg, #2b7abb -2%, #194ddd 44.65%, #573aa6 96.88%)',
        mobileBgColor: '#ffffff',
    },
    toast: {
        backgroundColor: '#ffffff',
        borderColor: '#ffffff',
        textColor: '#020219',
        successTextColor: '#008a24',
        actionColor: '#1a4af0',
        successIconColor: '#008a24',
        infoIconColor: '#1A4AF0',
        closeIconColor: '#91919d',
        shadow: '0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2)',
    },
    scrollDownButton: {
        backgroundColor: '#ffffff',
        borderColor: '#ccccd1',
        shadow: '0px 4px 14px 6px rgba(0, 0, 0, 0.1)',
        iconColor: '#4E4E5F',
    },
    routingError: {
        backgroundColor: '#ffffff',
        textColor: '#000000',
        linkColor: '#106ba3',
    },
    confirmationOverlay: {
        backdropColor: 'rgba(255, 255, 255, 0.9)',
        panelShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.1)',
        panelBackground: '#ffffff',
        textColor: '#020219',
    },
}
