import React from 'react'

export const EmptyTranscriptionIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="75" height="83" viewBox="0 0 75 83" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 20H51V23H11V20ZM11 32H51V35H11V32ZM51 26H11V29H51V26ZM11 38H51V41H11V38ZM32 44H11V47H32V44Z"
            fill="#304571"
        />
        <path d="M60 46.5943V1H1V82H60V77.9024" stroke="#304571" strokeWidth="2" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M73.2485 59.4843C73.2485 66.8487 67.2809 72.8151 59.9297 72.8151C52.5677 72.8151 46.6001 66.8487 46.6001 59.4843C46.6001 52.1331 52.5677 46.1655 59.9297 46.1655C67.2809 46.1655 73.2485 52.1331 73.2485 59.4843Z"
            stroke="#304571"
            strokeWidth="2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.1028 57.7788V60.9857C57.1028 62.5985 58.4031 63.9132 60.0159 63.9132C61.6273 63.9132 62.942 62.5985 62.942 60.9857V57.7788C60.9764 57.7788 58.9777 57.7788 57.1028 57.7788Z"
            fill="#28D8C5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.9434 54.3963C62.9434 52.7849 61.6287 51.4688 60.0174 51.4688C58.4046 51.4688 57.1042 52.7849 57.1042 54.3963V55.6822C58.9791 55.6822 60.9778 55.6822 62.9449 55.6822V54.3963H62.9434Z"
            fill="#28D8C5"
        />
        <path
            d="M54.0098 60.1421C54.0098 63.4253 56.6781 66.0936 59.9598 66.0936C63.2286 66.0936 65.8955 63.4253 65.8955 60.1421"
            stroke="#28D8C5"
            strokeWidth="1.44"
            strokeLinecap="round"
        />
        <path d="M60.0635 68.5054V66.2129" stroke="#28D8C5" strokeWidth="1.44" />
    </svg>
)

export const PaletteIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
        <g clipPath="url(#clip0_5449_2697)">
            <path
                d="M9 13.5C9 13.0524 9.17779 12.6232 9.49426 12.3067C9.81072 11.9903 10.2399 11.8125 10.6875 11.8125H13.9366C14.3189 11.8125 14.6898 11.6828 14.9887 11.4446C15.2876 11.2064 15.4967 10.8738 15.582 10.5011C15.6981 9.98955 15.7545 9.46621 15.75 8.94162C15.7184 5.22771 12.645 2.21271 8.92969 2.24998C7.15169 2.2685 5.45281 2.98782 4.20211 4.25169C2.9514 5.51556 2.2499 7.22188 2.25 8.99998C2.25 11.9397 4.12945 14.1637 6.75 15.0897C7.00443 15.1797 7.27672 15.2073 7.54403 15.1703C7.81135 15.1333 8.06588 15.0327 8.28628 14.8769C8.50668 14.7212 8.68652 14.5149 8.8107 14.2753C8.93489 14.0357 8.9998 13.7698 9 13.5Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 6.1875C9.46599 6.1875 9.84375 5.80974 9.84375 5.34375C9.84375 4.87776 9.46599 4.5 9 4.5C8.53401 4.5 8.15625 4.87776 8.15625 5.34375C8.15625 5.80974 8.53401 6.1875 9 6.1875Z"
                fill="currentColor"
            />
            <path
                d="M5.90625 7.875C6.37224 7.875 6.75 7.49724 6.75 7.03125C6.75 6.56526 6.37224 6.1875 5.90625 6.1875C5.44026 6.1875 5.0625 6.56526 5.0625 7.03125C5.0625 7.49724 5.44026 7.875 5.90625 7.875Z"
                fill="currentColor"
            />
            <path
                d="M5.90625 11.8125C6.37224 11.8125 6.75 11.4347 6.75 10.9688C6.75 10.5028 6.37224 10.125 5.90625 10.125C5.44026 10.125 5.0625 10.5028 5.0625 10.9688C5.0625 11.4347 5.44026 11.8125 5.90625 11.8125Z"
                fill="currentColor"
            />
            <path
                d="M12.0938 7.875C12.5597 7.875 12.9375 7.49724 12.9375 7.03125C12.9375 6.56526 12.5597 6.1875 12.0938 6.1875C11.6278 6.1875 11.25 6.56526 11.25 7.03125C11.25 7.49724 11.6278 7.875 12.0938 7.875Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_5449_2697">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export const GlobeIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_7331_1463)">
            <path
                d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.5 7.99999C5.5 10.3412 6.33312 12.4325 7.6425 13.8431C7.68799 13.8926 7.74324 13.9321 7.80478 13.9591C7.86632 13.9861 7.93279 14.0001 8 14.0001C8.06721 14.0001 8.13368 13.9861 8.19522 13.9591C8.25676 13.9321 8.31201 13.8926 8.3575 13.8431C9.66688 12.4325 10.5 10.3412 10.5 7.99999C10.5 5.65874 9.66688 3.56749 8.3575 2.15686C8.31201 2.10739 8.25676 2.06789 8.19522 2.04087C8.13368 2.01386 8.06721 1.99991 8 1.99991C7.93279 1.99991 7.86632 2.01386 7.80478 2.04087C7.74324 2.06789 7.68799 2.10739 7.6425 2.15686C6.33312 3.56749 5.5 5.65874 5.5 7.99999Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M2 8H14" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_7331_1463">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export const MinusIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
        <path d="M3.75 9H14.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const PlusIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
        <path d="M9 3.75V14.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.75 9H14.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const BlackWhiteToggleIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <path
            d="M1.66664 10C1.66664 14.6024 5.39761 18.3333 9.99998 18.3333C14.6024 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39763 14.6024 1.66667 9.99998 1.66667C5.39761 1.66667 1.66664 5.39763 1.66664 10Z"
            fill="white"
            stroke="#020219"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10 18.3333C5.39763 18.3333 1.66667 14.6024 1.66667 10C1.66667 5.39763 5.39763 1.66667 10 1.66667C10 1.66667 9.99991 6.15521 9.99991 10.7576C9.99991 15.36 10 18.3333 10 18.3333Z"
            fill="#020219"
        />
    </svg>
)
