import { FormEvent, useEffect, useState } from 'react'
import { PulseLoader } from 'react-spinners'

import { Button, Checkbox, FormGroup, InputGroup, InputGroupProps2, TextArea } from '@blueprintjs/core'
import styled from 'styled-components'
import { AxiosError } from 'axios'
import { theme } from 'styled-tools'
import { OptionType, Select } from '@verbit-ai/verbit-ui-library'

import { aiServiceClient } from 'src/network'
import { useDebounce } from 'src/hooks/useDebounce'
import { InternalTestingTemplate } from 'src/components/InternalTestingTemplate/InternalTestingTemplate'
import {
    PromptInternalTestParams,
    PromptTemplateDetails,
    PromptTemplateDetailsField,
    PROMPT_TEMPLATE_DETAILS_FIELDS,
} from 'src/models/Prompt'

import { InternalPromptTestPreview } from './InternalPromptTestPreview'
import { InternalPromptTestPrompt } from './InternalPromptTestPrompt'

const Columns = styled.div`
    display: flex;
    gap: 30px;
    width: 100%;
    justify-content: center;
    overflow-y: auto;
    padding-top: ${theme('sizes.headerHeight')}px;
`

const formButtonClassname = 'form-button'
const Form = styled.form`
    width: 100%;
    max-width: 600px;

    .${formButtonClassname} {
        width: 100%;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .bp5-form-group textarea {
        width: 100%;
    }
`

const FormGroupContainer = styled.div`
    display: flex;
    column-gap: 16px;
    flex-wrap: wrap;
    .bp5-form-group {
        width: calc(50% - 8px);
    }
`

const CheckboxGroup = styled.div`
    display: flex;
    gap: 16px;
    .bp5-checkbox {
        width: 100%;
    }
`

const Error = styled.div`
    color: #f31515;
`

const TRANSCRIPT_FETCH_TYPES = ['byCustomerId', 'byJobId'] as const
type TranscriptFetchType = (typeof TRANSCRIPT_FETCH_TYPES)[number]
const isTranscriptFetchType = (value: string): value is TranscriptFetchType => TRANSCRIPT_FETCH_TYPES.includes(value as TranscriptFetchType)
const TRANSCRIPT_FETCH_TYPE_PRESENTATION: Record<TranscriptFetchType, string> = {
    byCustomerId: 'Customer ID + Number of transcripts',
    byJobId: 'Job ID',
}

interface InternalPromptTestForm extends PromptTemplateDetails {
    prompt: string
    transcriptFetchType: TranscriptFetchType
    segment: boolean
    // if fetching by customer ID
    customerId: string
    numberOfTranscripts: string
    // if fetching by job ID
    jobId: string
}
const MANDATORY_FIELDS_BY_FETCH_TYPE: Record<TranscriptFetchType, (keyof InternalPromptTestForm)[]> = {
    byCustomerId: ['customerId', 'numberOfTranscripts'],
    byJobId: ['jobId'],
}
const promptTemplatingFormFields: (keyof InternalPromptTestForm)[] = [
    'prompt',
    'transcriptFetchType',
    'segment',
    'customerId',
    'numberOfTranscripts',
    'jobId',
    ...PROMPT_TEMPLATE_DETAILS_FIELDS,
]

interface PromptTemplatingFormFieldDescriptor {
    title: string
    inputType: InputGroupProps2['type']
}
const promptTemplatingFormFieldDescriptors: Record<PromptTemplateDetailsField, PromptTemplatingFormFieldDescriptor> = {
    guidelines: {
        title: 'Guidelines',
        inputType: 'text',
    },
    format: {
        title: 'Format',
        inputType: 'text',
    },
    examples: {
        title: 'Examples',
        inputType: 'text',
    },
    main_task_definition: {
        title: 'Main task definition',
        inputType: 'text',
    },
    vertical_explanation: {
        title: 'Vertical explanation',
        inputType: 'text',
    },
    max_tokens_for_answer: {
        title: 'Max tokens for answer',
        inputType: 'number',
    },
}

const DEFAULT_TEMPLATE =
    'system : You are a proficient AI with a speciality in {variable: main_task_definition} {variable: guidelines}. This audio\'s source is from {variable: vertical_explanation}. Inaudible portions are represented as "\\u001f" or "x001F". {variable: format}. Response should not exceed {variable: max_tokens_for_answer} tokens. Stay true to the text content without adding external information.'
const IS_TEMPLATE_LOCAL_STORAGE_KEY = 'verbitext_ui_is_template'
const FORM_LOCAL_STORAGE_KEY = 'verbitext_ui_internal_prompt_test_v2'

const replaceTemplateFields = (formValue: InternalPromptTestForm) => {
    let promptString = formValue.prompt

    PROMPT_TEMPLATE_DETAILS_FIELDS.forEach((field) => {
        promptString = promptString.replace(new RegExp(`{variable: ${field}}`), formValue[field])
    })

    return promptString
}

export function InternalPromptTest() {
    const [isUsingTemplate, setIsUsingTemplate] = useState(() => {
        try {
            const preservedValue = JSON.parse(localStorage.getItem(IS_TEMPLATE_LOCAL_STORAGE_KEY) || '')
            if (typeof preservedValue === 'boolean') {
                return preservedValue
            }
        } catch (e) {}

        return false
    })
    const transcriptFetchTypeOptions: OptionType[] = TRANSCRIPT_FETCH_TYPES.map((fetchType) => ({
        value: fetchType,
        label: TRANSCRIPT_FETCH_TYPE_PRESENTATION[fetchType],
    }))
    const [formValue, setFormValue] = useState<InternalPromptTestForm>(() => {
        const value: InternalPromptTestForm = {
            prompt: isUsingTemplate ? DEFAULT_TEMPLATE : '',
            segment: false,
            transcriptFetchType: 'byCustomerId',
            customerId: '',
            numberOfTranscripts: '',
            jobId: '',
            examples: '',
            format: '',
            guidelines: '',
            main_task_definition: '',
            max_tokens_for_answer: '',
            vertical_explanation: '',
        }

        try {
            const preservedFormValue = JSON.parse(localStorage.getItem(FORM_LOCAL_STORAGE_KEY) || '') as InternalPromptTestForm

            promptTemplatingFormFields.forEach((field) => {
                if (field === 'transcriptFetchType') {
                    if (isTranscriptFetchType(preservedFormValue[field])) {
                        value[field] = preservedFormValue[field]
                    }
                } else if (field === 'segment') {
                    if (typeof preservedFormValue[field] === 'boolean') {
                        value[field] = preservedFormValue[field]
                    }
                } else if (preservedFormValue[field]) {
                    value[field] = preservedFormValue[field]
                }
            })
        } catch (e) {}

        return value
    })
    const [isFormValid, setIsFormValid] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [csvData, setCsvData] = useState('')

    useEffect(() => {
        const areAllFieldsRequiredByFetchTypePresent = MANDATORY_FIELDS_BY_FETCH_TYPE[formValue.transcriptFetchType].reduce(
            (acc, curr) => acc && !!formValue[curr],
            true,
        )
        setIsFormValid(!!formValue.prompt && areAllFieldsRequiredByFetchTypePresent)
        setError('')
    }, [formValue])

    useEffect(() => {
        try {
            localStorage.setItem(IS_TEMPLATE_LOCAL_STORAGE_KEY, JSON.stringify(isUsingTemplate))
        } catch (e) {}
    }, [isUsingTemplate])

    const debouncedFormValue = useDebounce(formValue)
    useEffect(() => {
        try {
            localStorage.setItem(FORM_LOCAL_STORAGE_KEY, JSON.stringify(debouncedFormValue))
        } catch (e) {}
    }, [debouncedFormValue])

    const tryFetchingCsv = async (requestUuid: string) => {
        try {
            const data = await aiServiceClient.testPromptFetchCsv(requestUuid)
            setCsvData(data)
        } catch (exception) {
            if ((exception as AxiosError)?.response?.status === 500) {
                throw exception
            }
            await new Promise((resolve) => setTimeout(resolve, 1000))
            await tryFetchingCsv(requestUuid)
        }
    }

    const onFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isFormValid || isLoading) {
            return
        }

        setIsLoading(true)

        const params: PromptInternalTestParams = {
            prompt: isUsingTemplate ? replaceTemplateFields(formValue) : formValue.prompt,
            prompt_details: {},
            segment: formValue.segment,
        }
        if (formValue.transcriptFetchType === 'byCustomerId') {
            params.customer_id = Number(formValue.customerId)
            params.number_of_transcripts = Number(formValue.numberOfTranscripts)
        } else {
            params.job_id = formValue.jobId
        }
        try {
            const { request_id } = await aiServiceClient.testPromptInit(params)
            await tryFetchingCsv(request_id)
        } catch (exception) {
            const errorMessage =
                (exception as AxiosError<{ message: string }>)?.response?.data?.message ||
                (exception as AxiosError)?.message ||
                'Something went wrong'
            setError(errorMessage)
        }

        setIsLoading(false)
    }

    const transcriptFetchTypeTemplates: Record<TranscriptFetchType, JSX.Element> = {
        byCustomerId: (
            <FormGroupContainer>
                <FormGroup label="Customer ID">
                    <InputGroup
                        placeholder="Required"
                        type="number"
                        value={formValue.customerId}
                        onChange={(e) =>
                            setFormValue((oldValue) => ({
                                ...oldValue,
                                customerId: e.target.value,
                            }))
                        }
                    />
                </FormGroup>
                <FormGroup label="Number of transcripts">
                    <InputGroup
                        placeholder="Required"
                        type="number"
                        value={formValue.numberOfTranscripts}
                        onChange={(e) =>
                            setFormValue((oldValue) => ({
                                ...oldValue,
                                numberOfTranscripts: e.target.value,
                            }))
                        }
                    />
                </FormGroup>
            </FormGroupContainer>
        ),
        byJobId: (
            <FormGroup label="Job ID">
                <InputGroup
                    placeholder="Required"
                    value={formValue.jobId}
                    onChange={(e) =>
                        setFormValue((oldValue) => ({
                            ...oldValue,
                            jobId: e.target.value,
                        }))
                    }
                />
            </FormGroup>
        ),
    }

    return (
        <InternalTestingTemplate header="Prompts testing">
            <Columns>
                <Form onSubmit={onFormSubmit}>
                    <CheckboxGroup>
                        <Checkbox
                            checked={isUsingTemplate}
                            label="Should use templating"
                            onChange={(e) => setIsUsingTemplate((e.target as HTMLInputElement).checked)}
                        />
                        <Checkbox
                            checked={formValue.segment}
                            label="Should use segmentation"
                            onChange={(e) =>
                                setFormValue((oldValue) => ({
                                    ...oldValue,
                                    segment: (e.target as HTMLInputElement).checked,
                                }))
                            }
                        />
                    </CheckboxGroup>
                    <FormGroup label={isUsingTemplate ? 'Template' : 'Prompt'}>
                        <TextArea
                            placeholder="Required"
                            value={formValue.prompt}
                            onChange={(e) =>
                                setFormValue((oldValue) => ({
                                    ...oldValue,
                                    prompt: e.target.value,
                                }))
                            }
                        />
                    </FormGroup>
                    {isUsingTemplate && (
                        <FormGroupContainer>
                            {PROMPT_TEMPLATE_DETAILS_FIELDS.map((field) => {
                                const { title, inputType } = promptTemplatingFormFieldDescriptors[field]

                                return (
                                    <FormGroup label={title} key={field}>
                                        <InputGroup
                                            placeholder="Optional"
                                            type={inputType}
                                            value={formValue[field]}
                                            onChange={(e) =>
                                                setFormValue((oldValue) => ({
                                                    ...oldValue,
                                                    [field]: e.target.value,
                                                }))
                                            }
                                        />
                                    </FormGroup>
                                )
                            })}
                            <InternalPromptTestPrompt template={formValue.prompt} details={formValue} />
                        </FormGroupContainer>
                    )}
                    <FormGroup>
                        <Select
                            options={transcriptFetchTypeOptions}
                            defaultValue={transcriptFetchTypeOptions.find(({ value }) => value === formValue.transcriptFetchType)}
                            getOptionLabel={({ label }) => `Fetch transcript by: ${label}`}
                            onChange={(option) =>
                                setFormValue((oldValue) => ({
                                    ...oldValue,
                                    transcriptFetchType: (option as OptionType).value as TranscriptFetchType,
                                }))
                            }
                        />
                    </FormGroup>
                    {transcriptFetchTypeTemplates[formValue.transcriptFetchType]}
                    <Button type="submit" intent="primary" className={formButtonClassname} disabled={!isFormValid || isLoading}>
                        {isLoading ? <PulseLoader size={10} color="#fff" /> : 'Submit'}
                    </Button>
                    {error && <Error>{error}</Error>}
                </Form>
                {csvData && <InternalPromptTestPreview csvData={csvData} />}
            </Columns>
        </InternalTestingTemplate>
    )
}
