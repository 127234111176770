import { Transcript, TranscriptParagraph } from 'src/models/Transcript'
import { isPunctuation, TranscriptWord } from 'src/models/TranscriptWord'

interface SearchResultMatch {
    startOffset: number
    endOffset: number
}
export interface SearchResult {
    paragraph: TranscriptParagraph
    match: SearchResultMatch
}

// TODO: rename?
export function fuzzySearch(transcript: Transcript, searchTerm: string): SearchResult[] {
    const results: SearchResult[] = []

    const searchTerms = searchTerm
        .trim()
        .split(/\s/)
        .map((s) => s.toLowerCase())
    const firstSearchTerm = searchTerms[0]

    for (let i = 0; i < Transcript.getTotalParagraphCount(transcript); i++) {
        const p = Transcript.getParagraphByIndex(transcript, i)
        const words = Transcript.getParagraphWords(transcript, p.id)

        for (let j = 0; j < words.length; j++) {
            const w = words[j]
            if (isPunctuation(w.text)) continue

            const word = TranscriptWord.withoutPunctuation(w).toLowerCase()
            if (word === firstSearchTerm) {
                let endOffset

                if (searchTerms.length > 1) {
                    endOffset = matchTerms(words, searchTerms, j + 1, 1)
                } else endOffset = j

                if (endOffset !== null) {
                    results.push({
                        paragraph: p,
                        match: {
                            startOffset: j,
                            endOffset,
                        },
                    })

                    // skip matched words
                    j = endOffset
                }
            }
        }
    }

    return results
}

function matchTerms(words: TranscriptWord[], searchTerms: string[], wordOffset: number, searchOffset: number): number | null {
    if (wordOffset === words.length) {
        return null
    }

    const w = words[wordOffset]
    const s = searchTerms[searchOffset]

    if (isPunctuation(w.text)) {
        return matchTerms(words, searchTerms, wordOffset + 1, searchOffset)
    }

    const word = TranscriptWord.withoutPunctuation(w).toLowerCase()
    if (word === s) {
        if (searchOffset === searchTerms.length - 1) {
            return wordOffset
        }

        return matchTerms(words, searchTerms, wordOffset + 1, searchOffset + 1)
    }

    return null
}
