import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { theme } from 'styled-tools'

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${theme('palette.routingError.backgroundColor')};
    color: ${theme('palette.routingError.textColor')};
`

const Title = styled.h1`
    font-size: 32px;
    line-height: 1.5;
`

const Description = styled.span`
    font-size: 16px;
    line-height: 1.5;
`

const StyledLink = styled(Link)`
    font-size: 16px;
    color: ${theme('palette.routingError.linkColor')};
    line-height: 1.5;
    text-decoration: underline;
`

export function RoutingError() {
    return (
        <Container>
            <Title>404</Title>
            <Description>This route doesn&apos;t exist</Description>
            <StyledLink to="/">Return to home page</StyledLink>
        </Container>
    )
}
