import { useCallback, useEffect, useRef } from 'react'
import { VariableSizeList } from 'react-window'

import styled, { css, useTheme } from 'styled-components'
import { ifNotProp, ifProp, prop, theme } from 'styled-tools'

import { usePrevious } from 'src/hooks/usePrevious'
import { useResponsive } from 'src/hooks/useResponsive'
import { useShallowMemo } from 'src/hooks/useShallowMemo'
import { useTranscript } from 'src/contexts/TranscriptContext'
import { useTranscriptViewer } from 'src/contexts/TranscriptViewerProvider'
import { ASRTranscriptParagraph, Transcript, TranscriptParagraph } from 'src/models/Transcript'
import { useConfig } from 'src/contexts/ConfigurationContext'
import { sumByExtended } from 'src/utils/math'
import { isChromakeyModeActive } from 'src/utils/env'
import { AI_PANEL_CLOSED_WIDTH, AUTO_SCROLL_THRESHOLD } from 'src/components/constants'
import { Language } from 'src/models/Language'
import { FONT_SIZE_BASE } from 'src/models/UIConfiguration'

import { ScrollButton } from './components/ScrollButton'
import { getParagraphHeight, ParagraphView } from './components/ParagraphView'

const Container = styled.div<{ $fontSize: number; $isChromakeyMode: boolean; $isAiPanelVisible: boolean }>`
    position: relative;
    display: flex;
    width: ${ifProp('$isAiPanelVisible', `calc(100% - ${AI_PANEL_CLOSED_WIDTH}px)`, '100%')};
    height: calc(100% - ${theme('sizes.trancriptToolbarHeight')}px);
    background-color: ${theme('palette.transcript.backgroundColor')};
    font-size: ${prop('$fontSize')}px;

    &:focus-within:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        pointer-events: none;
        border-radius: 3px;
        box-shadow: inset 0 0 0px 2px ${theme('palette.global.focusIndicatorColor')};
    }

    ${ifNotProp(
        '$isChromakeyMode',
        css`
            @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
                height: 100%;
            }
        `,
    )}

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        width: 100%;
    }
`

const canvas = document.createElement('canvas')

interface TranscriptViewerProps {
    width: number
    containerWidth: number
    containerHeight: number
    isAiPanelVisible: boolean
    isAIPanelOpened: boolean
    language: Language
    onInitialFontAvailable: () => void
}

// TODO: write tests for hooks logic
export const TranscriptViewer = ({
    width,
    containerWidth,
    containerHeight,
    isAiPanelVisible,
    isAIPanelOpened,
    language,
    onInitialFontAvailable,
}: TranscriptViewerProps) => {
    const transcript = useTranscript()
    const totalParagraphCount = Transcript.getTotalParagraphCount(transcript)
    const { initManualScrollingSideEffects, listRef, listScrollingContainerRef, autoScrollMode, hasNewContent, enterAutoScroll } =
        useTranscriptViewer()
    const fakeParagraphHeightRef = useRef(0)
    const asrParagraphCount = transcript.asrParagraphs.length

    const { isMobilePhone, isTablet } = useResponsive()
    const [{ fontSizePercentage, isFullWidthMode }] = useConfig()
    const fontSize = Math.round((FONT_SIZE_BASE * fontSizePercentage) / 100)
    const { fontFamily } = useTheme()

    const isChromakeyMode = isChromakeyModeActive()

    const cachedParagraphHeights = useRef<{ [key: string]: number }>({})
    const getCachedParagraphHeight = useCallback(
        (paragraph: TranscriptParagraph | ASRTranscriptParagraph, index: number) => {
            const paragraphKey = `${paragraph.id}-${paragraph.updatedAt}`

            if (!cachedParagraphHeights.current[paragraphKey]) {
                cachedParagraphHeights.current[paragraphKey] = getParagraphHeight({
                    canvas,
                    paragraph,
                    paragraphIndex: index,
                    fontSizePercentage,
                    fontFamily,
                    width,
                    isTablet,
                    isMobilePhone,
                    isAiPanelVisible,
                    language,
                    isChromakeyMode,
                    isFullWidthMode,
                    isDiarizationSupported: transcript.isDiarizationSupported,
                })
            }

            return cachedParagraphHeights.current[paragraphKey]
        },
        [
            fontFamily,
            fontSizePercentage,
            isAiPanelVisible,
            isChromakeyMode,
            isFullWidthMode,
            isMobilePhone,
            isTablet,
            language,
            transcript.isDiarizationSupported,
            width,
        ],
    )

    const keyRef = useRef(0)
    const initialScrollOffsetRef = useRef(0)
    const prevTranscript = usePrevious(transcript)
    const prevFontSize = usePrevious(fontSize) // Moved to top level
    /** "fix" scrollTop changes upon revision updates in order to prevent the Revisions-ASR border from "moving" **/
    if (
        transcript.lastRevisionUpdateDiff &&
        transcript.lastRevisionUpdateDiff !== prevTranscript?.lastRevisionUpdateDiff &&
        autoScrollMode
    ) {
        const addedHeight = sumByExtended(getCachedParagraphHeight, transcript.lastRevisionUpdateDiff.added)
        const removedHeight = sumByExtended(getCachedParagraphHeight, transcript.lastRevisionUpdateDiff.removed)
        const heightDelta = addedHeight - removedHeight

        /** force remount of the list and set the fixed initialScrollOffset **/
        initialScrollOffsetRef.current = (listScrollingContainerRef.current?.scrollTop ?? 0) + heightDelta
        keyRef.current++
    } else {
        /** in order to prevent the auto-scrolling from being bouncy, the scrolling container scrollHeight must never decrease
         *  the scrollHeight decreases upon paragraphs merging which leads to a lower item count in the virtual list,
         *  or upon paragraph content updates which lead to a decrease of the paragraph's height.
         *
         *  in order to prevent this, fake paragraphs are being rendered to cover up the reduced scrollHeight between content updates
         *  **/

        /** calculate the height of the fake paragraph on lastAsrUpdateDiff change **/
        if (transcript.lastAsrUpdateDiff && transcript.lastAsrUpdateDiff !== prevTranscript?.lastAsrUpdateDiff && autoScrollMode) {
            const addedHeight = sumByExtended(getCachedParagraphHeight, transcript.lastAsrUpdateDiff.added)
            const removedHeight =
                sumByExtended(getCachedParagraphHeight, transcript.lastAsrUpdateDiff.removed) + fakeParagraphHeightRef.current
            const heightDelta = addedHeight - removedHeight
            const nextFakeParagraphHeight = heightDelta < 0 ? Math.abs(heightDelta) : 0

            /** force remount of the list and set the fixed initialScrollOffset on fake paragraph height change **/
            if (fakeParagraphHeightRef.current !== nextFakeParagraphHeight) {
                fakeParagraphHeightRef.current = nextFakeParagraphHeight
                initialScrollOffsetRef.current = listScrollingContainerRef.current?.scrollTop ?? 0
                keyRef.current++
            }
        }

        /** reset when all ASR has been finalized by human layers **/
        if (!asrParagraphCount) {
            fakeParagraphHeightRef.current = 0
        }
    }

    /** make list focusable via keyboard and focus it if necessary **/
    useEffect(() => {
        listScrollingContainerRef.current?.setAttribute('tabindex', '0')

        // if there is no other specific element focused at the moment
        if (document.activeElement === document.body) {
            listScrollingContainerRef.current?.focus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyRef.current])

    useEffect(() => {
        const container = listScrollingContainerRef.current
        if (!container) return

        const isUserAtBottom = Math.abs(container.scrollHeight - container.scrollTop - container.clientHeight) < AUTO_SCROLL_THRESHOLD

        if (prevFontSize !== undefined) {
            cachedParagraphHeights.current = {}
            listRef.current?.resetAfterIndex(0)

            if (fontSize !== prevFontSize) {
                if (isUserAtBottom) {
                    enterAutoScroll({ shouldFocusContainer: false })
                }

                if (fontSize > prevFontSize) {
                    if (isUserAtBottom) {
                        listRef.current?.scrollToItem(totalParagraphCount - 1)
                        enterAutoScroll({ shouldFocusContainer: false })
                    }
                } else if (fontSize < prevFontSize) {
                    if (isUserAtBottom) {
                        const scrollPosition = container.scrollHeight - container.clientHeight
                        container.scrollTo(0, scrollPosition)
                        enterAutoScroll({ shouldFocusContainer: false })
                    }
                }
            }
        }
    }, [fontSize, totalParagraphCount, enterAutoScroll, prevFontSize, listScrollingContainerRef, listRef])

    useEffect(
        () => {
            const dispose = initManualScrollingSideEffects()

            return () => {
                dispose()
            }
        },
        /** initManualScrollingSideEffects uses list dom element ref, so it needs to rerun on remount on key change **/
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [initManualScrollingSideEffects, keyRef.current],
    )

    const getParagraphSize = useCallback(
        (index: number) => {
            /** if its a fake paragraph **/
            if (index === totalParagraphCount) {
                return fakeParagraphHeightRef.current
            }

            const paragraph = Transcript.getParagraphByIndex(transcript, index)

            return getCachedParagraphHeight(paragraph, index)
        },
        [totalParagraphCount, transcript, getCachedParagraphHeight],
    )

    const isInitialListRefChange = useRef(true)
    useEffect(() => {
        document.fonts.ready
            .then((fontFaceSet) => {
                // even though `document.fonts.ready` promise is resolved, additional fonts for transcript customization
                // might not be loaded yet, cause they aren't used in styles initially;
                // so, here we're looking for a specific font face:
                //  - family equal to active font family;
                //  - regular weight;
                //  - Unicode range is https://en.wikipedia.org/wiki/Plane_(Unicode)#Basic_Multilingual_Plane
                // this is important, cause additional weights and ranges are not used in transcript,
                // and therefore will not be requested by browser, so their status will never change
                const targetFaces = [...fontFaceSet].filter(
                    ({ family, weight, unicodeRange }) => family === fontFamily && weight === '400' && unicodeRange === 'U+0-10FFFF',
                )

                return Promise.all(targetFaces.map((fontFace) => fontFace.load()))
            })
            .then(() => {
                // we reset calculated paragraph heights on font load, and,
                // if this is the first time `listRef` changed, we also scroll to the bottom of the list
                // (no need to scroll down when font family is changed)
                cachedParagraphHeights.current = {}
                listRef.current?.resetAfterIndex(0)
                if (listRef.current && isInitialListRefChange.current) {
                    listRef.current?.scrollToItem(totalParagraphCount - 1)
                    isInitialListRefChange.current = false
                }
                onInitialFontAvailable()
            })
        // the effect should happen:
        //  - when `listRef` is changed (from null to non-null value; note that its _not_ the current value, but the ref itself);
        //  - when font family is changed
        // effect should _not_ happen when `totalParagraphCount` is changed (we only need the last known value of it
        // at the moment when list ref was initiated)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fontFamily, listRef])

    /**
     * reset calculated paragraph heights on:
     *  - transcript updates;
     *  - container width changes;
     *  - font size changes;
     *  - full width toggle
     */
    useEffect(() => {
        cachedParagraphHeights.current = {}
        listRef.current?.resetAfterIndex(0)
        // listRef.current?.scrollToItem(totalParagraphCount - 1)
    }, [listRef, containerWidth, fontSize, isFullWidthMode, transcript])

    return (
        <Container $fontSize={fontSize} $isChromakeyMode={isChromakeyMode} $isAiPanelVisible={isAiPanelVisible}>
            <VariableSizeList
                key={keyRef.current}
                style={{ overflowY: 'scroll' }}
                ref={listRef}
                outerRef={listScrollingContainerRef}
                width={width}
                height={containerHeight}
                overscanCount={5}
                itemCount={totalParagraphCount + (fakeParagraphHeightRef.current ? 1 : 0)}
                itemSize={getParagraphSize}
                children={ParagraphView}
                itemData={useShallowMemo({ containerWidth, fontSize })}
                initialScrollOffset={initialScrollOffsetRef.current}
            />

            <ScrollButton
                onScrollDown={() => enterAutoScroll({ shouldFocusContainer: true })}
                isVisible={!autoScrollMode}
                isShifted={isAIPanelOpened}
                hasIndicator={hasNewContent}
            />
        </Container>
    )
}
