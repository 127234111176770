import { useMemo } from 'react'

import styled from 'styled-components'
import { Button } from '@blueprintjs/core'
import { LinkIcon } from '@verbit-ai/icons-library'
// import { parse } from 'csv-parse/lib/sync'

import { downloadTextFile, getUniqueFilename } from 'src/utils/download'

const Container = styled.div`
    width: 100%;
    max-width: 600px;
`

const StyledButton = styled(Button)`
    width: 100%;
    margin-top: 15px;
`

const TableWrapper = styled.div`
    height: calc(100% - 55px);
    overflow: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
`

const Table = styled.table`
    width: 100%;
`

const TableBody = styled.tbody`
    width: 100%;
`

const TableRow = styled.tr`
    &:last-child {
        td {
            border: 0;
        }
    }
`

const TableData = styled.td`
    border-bottom: 1px solid #ccc;
    padding: 2px 10px;
`

const Link = styled.a`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    color: #106ba3;
    text-decoration: underline;
`

interface PromptResultPreview {
    transcript_id: string
    prompt_content: string
    result: string
}

interface InternalPromptTestPreviewProps {
    csvData: string
}

export function InternalPromptTestPreview({ csvData }: InternalPromptTestPreviewProps) {
    const tableData = useMemo(() => {
        try {
            console.log(csvData)
            const records = [] as PromptResultPreview[]
            // parse(csvData, {
            //     columns: true,
            //     skip_empty_lines: true,
            // })

            return records
        } catch (e) {
            return []
        }
    }, [csvData])

    const downloadResults = () => {
        downloadTextFile({
            text: csvData,
            filename: getUniqueFilename('prompt-test'),
            mimetype: 'text/csv',
            ext: 'csv',
        })
    }

    return (
        <Container>
            <TableWrapper>
                <Table>
                    <TableBody>
                        {tableData.map((cell, i) => (
                            <TableRow key={cell.transcript_id}>
                                <TableData>
                                    <Link
                                        href={`${location.origin}/?transcriptJobId=${cell.transcript_id}`}
                                        target="_blank"
                                        rel="noreferrer">
                                        <LinkIcon />
                                        Job #{i + 1}
                                    </Link>
                                </TableData>
                                <TableData>{cell.result}</TableData>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableWrapper>
            <StyledButton intent="success" onClick={downloadResults}>
                Download as CSV (with full prompts)
            </StyledButton>
        </Container>
    )
}
