import { createContext, ReactNode, RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { VariableSizeList } from 'react-window'

import { useShallowMemo } from 'src/hooks/useShallowMemo'
import { useVariableRef } from 'src/hooks/useVariableRef'
import { useTranscript } from 'src/contexts/TranscriptContext'
import { Transcript } from 'src/models/Transcript'
import { scrollToBottomEdge, scrollBy } from 'src/utils/scroll'
import { isMobileOperatingSystem } from 'src/utils/plattform'

import { useConfig } from './ConfigurationContext'

export const TranscriptViewerContext = createContext<{
    initManualScrollingSideEffects: () => () => void
    listRef: RefObject<VariableSizeList>
    listScrollingContainerRef: RefObject<HTMLDivElement>
    autoScrollMode: boolean
    hasNewContent: boolean
    enterAutoScroll: (dto: EnterAutoScrollDto) => void
    requestNonBlockingAutoscrollFrame: (run: () => void) => void
} | null>(null)

const TranscriptViewerAPIContext = createContext<{
    scrollToParagraphId: (pId: string) => unknown
} | null>(null)

const UP = 38
const DOWN = 40

const AUTO_SCROLL_TRIGGER_THRESHOLD = 10
const AUTO_SCROLL_EXIT_THRESHOLD = 100
const AUTO_SCROLL_SPEED_PIXEL_PER_SECOND = 50
const ARROW_KEY_SCROLL_DISTANCE_PX = 400
const ARROW_KEY_SCROLL_SPEED_PX_PER_SEC = ARROW_KEY_SCROLL_DISTANCE_PX

const VISIBILITY_CHANGE_EVENT: keyof DocumentEventMap = 'visibilitychange'

interface TranscriptViewerProviderProps {
    isInitialFontAvailable: boolean
    children: ReactNode
}

interface EnterAutoScrollDto {
    shouldFocusContainer: boolean
}

export const TranscriptViewerProvider = ({ isInitialFontAvailable, children }: TranscriptViewerProviderProps) => {
    const transcript = useTranscript()

    const listRef = useRef<VariableSizeList>(null)
    const listScrollingContainerRef = useRef<HTMLDivElement>(null)

    const autoScrollPromiseRef = useRef<Promise<void>>(Promise.resolve())
    const autoScrollCancelRef = useRef(false)
    const [autoScrollMode, setAutoScrollMode] = useState(false)
    const autoScrollModeRef = useVariableRef(autoScrollMode)

    const totalParagraphCount = Transcript.getTotalParagraphCount(transcript)
    const paragraphCountRef = useVariableRef(totalParagraphCount)

    const [{ fontSizePercentage }] = useConfig()
    const [recentFontSizeChange, setRecentFontSizeChange] = useState(false)

    const [hasNewContent, setHasNewContent] = useState(false)

    const enterAutoScroll = useCallback(
        ({ shouldFocusContainer }: EnterAutoScrollDto) => {
            // when initial font is loaded, the list is scrolled to the last item immediately,
            // setting list's `scrollUpdateWasRequested` flag to true and cancelling the autoscroll;
            // sometimes though, due to async issues, when scroll happens between frames, autoscroll isn't cancelled,
            // and after bringing user to the bottom, transcript jumps back up and continues scrolling slowly
            // all the way through; to prevent this, we do not start autoscrolling before initial font is loaded
            if (!isInitialFontAvailable) {
                return
            }

            autoScrollCancelRef.current = false
            setAutoScrollMode(true)
            listRef.current?.scrollToItem(paragraphCountRef.current - 1)
            // when autoscroll is caused by font size change, scrolling container
            // should not get focus, cause then font control buttons would
            // _lose_ focus, which contradicts VPAT compliance requirements
            if (shouldFocusContainer) {
                listScrollingContainerRef.current?.focus()
            }
        },
        [isInitialFontAvailable, paragraphCountRef],
    )

    useEffect(() => {
        if (isInitialFontAvailable) {
            enterAutoScroll({ shouldFocusContainer: true })
        }
    }, [enterAutoScroll, isInitialFontAvailable])

    useEffect(() => {
        setRecentFontSizeChange(true)

        const listScrollingContainer = listScrollingContainerRef.current
        if (!listScrollingContainer) return
        const { scrollTop, scrollHeight, clientHeight } = listScrollingContainer
        const nearBottom = scrollTop >= scrollHeight - clientHeight - AUTO_SCROLL_TRIGGER_THRESHOLD

        const timer = setTimeout(() => {
            setRecentFontSizeChange(false)
            if (nearBottom) {
                enterAutoScroll({ shouldFocusContainer: false })
            }
        }, 200)

        return () => clearTimeout(timer)
    }, [enterAutoScroll, fontSizePercentage])

    const exitAutoScroll = useCallback(() => {
        autoScrollCancelRef.current = true
        setAutoScrollMode(false)
        autoScrollPromiseRef.current = Promise.resolve()
    }, [])

    // TODO: search not working
    // TODO: handle manual scroll bar thumb scroll
    // TODO: rename
    const initManualScrollingSideEffects = useCallback(() => {
        const listScrollingContainer = listScrollingContainerRef.current
        let lastScrollTop = listScrollingContainer?.scrollTop

        const handleScroll = (isScrollingUp: boolean) => {
            if (!listScrollingContainer) return

            const { scrollTop, scrollHeight, clientHeight } = listScrollingContainer
            const manualScrollShouldEnableAutoScrollMode = scrollTop >= scrollHeight - clientHeight - AUTO_SCROLL_TRIGGER_THRESHOLD

            if (isScrollingUp) {
                // only exit autoscroll when at least ${AUTO_SCROLL_EXIT_THRESHOLD}px away from the bottom of the page,
                // in order to avoid accidental exits when ASR chunk is big enough to add an extra line and trigger
                // scroll event (which will be treated as scrolling up)
                if (
                    scrollHeight > clientHeight &&
                    !recentFontSizeChange &&
                    scrollHeight - scrollTop - clientHeight > AUTO_SCROLL_EXIT_THRESHOLD
                ) {
                    exitAutoScroll()
                }
            } else if (manualScrollShouldEnableAutoScrollMode) {
                autoScrollCancelRef.current = false
                setAutoScrollMode(true)
            }
        }

        const onScroll = () => {
            if (lastScrollTop !== undefined && listScrollingContainer) {
                handleScroll(lastScrollTop - listScrollingContainer.scrollTop >= 1)
            }

            lastScrollTop = listScrollingContainer?.scrollTop
        }

        /** keyboard */
        const arrowKeyAutoScrollCancelRef = { current: false }

        const enterArrowKeyAutoScroll = async (isScrollingUp: boolean): Promise<void> => {
            if (autoScrollModeRef.current && !isScrollingUp) return

            const scrollTopDelta = ARROW_KEY_SCROLL_DISTANCE_PX * (isScrollingUp ? -1 : 1)

            return scrollBy(
                scrollTopDelta,
                ARROW_KEY_SCROLL_SPEED_PX_PER_SEC,
                listScrollingContainerRef.current!,
                arrowKeyAutoScrollCancelRef,
            )
                .then(() => handleScroll(isScrollingUp))
                .then(() => enterArrowKeyAutoScroll(isScrollingUp))
                .catch((...args) => {
                    arrowKeyAutoScrollCancelRef.current = false
                    console.warn(...args)
                })
        }

        const quitArrowKeyAutoScroll = () => {
            arrowKeyAutoScrollCancelRef.current = true
        }

        const onKeyDown = (e: KeyboardEvent) => {
            const { repeat, keyCode } = e

            switch (keyCode) {
                case UP:
                    e.preventDefault()

                    if (!repeat) {
                        arrowKeyAutoScrollCancelRef.current = false
                        enterArrowKeyAutoScroll(true)
                    }

                    break

                case DOWN:
                    e.preventDefault()

                    if (!repeat) {
                        arrowKeyAutoScrollCancelRef.current = false
                        enterArrowKeyAutoScroll(false)
                    }

                    break
            }
        }

        const onKeyUp = ({ keyCode }: KeyboardEvent) => {
            switch (keyCode) {
                case UP:
                case DOWN:
                    quitArrowKeyAutoScroll()
            }
        }

        /** mobile **/
        const onTouchStart = ({ touches: [touchStart] }: TouchEvent) => {
            let prevClientY = touchStart.clientY
            let isScrollingUp = false

            const onTouchMove = ({ touches: [touchMove] }: TouchEvent) => {
                const deltaY = touchMove.clientY - prevClientY
                prevClientY = touchMove.clientY
                isScrollingUp = deltaY > 0

                handleScroll(isScrollingUp)
            }

            listScrollingContainer?.addEventListener('touchmove', onTouchMove)
            listScrollingContainer?.addEventListener(
                'touchend',
                () => listScrollingContainer?.removeEventListener('touchmove', onTouchMove),
                { once: true },
            )
        }

        listScrollingContainer?.addEventListener('keydown', onKeyDown)
        listScrollingContainer?.addEventListener('keyup', onKeyUp)

        // important note: `onScroll` event listener should not be added on mobile;
        // reasoning: "scroll" event happens not only due to a user's action, but upon any change of `scrollTop` property;
        // this means that this listener is constantly being called while the autoscroll is in progress;
        // on mobile, if text revision is big enough, it may cause 2+ lines of text to be inserted instantly, which would shift
        // the scrollbar and make the app think that user scrolled up a bit, and thus stop the autoscroll -
        // which is undesirable behaviour, cause user wasn't actually doing anything;
        // on desktop this doesn't happen, as container is wider and usually there are no revisions this big;
        // since we only really care about scrolls caused by a user interaction, and since on mobile the only such interaction is touch,
        // "scroll" event listener is redundant on mobile anyway
        const isMobile = isMobileOperatingSystem()
        if (isMobile) {
            listScrollingContainer?.addEventListener('touchstart', onTouchStart)
        } else {
            listScrollingContainer?.addEventListener('scroll', onScroll)
        }

        return () => {
            listScrollingContainer?.removeEventListener('keydown', onKeyDown)
            listScrollingContainer?.removeEventListener('keyup', onKeyUp)
            if (isMobile) {
                listScrollingContainer?.removeEventListener('touchstart', onTouchStart)
            } else {
                listScrollingContainer?.removeEventListener('scroll', onScroll)
            }
        }
    }, [autoScrollModeRef, exitAutoScroll, recentFontSizeChange])

    /** auto scrolling functionality **/
    useEffect(() => {
        if (autoScrollMode) {
            autoScrollPromiseRef.current = autoScrollPromiseRef.current
                .then(() => {
                    return scrollToBottomEdge(AUTO_SCROLL_SPEED_PIXEL_PER_SECOND, listScrollingContainerRef.current)
                })
                .catch((error) => {
                    console.warn('Caught error in autoScrollMode useEffect:', error)
                })
            setHasNewContent(false)
        }
    }, [autoScrollMode, transcript])

    useEffect(() => {
        if (!autoScrollModeRef.current) {
            setHasNewContent(true)
        }
    }, [autoScrollModeRef, transcript])

    const scrollToParagraphId = useCallback(
        (pId: string) => {
            const pIndex = Transcript.getParagraphIndexById(transcript, pId)

            if (pIndex >= 0) {
                exitAutoScroll()
                listRef.current?.scrollToItem(Math.max(pIndex - 2, 0), 'start')
            }
        },
        [exitAutoScroll, transcript],
    )

    /** schedule an execution of a function after the current last autoScroll promise,
     in order to safely update the UI without affecting the auto-scrolling functionality **/
    const requestNonBlockingAutoscrollFrame = useCallback((run: () => void) => {
        autoScrollPromiseRef.current = autoScrollPromiseRef.current.then(run)
    }, [])

    // since we cannot guarantee proper handling of autoscroll while the page isn't focused,
    // we exit autoscroll when page is hidden, and enter it again once the focus is restored
    useEffect(() => {
        const visibilityChangeListener = () => {
            if (document.hidden) {
                exitAutoScroll()
            } else {
                enterAutoScroll({ shouldFocusContainer: true })
            }
        }

        document.addEventListener(VISIBILITY_CHANGE_EVENT, visibilityChangeListener)

        return () => {
            document.removeEventListener(VISIBILITY_CHANGE_EVENT, visibilityChangeListener)
        }
    }, [enterAutoScroll, exitAutoScroll])

    return (
        <TranscriptViewerContext.Provider
            value={useShallowMemo({
                initManualScrollingSideEffects,
                listRef,
                listScrollingContainerRef,
                autoScrollMode,
                hasNewContent,
                enterAutoScroll,
                requestNonBlockingAutoscrollFrame,
            })}>
            <TranscriptViewerAPIContext.Provider value={useShallowMemo({ scrollToParagraphId })}>
                {children}
            </TranscriptViewerAPIContext.Provider>
        </TranscriptViewerContext.Provider>
    )
}

export function useTranscriptViewer() {
    const value = useContext(TranscriptViewerContext)

    if (!value) {
        throw new Error('You have forgot to use <TranscriptViewerProvider />, shame on you.')
    }

    return value
}

export function useTranscriptViewerAPI() {
    const value = useContext(TranscriptViewerAPIContext)

    if (!value) {
        throw new Error('You have forgot to use <TranscriptViewerProvider />, shame on you.')
    }

    return value
}
