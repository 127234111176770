import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'

interface ClipboardContextData {
    canWriteToClipboard: boolean
    writeToClipboard: ((data: string | Blob) => Promise<void>) | undefined
}

const ClipboardContext = createContext<ClipboardContextData>({
    canWriteToClipboard: false,
    writeToClipboard: undefined,
})

interface ClipboardProviderProps {
    children: ReactNode
}

export function ClipboardProvider({ children }: ClipboardProviderProps) {
    const [canWriteToClipboard, setCanWriteToClipboard] = useState(false)

    useEffect(() => {
        if (navigator.permissions?.query) {
            navigator.permissions
                .query({ name: 'clipboard-write' as PermissionName })
                .then((result) => {
                    setCanWriteToClipboard(result.state === 'granted')
                    result.onchange = () => {
                        setCanWriteToClipboard(result.state === 'granted')
                    }
                })
                .catch((error) => {
                    if (error instanceof TypeError) {
                        // browser does not support 'clipboard-write' as PermissionName
                        // for querying; the actual clipboard manipulation might still
                        // be available
                        setCanWriteToClipboard(!!navigator.clipboard)
                    }
                })
        } else {
            setCanWriteToClipboard(!!navigator.clipboard)
        }
    }, [])

    const writeToClipboard: ClipboardContextData['writeToClipboard'] = (data) => {
        if (typeof data === 'string') {
            return navigator.clipboard.writeText(data)
        } else {
            return navigator.clipboard.write([
                new ClipboardItem({
                    [data.type]: data,
                }),
            ])
        }
    }

    const providedValue = useMemo<ClipboardContextData>(
        () => ({
            canWriteToClipboard,
            writeToClipboard,
        }),
        [canWriteToClipboard],
    )

    return <ClipboardContext.Provider value={providedValue}>{children}</ClipboardContext.Provider>
}

export function useClipboard() {
    return useContext(ClipboardContext)
}
