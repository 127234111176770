export const SEPARATION_PUNCTUATION = ['.', ',', '?', ';', ':', '!']

export const PUNCTUATION = [...SEPARATION_PUNCTUATION, '...', '/', '"', '(', ')', "'"]

export function isPunctuation(word: string) {
    if (!word) return null

    return PUNCTUATION.includes(word)
}

export interface TranscriptWord {
    text: string
    paragraphId: string
    time: number
    duration: number
}

export interface ASRTranscriptWord extends TranscriptWord {
    uttId: number
}

export interface ASRChunkWordJSON {
    text: string
    start: number
    end: number
    confidence: number
    speaker_id: string
    speaker_label: string
    paragraph_id: string
}

export const TranscriptWord = {
    shouldAddSpace(word: TranscriptWord, options: { first?: boolean } = {}) {
        return !options.first && !isPunctuation(word.text)
    },

    withoutPunctuation(word: TranscriptWord) {
        return word.text.replace(new RegExp(`[${PUNCTUATION.join('')}]`, 'g'), '')
    },

    concat(words: Array<TranscriptWord | ASRTranscriptWord>) {
        return words.reduce((text, w, i) => text + (TranscriptWord.shouldAddSpace(w, { first: i === 0 }) ? ' ' : '') + w.text, '')
    },

    fromAsrChunkWordJSON: (json: ASRChunkWordJSON, uttId: number): ASRTranscriptWord => ({
        uttId,
        paragraphId: json.paragraph_id,
        time: json.start,
        duration: json.end - json.start,
        text: json.text,
    }),
}
