import styled from 'styled-components'
import { theme } from 'styled-tools'

import { EmptyTranscriptionIcon } from 'src/components/icons'

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme('palette.transcript.backgroundColor')};
    color: ${theme('palette.transcript.textColors.0.colorValue')};
`

const Text = styled.div`
    text-align: center;
    line-height: 1.5em;
    margin-top: 32px;
`

export function EmptyTranscriptScreen() {
    return (
        <Wrapper>
            <EmptyTranscriptionIcon />
            <Text>
                <div>The transcript will be displayed</div>
                <div>here when the session starts.</div>
            </Text>
        </Wrapper>
    )
}
