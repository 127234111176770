import { Popover } from '@blueprintjs/core'

import { BP5_POPOVER_VERTICAL_SPACING_CLASSNAME } from 'src/global-styles'
import { useBlueprintThemeClass } from 'src/hooks/useBlueprintThemeClass'

import { LanguageSelectProps } from './types'
import { LanguagesList } from './LanguagesList'

export const LANGUAGE_SELECT_CLASSNAME = 'language-select'

export function LanguageSelect({ isOpen, onClose, children }: LanguageSelectProps) {
    const popoverClassName = useBlueprintThemeClass()

    return (
        <Popover
            content={<LanguagesList />}
            portalClassName={BP5_POPOVER_VERTICAL_SPACING_CLASSNAME}
            popoverClassName={popoverClassName}
            className={LANGUAGE_SELECT_CLASSNAME}
            interactionKind="click"
            isOpen={isOpen}
            onClose={onClose}
            placement="bottom-start"
            minimal>
            {children}
        </Popover>
    )
}
