import { FontSizeIcon } from '@verbit-ai/icons-library'
import styled from 'styled-components'
import { clamp } from 'lodash-es'

import { useConfig } from 'src/contexts/ConfigurationContext'
import { MinusIcon, PlusIcon } from 'src/components/icons'
import { FONT_SIZE_PERCENTAGE_OPTIONS } from 'src/models/UIConfiguration'

const Panel = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid #ffffff;
    border-radius: 4px;
`

const Button = styled.button`
    padding: 11px;
    outline: 0;
`

export function FontSizeControlChromakey() {
    const [, setProp] = useConfig()

    const cycleThroughFontSizes = (direction: 1 | -1) =>
        setProp('fontSizePercentage', (percentage) => {
            const percentageIndex = FONT_SIZE_PERCENTAGE_OPTIONS.indexOf(percentage)
            const nextPercentageIndex = clamp(percentageIndex + direction, 0, FONT_SIZE_PERCENTAGE_OPTIONS.length - 1)

            return FONT_SIZE_PERCENTAGE_OPTIONS[nextPercentageIndex]
        })

    return (
        <Panel>
            <Button tabIndex={-1} onClick={() => cycleThroughFontSizes(-1)}>
                <MinusIcon color="#fff" />
            </Button>
            <FontSizeIcon size={20} color="#fff" />
            <Button tabIndex={-1} onClick={() => cycleThroughFontSizes(1)}>
                <PlusIcon color="#fff" />
            </Button>
        </Panel>
    )
}
