import styled from 'styled-components'
import { theme } from 'styled-tools'

export const ThemeButton = styled.button`
    display: block;
    width: 100%;
    padding: 8px 40px 8px 16px;
    border: 0;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: ${theme('palette.themeToggle.textColor')};
    position: relative;

    svg {
        position: absolute;
        top: 11px;
        right: 16px;
        stroke-width: 2px;
    }

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        font-size: 16px;
        line-height: 24px;
        padding: 16px 40px 16px 0;

        svg {
            top: 20px;
        }
    }
`
