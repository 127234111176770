import { useEffect, useRef, useState } from 'react'

import styled, { css, useTheme } from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { CaretDownIcon, DownloadIcon, FontSizeIcon, SearchIcon } from '@verbit-ai/icons-library'

import { useDownload, useIsTranscriptEmpty, useTranscript, useTranscriptMetadata } from 'src/contexts/TranscriptContext'
import { FontSizeControl } from 'src/components/FontSizeControl/FontSizeControl'
import { Search } from 'src/components/Search/Search'
import { GradientButton } from 'src/components/GradientButton/GradientButton'
import { ThemeToggle } from 'src/components/ThemeToggle/ThemeToggle'
import { LanguageSelect } from 'src/components/LanguageSelect/LanguageSelect'
import { GlobeIcon, PaletteIcon } from 'src/components/icons'
import { isLanguageSelectionAvailable } from 'src/models/TranscriptMetadata'
import { getLanguagePresentation } from 'src/models/Language'
import { analyticsService } from 'src/services/AnalyticsService'
import { getTranscriptDimensions } from 'src/components/constants'
import { useConfig } from 'src/contexts/ConfigurationContext'

import { TranscriptToolbarBar } from './TranscriptToolbarBar'
import { TOGGLE_THEME_BUTTON_CLASSNAME, TranscriptToolbarLimiter } from './TranscriptToolbarLimiter'

const TranscriptTitle = styled.h2`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto 0 0;
    color: ${theme('palette.transcript.titleColor')};
`

const LanguageButtonContent = styled.span`
    display: inline-flex;
    align-items: center;
    padding: 0 4px;
    gap: 8px;
`
const LanguageName = styled.span`
    max-width: 82px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
    color: ${theme('palette.gradientButton.textColor')};
`

const StyledCaretDown = styled(CaretDownIcon)<{ $rotated?: boolean }>`
    ${ifProp(
        '$rotated',
        css`
            transform: rotate(180deg);
        `,
    )};
`

const TOOLBAR_LABEL_ID = 'toolbar_label'

interface TranscriptToolbarProps {
    isAIPanelOpened: boolean
    containerWidth: number
}

export function TranscriptToolbar({ isAIPanelOpened, containerWidth }: TranscriptToolbarProps) {
    const transcript = useTranscript()
    const transcriptMetadata = useTranscriptMetadata()
    const [isFontSizeControlOpen, setIsFontSizeControlOpen] = useState(false)
    const [isSearchOpen, setIsSearchOpen] = useState(false)
    const [isThemeToggleOpen, setIsThemeToggleOpen] = useState(false)
    const [isLanguageSelectOpen, setIsLanguageSelectOpen] = useState(false)
    const { canDownload, downloadTextFile } = useDownload()
    const isTranscriptEmpty = useIsTranscriptEmpty()
    const {
        palette: {
            gradientButton: { iconColor },
        },
    } = useTheme()
    const [{ fontSizePercentage, isFullWidthMode }] = useConfig()

    const { textMaxWidth, transcriptParagraphWidth } = getTranscriptDimensions({
        isDiarizationSupported: transcript.isDiarizationSupported,
        fontSizePercentage,
        isFullWidthMode,
        containerWidth,
    })

    useEffect(() => {
        if (isLanguageSelectOpen) {
            analyticsService.track('TranslationToggled', {})
        }
    }, [isLanguageSelectOpen])

    const searchButtonRef = useRef<HTMLButtonElement | null>(null)
    const onSearchToggle = (nextIsOpen: boolean) => {
        setIsSearchOpen(nextIsOpen)
        if (!nextIsOpen) {
            // hereinafter: try returning focus to the button
            // in case if popover was closed with ESC
            searchButtonRef.current?.focus()
        }
    }

    const fontSizeButtonRef = useRef<HTMLButtonElement | null>(null)
    const onFontSizeControlClose = () => {
        setIsFontSizeControlOpen(false)
        fontSizeButtonRef.current?.focus()
    }

    const themeButtonRef = useRef<HTMLButtonElement | null>(null)
    const onThemeToggleClose = () => {
        setIsThemeToggleOpen(false)
        themeButtonRef.current?.focus()
    }

    const languageButtonRef = useRef<HTMLButtonElement | null>(null)
    const onLanguageSelectClose = () => {
        setIsLanguageSelectOpen(false)
        languageButtonRef.current?.focus()
    }

    return (
        <TranscriptToolbarBar aria-labelledby={TOOLBAR_LABEL_ID}>
            <TranscriptToolbarLimiter
                $textMaxWidth={textMaxWidth}
                $paragraphTotalWidth={transcriptParagraphWidth}
                $isPanelOpen={isAIPanelOpened}>
                <TranscriptTitle id={TOOLBAR_LABEL_ID}>Transcript</TranscriptTitle>

                {!isTranscriptEmpty && (
                    <Search isOpen={isSearchOpen} onToggle={onSearchToggle}>
                        <GradientButton isFocusable ariaLabel="Search in transcript" ariaHasPopup innerRef={searchButtonRef}>
                            <SearchIcon color={iconColor} />
                        </GradientButton>
                    </Search>
                )}

                <FontSizeControl isOpen={isFontSizeControlOpen} onClose={onFontSizeControlClose}>
                    <GradientButton
                        isFocusable
                        ariaLabel="Font size"
                        ariaHasPopup
                        innerRef={fontSizeButtonRef}
                        onClick={() => setIsFontSizeControlOpen((isOpen) => !isOpen)}>
                        <FontSizeIcon color={iconColor} />
                    </GradientButton>
                </FontSizeControl>

                {canDownload && (
                    <GradientButton isFocusable ariaLabel="Download transcript" onClick={downloadTextFile}>
                        <DownloadIcon color={iconColor} />
                    </GradientButton>
                )}

                {isLanguageSelectionAvailable(transcriptMetadata) && (
                    <LanguageSelect isOpen={isLanguageSelectOpen} onClose={onLanguageSelectClose}>
                        <GradientButton
                            isFocusable
                            ariaLabel="Select language"
                            ariaHasPopup
                            innerRef={languageButtonRef}
                            onClick={() => setIsLanguageSelectOpen((isOpen) => !isOpen)}>
                            <LanguageButtonContent>
                                <GlobeIcon color={iconColor} />
                                <LanguageName>
                                    {getLanguagePresentation(transcriptMetadata!.currentLanguageCode, transcriptMetadata!.translations)}
                                </LanguageName>
                                <StyledCaretDown color={iconColor} $rotated={isLanguageSelectOpen} />
                            </LanguageButtonContent>
                        </GradientButton>
                    </LanguageSelect>
                )}

                <ThemeToggle isOpen={isThemeToggleOpen} onClose={onThemeToggleClose}>
                    <GradientButton
                        isFocusable
                        className={TOGGLE_THEME_BUTTON_CLASSNAME}
                        ariaLabel="Toggle theme"
                        ariaHasPopup
                        innerRef={themeButtonRef}
                        onClick={() => setIsThemeToggleOpen((isOpen) => !isOpen)}>
                        <PaletteIcon color={iconColor} />
                    </GradientButton>
                </ThemeToggle>
            </TranscriptToolbarLimiter>
        </TranscriptToolbarBar>
    )
}
