import * as Ably from 'ably'
import axios from 'axios'

import { getEnv, getRequestedLanguageCode } from 'src/utils/env'
import { decrypt } from 'src/utils/decryption'
import { rollbarService } from 'src/services/RollbarService'

import { StreamHistoryOptions, StreamMessage, StreamSourceBase, StreamSourceInterface } from './StreamClient'

interface AblyStreamSourceOptions {
    url: string
    sessionId: string
    options: Record<string, any>
}

export class AblyStreamSource extends StreamSourceBase implements StreamSourceInterface {
    private client: Ably.Realtime | null = null
    private readonly sessionId: string
    private options: Record<string, any>

    constructor({ url, sessionId, options }: AblyStreamSourceOptions) {
        super()

        this.sessionId = sessionId
        this.options = options
        this.client = new Ably.Realtime({
            authHeaders: { 'X-Requested-With': 'XMLHttpRequest' },
            closeOnUnload: false,
            authUrl: url,
            httpRequestTimeout: 30000,
            realtimeRequestTimeout: 30000,
            authCallback: async (data, callback) => {
                try {
                    const { data: tokenRequest } = await axios({ method: 'get', url, withCredentials: true })
                    callback('', tokenRequest)
                } catch (e: any) {
                    callback(e, '')
                }
            },
            ...options,
        })
    }

    close() {
        this.client?.connection.close()
    }

    private channelLanguage = getRequestedLanguageCode().toLowerCase().replace(/-/g, '_')
    private channel(target: string) {
        return ['trax', 'session', getEnv() === 'dev' ? 'staging' : getEnv(), this.sessionId, this.channelLanguage, target]
            .filter((p) => !!p)
            .join(':')
    }

    private prepareMessage(m: Ably.Types.Message) {
        try {
            let data = m.data
            if (m.data instanceof ArrayBuffer) {
                // Convert binary data to JSON when an Ably message is transmitted as binary, as our system consistently anticipates JSON data.
                data = JSON.parse(String.fromCharCode(...new Uint8Array(m.data)))
            }

            if ('encrypted_data' in data.data) {
                // Decrypted the encrypted data using the provided cipher key
                // Check if the cipher key exists and isn't empty, else log error and return null

                if (!this.options.cipher || !this.options.cipher.key) {
                    console.error(`[Ably-Integration] Cipher key not provided`, m)
                    rollbarService.error('[Ably-Integration] Cipher key not provided')

                    return null
                }

                data.data = JSON.parse(decrypt(data.data.encrypted_data, this.options.cipher.key))
            }

            return data
        } catch (e) {
            console.error(`[Ably-Integration] encountered error`, e, m)
            rollbarService.error('[Ably-Integration] encountered error preparing data')

            return null
        }
    }

    private messageListener = (m: Ably.Types.Message) => {
        const msg = this.prepareMessage(m)

        if (msg) {
            this.send(msg)
        }
    }

    private failedChannelsStates: Ably.Types.ChannelState[] = ['failed', 'suspended']
    private connectivityFailureListener = (evt: Ably.Types.ChannelStateChange) => {
        rollbarService.error(`[Ably-Integration] channel connection: ${evt.current}${evt.reason ? ', ' + evt.reason.message : ''}`)
    }

    subscribe(...targets: string[]) {
        targets.forEach((t) => {
            if (!this.client) {
                return
            }
            const channel = this.client.channels.get(this.channel(t))
            channel.subscribe(this.messageListener)

            this.failedChannelsStates.forEach((evt) => channel.on(evt, this.connectivityFailureListener))
        })
    }

    unsubscribe(...targets: string[]) {
        targets.forEach((t) => {
            if (!this.client) {
                return
            }
            const channel = this.client.channels.get(this.channel(t))
            channel.unsubscribe(this.messageListener)

            this.failedChannelsStates.forEach((evt) => channel.off(evt, this.connectivityFailureListener))
        })
    }

    async history<T extends StreamMessage['action']>(target: string, options: StreamHistoryOptions) {
        return new Promise<Extract<StreamMessage, { action: T }>[]>((resolve, reject) =>
            this.client?.channels.get(this.channel(target)).history(options, (error, result) => {
                error ? reject(error) : resolve(result?.items.map((item) => this.prepareMessage(item)).filter((m) => m !== null) ?? [])
            }),
        )
    }
}
