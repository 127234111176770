import { ReactElement, ReactNode } from 'react'

import { flatten } from 'lodash-es'

import { LanguageWithoutWhitespaces, LANGUAGES_WITHOUT_WHITESPACES, Language } from 'src/models/Language'
import { TranscriptWord } from 'src/models/TranscriptWord'

const unicodeRangesForLanguagesWithoutWhitespaces: Record<LanguageWithoutWhitespaces, [number, number][]> = {
    Chinese: [[0x4e00, 0x9fff]],
    Japanese: [
        [0x3040, 0x309f], // Hiragana
        [0x30a0, 0x30ff], // Katakana
        [0x3400, 0x4dbf], // Kanji
    ],
}
const noWhitespaceLangRegExp = new RegExp(
    `[${flatten(Object.values(unicodeRangesForLanguagesWithoutWhitespaces)).reduce(
        (accumulatedPattern, [rangeStart, rangeEnd]) => `${accumulatedPattern}\\u${rangeStart.toString(16)}-\\u${rangeEnd.toString(16)}`,
        '',
    )}]`,
)

const isLetterOrDigit = (char: string) => /\p{L}|\p{N}/u.test(char)
const isPunctuation = (char: string) => /^\p{P}$/u.test(char)

/**
 * While we use the words we receive from backend to display, the segmentation on BE
 * doesn't account for languages that are not using whitespaces to separate words.
 * For example, we may receive "我是新公司的首席执行官。" as a single word, but for the purpose
 * of estimating text dimensions, we cannot treat it as a single word, because browser can
 * actually break the line anywhere in the middle of it. This method performs an additional check
 * and splits the "words" into proper, non-divisible segments (if needed)
 */
const splitTextIntoSegments = (text: string) => {
    const segments: string[] = []
    let currentSegment = ''

    const tryAddingSegment = () => {
        if (currentSegment !== '') {
            // with this, ["拉的。"] becomes ["拉", "的", "。"];
            // note that we check if the word _contains_ any characters from the languages without
            // whitespaces, not if the word _fully consists_ of these characters, because we may
            // be getting something like "不要害怕AI。" from BE
            if (noWhitespaceLangRegExp.test(currentSegment)) {
                // this is basically more verbose `segments.push(...currentSegment.split(''))`,
                // but since we don't know how long `currentSegment` can be, spreading it like this
                // can lead to stack overflows when its too long, so we use safer approach
                for (let i = 0; i < currentSegment.length; i++) {
                    segments.push(currentSegment.charAt(i))
                }
            } else {
                segments.push(currentSegment)
            }
            currentSegment = ''
        }
    }

    for (const char of text) {
        if (isLetterOrDigit(char)) {
            currentSegment += char
        } else {
            tryAddingSegment()

            // punctuation signs are appended to the previous word, not added as a distinct word,
            // because that's what browser does when rendering it (to avoid dangling punctuation
            // at the beginning of a line)
            if (isPunctuation(char) && segments.length) {
                segments[segments.length - 1] += char
            } else {
                segments.push(char)
            }
        }
    }

    tryAddingSegment()

    return segments
}

const combineWordsWithPunctuation = (words: TranscriptWord[]) => {
    const combinedWords: TranscriptWord[] = []
    words.forEach((word) => {
        if (isPunctuation(word.text) && combinedWords.length) {
            combinedWords[combinedWords.length - 1].text += word.text
        } else {
            combinedWords.push({ ...word })
        }
    })

    return combinedWords
}

interface CountLinesDto {
    ctx: CanvasRenderingContext2D
    words: TranscriptWord[]
    maxWidth: number
    language: Language
}
export const countLines = ({ ctx, words, maxWidth, language }: CountLinesDto) => {
    let lineCount = 0
    let currentLine = ''

    const isLanguageWithoutWhitespaces = LANGUAGES_WITHOUT_WHITESPACES.includes(language as LanguageWithoutWhitespaces)
    const actualWords = isLanguageWithoutWhitespaces ? words : combineWordsWithPunctuation(words)

    for (const word of actualWords) {
        const segments = isLanguageWithoutWhitespaces ? splitTextIntoSegments(word.text) : [word.text]
        for (const segment of segments) {
            const width = ctx.measureText(currentLine + segment).width
            if (Math.ceil(width) < maxWidth) {
                currentLine += segment
            } else {
                lineCount++
                currentLine = segment
            }
        }
        if (ctx.measureText(currentLine + ' ').width < maxWidth) {
            currentLine += ' '
        }
    }

    if (currentLine) {
        lineCount++
    }

    return lineCount
}

/**
 * This function is primarily used to format prompt result we get from LLM in AI panel.
 * This result is always just a plain string, but in certain cases
 * (e.g., for action items or keywords prompts) we need to display it as a list of items.
 *
 * Example input (typical prompt result):
 * ```
 * 1. View
 * 2. JavaScript framework
 * 3. User interfaces
 * ```
 *
 * Expected output (an array of strings without the numbers):
 * ```
 * ["View", "JavaScript framework", "User interfaces"]
 * ```
 */
export const splitTextFormattedAsOrderedList = (text: string) =>
    text
        // use newline symbols as a separator
        .split('\n')
        .map((s) =>
            s
                // replace any amount of numeric symbols,
                // placed at the beginning of a string,
                // followed by a dot or a bracket
                .replace(/^\d*[.)]*/, '')
                .trim(),
        )

export const formatAsOrderedList = (items: string[]) => items.reduce((acc, curr, i) => acc + `${i + 1}. ${curr}\n`, '')

export const getTitlePresentation = (plainText: string) =>
    plainText
        // replace quotation marks
        .replace(/["'«»‘’“”]/g, '')
        // replace the word "Title"
        .replace(/Title:/g, '')
        .trim()

export const getTextFromReactNode = (node: ReactNode): string => {
    if (typeof node === 'string') {
        return node
    }
    if (typeof node === 'number') {
        return node.toString()
    }
    if (Array.isArray(node)) {
        return node.map(getTextFromReactNode).join('')
    }
    if (typeof node === 'object' && !!node && (node as ReactElement).props) {
        return getTextFromReactNode((node as ReactElement).props.children)
    }

    return ''
}

export const isUUID = (text: string) => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(text)
