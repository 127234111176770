import CryptoJS from 'crypto-js'

export function decrypt(encryptedString: string, key: string) {
    // Base64 decode the encrypted string
    const decoded = atob(encryptedString)

    // Extract the IV (initialization vector) for the AES decryption
    const iv = CryptoJS.enc.Latin1.parse(decoded.slice(0, 16))

    // The rest of the decoded string is the actual encrypted data
    const encryptedData = decoded.slice(16)

    // Convert the encrypted data and the key to Base64 and then to CryptoJS's WordArray format
    const ciphertext = CryptoJS.enc.Base64.parse(btoa(encryptedData))
    const cryptoKey = CryptoJS.enc.Latin1.parse(key)

    // Create a CipherParams object for the decryption
    const encryptedCipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: ciphertext,
    })

    // Decrypt the data using AES
    const decryptedWordArray = CryptoJS.AES.decrypt(encryptedCipherParams, cryptoKey, {
        iv: iv,
    })

    // Convert the decrypted WordArray to a Latin1 string and return it
    const decryptedLatin1 = decryptedWordArray.toString(CryptoJS.enc.Latin1)

    return decryptedLatin1
}
