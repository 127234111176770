import styled, { useTheme } from 'styled-components'
import { theme } from 'styled-tools'
import { AlertCircleIcon } from '@verbit-ai/icons-library'

import { TranscriptError } from 'src/models/Error'

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme('palette.transcript.backgroundColor')};
    color: ${theme('palette.transcript.textColors.0.colorValue')};
`

const Text = styled.div`
    text-align: center;
    margin-top: 16px;
    max-width: 400px;
    padding: 0 10px;
`

const Title = styled.h1`
    font-size: 32px;
    line-height: 1.5;
`

const Description = styled.span`
    font-size: 16px;
    line-height: 1.5;
`

interface TranscriptErrorScreenProps {
    error: TranscriptError
}

interface ErrorDetails {
    title: string
    description: string
}

const ERROR_DETAILS: Record<TranscriptError, ErrorDetails> = {
    errorAccessDenied: {
        title: 'Access Denied',
        description: "You don't have permission to access this transcript. The link might be private or require authentication",
    },
    errorInvalidLink: {
        title: 'Invalid Link',
        description: 'The link you provided is invalid or broken. Please double-check the link and try again',
    },
    errorNetworkError: {
        title: 'Network Error',
        description: 'There seems to be a problem connecting to the server. Please check your internet connection and try again',
    },
    errorServerOverload: {
        title: 'Server Overload',
        description: 'The server is currently not responding. Please try accessing the transcript again later',
    },
    errorTechnicalDifficulties: {
        title: 'Technical Difficulties',
        description: 'There are currently technical difficulties with the live transcript service. Please try again later',
    },
    errorTranscriptNotFound: {
        title: 'Transcript Not Found',
        description: 'The transcript you are trying to access cannot be found. It might have been deleted or unavailable',
    },
}

export function TranscriptErrorScreen({ error }: TranscriptErrorScreenProps) {
    const { title, description } = ERROR_DETAILS[error]
    const {
        palette: {
            global: { iconColor },
        },
    } = useTheme()

    return (
        <Wrapper>
            <AlertCircleIcon size={75} color={iconColor} />
            <Text>
                <Title>{title}</Title>
                <Description>{description}</Description>
            </Text>
        </Wrapper>
    )
}
