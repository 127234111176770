import Rollbar from 'rollbar'

import { getEnv, getRollbarToken } from 'src/utils/env'

import { ServiceBase } from './ServiceBase'

class RollbarService extends ServiceBase {
    rollbar: Rollbar | undefined

    constructor() {
        super({ blockedEnvs: ['dev'] })
    }

    init() {
        try {
            this.rollbar = new Rollbar({
                accessToken: getRollbarToken(),
                captureUncaught: true,
                captureUnhandledRejections: true,
                payload: {
                    environment: getEnv(),
                    source_map_enabled: true,
                },
                ignoredMessages: [
                    'Network Error',
                    'Request failed with status code 401',
                    'Request failed with status code 403',
                    'Request aborted',
                ],
            })
            // Make rollbar globally available
            ;(window as any).rollbar = this.rollbar
        } catch (e) {
            console.error('Error initializing Rollbar', e)
        }
    }

    identify(id: string) {
        this.rollbar?.configure({
            payload: {
                person: {
                    id: id,
                },
            },
        })
    }

    error(eventName: string) {
        this.rollbar?.error(eventName)
    }
}

export const rollbarService = new RollbarService()
