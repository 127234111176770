export interface UserJSON {
    user_id: number
    user_name: string | null
    company_name: string
    customer_id: number | null
    email: string
    rules: string[]
}

export interface User {
    userId: number
    userName: string | null
    companyName: string
    customerId: number | null
    email: string
    rules: string[]
}

export const toUser = (userJson: UserJSON): User => ({
    userId: userJson.user_id,
    userName: userJson.user_name,
    companyName: userJson.company_name,
    customerId: userJson.customer_id,
    email: userJson.email,
    rules: userJson.rules,
})
