import React, { useEffect, useMemo, useRef, useState } from 'react'
import { GridLoader } from 'react-spinners'

import styled, { css, useTheme } from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { OverlayToaster } from '@blueprintjs/core'
import { CheckIcon, InfoIcon, OfflineIcon } from '@verbit-ai/icons-library'
import { AIPanelStage, GenAiPanelProps } from '@verbit-ai/ai-service-web'
import { VerticalType } from '@verbit-ai/ai-service-web/dist/models/Insight'

import { analyticsService } from 'src/services/AnalyticsService'
import { useUserContext } from 'src/contexts/UserProvider'
import { TranscriptUpdateEventQueueExecutor } from 'src/contexts/TranscriptUpdateEventQueueExecutor'
import {
    useIsJobFinished,
    useIsTranscriptAvailable,
    useIsTranscriptEmpty,
    useTranscriptError,
    useTranscriptMetadata,
    useTranscriptTokensAmount,
} from 'src/contexts/TranscriptContext'
import { TranscriptViewerProvider } from 'src/contexts/TranscriptViewerProvider'
import { SearchProvider } from 'src/contexts/SearchContext'
import { useDimensions } from 'src/hooks/useDimensions'
import { Topbar } from 'src/components/Topbar/Topbar'
import { TranscriptViewer } from 'src/components/TranscriptViewer/TranscriptViewer'
import { EmptyTranscriptScreen } from 'src/components/TranscriptViewer/EmptyTranscriptScreen'
import {
    getSourceOfTruth,
    getTranscriptJobId,
    getTraxSessionId,
    isAIPanelOpenInitially,
    isAppOpenedFromEmailLink,
    isChromakeyModeActive,
    isZoomAppsEnv,
    getMinTokensAmountToRunQueries,
    getEnv,
    isAIPanelSuppressed,
} from 'src/utils/env'
import { TranscriptToolbar } from 'src/components/TranscriptToolbar/TranscriptToolbar'
import { useResponsive } from 'src/hooks/useResponsive'
import { usePrevious } from 'src/hooks/usePrevious'
import { productAdoptionService } from 'src/services/ProductAdoptionService'
import { TranscriptToolbarChromakey } from 'src/components/TranscriptToolbar/TranscriptToolbarChromakey'
import {
    AI_PANEL_CLOSED_WIDTH,
    AI_PANEL_OPENED_WIDTH,
    AI_PANEL_TRANSITION_DURATION,
    AI_PANEL_TRANSITION_EASING,
    NEW_AI_PANEL_OPENED_WIDTH,
} from 'src/components/constants'
import { styledToastClassName, styledToastWithClose, StyledToastWrapper } from 'src/components/Toast/Toast'
import { DEFAULT_LANGUAGE } from 'src/models/Language'
import { useFeatureFlag } from 'src/contexts/FeatureFlagContext'
import { isJobLive } from 'src/models/TranscriptMetadata'
import { rollbarService } from 'src/services/RollbarService'
import { ThemeType } from 'src/themes/types'
import { announceText } from 'src/utils/announce'
import { useIsOnline } from 'src/hooks/useIsOnline'
import { OfflineMessage } from 'src/components/Toast/messages/OfflineMessage'
import { TranscriptErrorScreen } from 'src/components/TranscriptViewer/TranscriptErrorScreen'
import { useConfig } from 'src/contexts/ConfigurationContext'

const GenAiPanel = React.lazy(() => import('@verbit-ai/ai-service-web').then((module) => ({ default: module.GenAiPanel })))

const AppWrapper = styled.div`
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${theme('palette.transcript.backgroundColor')};
`

const AppContent = styled.div<{ $isHeaderHidden: boolean }>`
    height: calc(100% - ${theme('sizes.headerHeight')}px);
    // cannot put theme inside calc inside ifProp, so have to override height this way
    ${ifProp(
        '$isHeaderHidden',
        css`
            height: 100%;
        `,
    )}
    position: relative;
`

const LoaderContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: ${theme('palette.transcript.backgroundColor')};
`

const TranscriptViewerContainer = styled.div<{ $isShifted?: boolean; $panelWidth: number; $isNewAiPanel: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: transform ${AI_PANEL_TRANSITION_DURATION}s ${AI_PANEL_TRANSITION_EASING};
    transform: translateX(
        ${(props) =>
            props.$isNewAiPanel ? (props.$isShifted ? '-12.5%' : '0%') : props.$isShifted ? `${-props.$panelWidth / 2}px` : '0px'}
    );

    @media (max-width: ${theme('breakPoints.laptopMinWidth')}px) {
        transform: translateX(0);
    }
`

const StreamConcluded = styled.span`
    font-weight: 600;
`

const getAiPanelTheme = (appTheme: ThemeType) => {
    const themesMap: Record<ThemeType, GenAiPanelProps['themeType']> = {
        default: 'default',
        dark: 'dark',
        highContrast: 'highContrast',
        chromakey: 'default',
    }

    return themesMap[appTheme]
}

const onMixpanelEvent = analyticsService.track.bind(analyticsService)
const onAppcuesEvent = productAdoptionService.track.bind(productAdoptionService)
const onRollbarError = rollbarService.error.bind(rollbarService)

function App() {
    const env = getEnv()
    const isTranscriptAvailable = useIsTranscriptAvailable()
    const isTranscriptEmpty = useIsTranscriptEmpty()
    const transcriptMetadata = useTranscriptMetadata()
    const transcriptError = useTranscriptError()
    const { dimensionsMeasurerRef, dimensions } = useDimensions({ debounceMs: 1000 / 60 })
    const { width, height } = dimensions

    const [isInitialFontAvailable, setIsInitialFontAvailable] = useState(false)
    const isReadyToDisplayViewerContainer = !!width && !!height && !!transcriptMetadata?.currentLanguage
    const isReadyToDisplayTranscript = isTranscriptAvailable && !!transcriptMetadata?.currentLanguage

    const [isPrerequisitesLoading, setIsPrerequisitesLoading] = useState(true)
    useEffect(() => {
        setIsPrerequisitesLoading((!isReadyToDisplayTranscript || !isInitialFontAvailable) && !transcriptError && !isTranscriptEmpty)
    }, [isInitialFontAvailable, isReadyToDisplayTranscript, isTranscriptEmpty, transcriptError])

    const { user, isUserFetched } = useUserContext()
    const isAiPanelAvailable = transcriptMetadata?.generativeAiEnabled || false
    useEffect(() => {
        if (isUserFetched && transcriptMetadata) {
            analyticsService.trackPageVisit({
                user,
                sessionState: transcriptMetadata.sessionState,
                isZoomAppsEnv: isZoomAppsEnv(),
                downloadable: transcriptMetadata.downloadable,
                isAiPanelAvailable,
            })
        }
    }, [user, isUserFetched, transcriptMetadata, isAiPanelAvailable])

    const isChromakeyMode = isChromakeyModeActive()

    const currentTheme = useTheme()
    const [{ isHeaderHidden, isFullWidthMode }] = useConfig()
    const { isTablet } = useResponsive()
    const prevIsTablet = usePrevious(isTablet)
    const transcriptHeight = isTablet && !isChromakeyMode ? height : height - Number(currentTheme.sizes.trancriptToolbarHeight)

    const isNewInsightsPanelEnabled = useFeatureFlag('new_insights_panel')
    const initialInsightsPanelState = useFeatureFlag('insights_panel_init_open_state')

    const [isAIPanelOpened, setIsAIPanelOpened] = useState(false)
    const [aiPanelStage, setAiPanelStage] = useState<AIPanelStage>('promptsList')
    useEffect(() => {
        // on tablet / mobile, prompts list is shown in a distinct bar outside of the panel,
        // so when user switches from desktop to tablet / mobile while on "promptsList" stage,
        // the panel should be closed, otherwise user would see empty modal
        if (isTablet && !prevIsTablet && aiPanelStage === 'promptsList') {
            setIsAIPanelOpened(false)
        }
    }, [aiPanelStage, isTablet, prevIsTablet])

    /* For Insights-panel, to set the panel default state to true
       TODO Remove this when Insights-panel is enabled by default
    */
    useEffect(() => {
        const isLiveSession = transcriptMetadata && isJobLive(transcriptMetadata)
        const isFeatureFlagsEnabled = isNewInsightsPanelEnabled && initialInsightsPanelState

        if (isLiveSession && isFeatureFlagsEnabled) {
            setIsAIPanelOpened(true)
        }
    }, [isNewInsightsPanelEnabled, initialInsightsPanelState, transcriptMetadata])

    const isAiPanelSuppressed = useMemo(() => isAIPanelSuppressed(), [])
    const shouldDisplayAiPanel =
        isAiPanelAvailable && !isChromakeyMode && transcriptMetadata?.currentLanguage === DEFAULT_LANGUAGE && !isAiPanelSuppressed
    const containerWidth = shouldDisplayAiPanel ? width - AI_PANEL_CLOSED_WIDTH : width

    const [aiPanelTheme, setAiPanelTheme] = useState(getAiPanelTheme(currentTheme.themeType))
    useEffect(() => {
        setAiPanelTheme(getAiPanelTheme(currentTheme.themeType))
    }, [currentTheme.themeType])

    useEffect(() => {
        analyticsService.track('Generative AI - AIPanelAvailability', {
            isAvailable: isAiPanelAvailable,
        })
    }, [isAiPanelAvailable])

    useEffect(() => {
        if (isAppOpenedFromEmailLink()) {
            analyticsService.track('Generative AI - Email link opened', {})
        }
    }, [])

    useEffect(() => {
        if (!transcriptMetadata) {
            return
        }

        const { originalLanguageCode, translations } = transcriptMetadata
        analyticsService.track('TranslationsLanguagesLoaded', {
            languages: translations?.length ? translations.map(({ code }) => code) : [originalLanguageCode],
        })
    }, [transcriptMetadata])

    useEffect(() => {
        if (isNewInsightsPanelEnabled) return
        if (shouldDisplayAiPanel) {
            productAdoptionService.trackOpenAiIntroNotification()
            if (isAIPanelOpenInitially() && !isTablet) {
                setIsAIPanelOpened(true)
            }
        }
        // we only have to check for this on the initial load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldDisplayAiPanel])

    const isCurrentJobFinished = useIsJobFinished()
    const wasCurrentJobFinished = usePrevious(isCurrentJobFinished)
    const toasterRef = useRef<OverlayToaster | null>(null)
    useEffect(() => {
        if (!wasCurrentJobFinished && isCurrentJobFinished) {
            if (!isTablet) {
                setIsAIPanelOpened(true)
            }
            toasterRef.current?.show({
                icon: <InfoIcon size={20} color={currentTheme.palette.toast.infoIconColor} />,
                message: <StreamConcluded>Stream concluded. Thank you for joining!</StreamConcluded>,
                timeout: 5000,
                className: `${styledToastClassName} ${styledToastWithClose}`,
            })
        }
    }, [currentTheme.palette.toast.infoIconColor, isCurrentJobFinished, isTablet, wasCurrentJobFinished])

    const wasPromptReadyToastShown = useRef(false)
    useEffect(() => {
        if (aiPanelStage === 'promptResult' && !isAIPanelOpened && !wasPromptReadyToastShown.current) {
            toasterRef.current?.show({
                message: 'Your prompt has been generated!',
                icon: <CheckIcon size={18} color={currentTheme.palette.toast.successIconColor} />,
                action: {
                    text: 'View response',
                    onClick: () => setIsAIPanelOpened(true),
                },
                timeout: 24 * 60 * 60 * 1000,
                className: styledToastClassName,
            })
            wasPromptReadyToastShown.current = true
        }
        if (aiPanelStage === 'loading') {
            wasPromptReadyToastShown.current = false
        }
    }, [aiPanelStage, currentTheme.palette.toast.successIconColor, isAIPanelOpened])

    const isPromptResultEditingAvailable = useFeatureFlag('prompt_result_editing')
    const editorRoles = ['customer_admin', 'admin', 'editor']
    const canEditPromptResults =
        isPromptResultEditingAvailable &&
        ((transcriptMetadata && !isJobLive(transcriptMetadata)) || isCurrentJobFinished) &&
        !!user?.rules &&
        editorRoles.reduce((accCanEdit, role) => accCanEdit || user.rules.includes(role), false)

    const transcriptTokensAmount = useTranscriptTokensAmount()

    const isOnline = useIsOnline()
    const prevIsOnline = usePrevious(isOnline)
    useEffect(() => {
        if (!isOnline) {
            toasterRef.current?.show({
                message: <OfflineMessage />,
                icon: <OfflineIcon size={18} />,
                timeout: 24 * 60 * 60 * 1000,
                className: styledToastClassName,
            })
        }
        if (prevIsOnline === false && isOnline) {
            window.location.reload()
        }
    }, [isOnline, prevIsOnline])

    const app = (
        <TranscriptUpdateEventQueueExecutor>
            <AppWrapper>
                {!isChromakeyMode && !isHeaderHidden && <Topbar />}

                <AppContent $isHeaderHidden={isHeaderHidden || isChromakeyMode} ref={dimensionsMeasurerRef}>
                    {isReadyToDisplayTranscript ? (
                        isTranscriptEmpty ? (
                            <EmptyTranscriptScreen />
                        ) : (
                            isReadyToDisplayViewerContainer && (
                                <>
                                    <TranscriptViewerContainer
                                        $isShifted={!isFullWidthMode && isAIPanelOpened}
                                        $panelWidth={isNewInsightsPanelEnabled ? NEW_AI_PANEL_OPENED_WIDTH : AI_PANEL_OPENED_WIDTH}
                                        $isNewAiPanel={isNewInsightsPanelEnabled}>
                                        {isChromakeyMode ? (
                                            <TranscriptToolbarChromakey containerWidth={containerWidth} />
                                        ) : (
                                            <TranscriptToolbar isAIPanelOpened={isAIPanelOpened} containerWidth={containerWidth} />
                                        )}
                                        <TranscriptViewer
                                            width={width}
                                            containerWidth={containerWidth}
                                            containerHeight={transcriptHeight}
                                            isAiPanelVisible={shouldDisplayAiPanel}
                                            isAIPanelOpened={isAIPanelOpened}
                                            language={transcriptMetadata!.currentLanguage}
                                            onInitialFontAvailable={() => setIsInitialFontAvailable(true)}
                                        />
                                    </TranscriptViewerContainer>
                                    {shouldDisplayAiPanel && (
                                        <GenAiPanel
                                            isOpen={isAIPanelOpened}
                                            onOpenToggle={() => setIsAIPanelOpened((isOpened) => !isOpened)}
                                            onStageChange={setAiPanelStage}
                                            position={isTablet ? 'top' : 'right'}
                                            canEditResults={canEditPromptResults}
                                            transcriptJobId={
                                                isNewInsightsPanelEnabled
                                                    ? String(transcriptMetadata.transcriptionJobId)
                                                    : getTranscriptJobId()
                                            }
                                            traxSessionId={getTraxSessionId()}
                                            generativeAiEnabled
                                            isJobFinished={isCurrentJobFinished}
                                            isJobLive={isJobLive(transcriptMetadata)}
                                            environment={env}
                                            sessionName={transcriptMetadata?.sessionName}
                                            sourceOfTruth={getSourceOfTruth()}
                                            transcriptTokensAmount={transcriptTokensAmount}
                                            transcriptTokensAmountMin={getMinTokensAmountToRunQueries()}
                                            useVerboseLogging
                                            useMocks={env === 'dev'}
                                            themeType={aiPanelTheme}
                                            onMixpanelEvent={onMixpanelEvent}
                                            onAppcuesEvent={onAppcuesEvent}
                                            onRollbarError={onRollbarError}
                                            onTextAnnounce={announceText}
                                            newInsightsPanelEnabled={isNewInsightsPanelEnabled}
                                            vertical={transcriptMetadata?.vertical as VerticalType}
                                        />
                                    )}
                                </>
                            )
                        )
                    ) : (
                        !!transcriptError && <TranscriptErrorScreen error={transcriptError} />
                    )}
                    {isPrerequisitesLoading && (
                        <LoaderContainer>
                            <GridLoader color={currentTheme.palette.transcript.loaderColor} />
                        </LoaderContainer>
                    )}
                </AppContent>
                <StyledToastWrapper usePortal={false} toasterRef={toasterRef} position="top" maxToasts={1} />
            </AppWrapper>
        </TranscriptUpdateEventQueueExecutor>
    )

    return isTranscriptEmpty || !!transcriptError ? (
        app
    ) : (
        <TranscriptViewerProvider isInitialFontAvailable={isInitialFontAvailable}>
            <SearchProvider>{app}</SearchProvider>
        </TranscriptViewerProvider>
    )
}

export default App
