import { useEffect, useState } from 'react'

import styled, { useTheme } from 'styled-components'
import { Popover } from '@blueprintjs/core'
import { CheckIcon, RightChevronIcon } from '@verbit-ai/icons-library'

import { useSetThemeField } from 'src/contexts/ThemeContext'
import { BP5_POPOVER_HORIZONTAL_SPACING_CLASSNAME, BP5_POPOVER_VERTICAL_SPACING_CLASSNAME } from 'src/global-styles'
import { useBlueprintThemeClass } from 'src/hooks/useBlueprintThemeClass'
import { THEME_TYPE_QUERY_PARAM } from 'src/utils/env'
import { analyticsService } from 'src/services/AnalyticsService'
import { ThemeType, THEME_TYPES } from 'src/themes/types'

import { ThemeButton } from './ThemeButton'
import { THEMES_PRESENTATION, ThemeToggleProps } from './types'
import { ThemeTextCustomization } from './ThemeTextCustomization'

const ThemeToggleContainer = styled.div`
    padding: 8px 0;
    display: flex;
    flex-direction: column;
`

export function ThemeToggle({ isOpen, onClose, children }: ThemeToggleProps) {
    const {
        palette: {
            themeToggle: { iconColor },
        },
        themeType: activeThemeType,
    } = useTheme()
    const setThemeField = useSetThemeField()
    const popoverClassName = useBlueprintThemeClass()
    const [isTextCustomizationOpen, setIsTextCustomizationOpen] = useState(false)

    useEffect(() => {
        if (isOpen) {
            analyticsService.track('Accessibility panel opened', {})
        }
    }, [isOpen])

    const openChromakeyMode = () => {
        const themeType: ThemeType = 'chromakey'

        const newUrl = new URL(window.location.href)
        newUrl.searchParams.set(THEME_TYPE_QUERY_PARAM, themeType)

        const windowWidth = 800
        const windowHeight = window.innerHeight - 100
        const features = [
            'popup=true',
            `width=${windowWidth}`,
            `height=${windowHeight}`,
            `left=${Math.round((window.outerWidth - windowWidth) / 2)}`,
            `top=${Math.round((window.outerHeight - windowHeight) / 2)}`,
        ].join(',')

        window.open(newUrl, '_blank', features)
    }

    const themeToggleContainer = () => (
        <ThemeToggleContainer>
            {THEME_TYPES.filter((themeType) => THEMES_PRESENTATION[themeType].isVisible).map((themeType) => (
                <ThemeButton key={themeType} onClick={() => setThemeField('themeType', themeType)}>
                    {THEMES_PRESENTATION[themeType].name}
                    {themeType === activeThemeType && <CheckIcon color={iconColor} />}
                </ThemeButton>
            ))}
            <Popover
                content={<ThemeTextCustomization />}
                className={BP5_POPOVER_HORIZONTAL_SPACING_CLASSNAME}
                popoverClassName={popoverClassName}
                interactionKind="click"
                isOpen={isTextCustomizationOpen}
                onClose={() => setIsTextCustomizationOpen(false)}
                placement="right-start"
                minimal
                usePortal={false}>
                <ThemeButton onClick={() => setIsTextCustomizationOpen((isCustomizationOpen) => !isCustomizationOpen)}>
                    Custom text <RightChevronIcon color={iconColor} />
                </ThemeButton>
            </Popover>
            <ThemeButton onClick={openChromakeyMode}>Open Green Chromakey</ThemeButton>
        </ThemeToggleContainer>
    )

    return (
        <Popover
            content={themeToggleContainer()}
            portalClassName={BP5_POPOVER_VERTICAL_SPACING_CLASSNAME}
            popoverClassName={popoverClassName}
            interactionKind="click"
            isOpen={isOpen}
            onClose={onClose}
            placement="top-end"
            minimal>
            {children}
        </Popover>
    )
}
