import styled, { useTheme } from 'styled-components'

import { useSetThemeField } from 'src/contexts/ThemeContext'
import { BlackWhiteToggleIcon } from 'src/components/icons'

const Button = styled.button`
    padding: 10px;
    outline: 0;
`

export function ThemeTextCustomizationChromakey() {
    const { textColorIndex } = useTheme()
    const setThemeField = useSetThemeField()

    const toggleTextColor = () => {
        setThemeField('textColorIndex', textColorIndex ? 0 : 1)
    }

    return (
        <Button tabIndex={-1} onClick={toggleTextColor}>
            <BlackWhiteToggleIcon />
        </Button>
    )
}
