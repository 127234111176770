import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { getEnv, getVerbitAuthServerUrl } from 'src/utils/env'

interface RequestConfig extends Omit<AxiosRequestConfig, 'url' | 'method'> {
    requiresAuth?: boolean
}
interface PrivateRequestConfig extends AxiosRequestConfig {
    requiresAuth?: boolean
}

export abstract class HttpClientBase {
    private client: AxiosInstance

    constructor(clientConfig: AxiosRequestConfig<any> = {}) {
        this.client = axios.create(clientConfig)
    }

    protected async get<T = any>(url: string, config?: RequestConfig) {
        return this.request<T>({ method: 'get', url, ...config })
    }

    protected async post<T = any>(url: string, config?: RequestConfig) {
        return this.request<T>({ method: 'post', url, ...config })
    }

    protected async put<T = any>(url: string, config?: RequestConfig) {
        return this.request<T>({ method: 'put', url, ...config })
    }

    protected async patch<T = any>(url: string, config?: RequestConfig) {
        return this.request<T>({ method: 'patch', url, ...config })
    }

    protected async delete<T = any>(url: string, config?: RequestConfig) {
        return this.request<T>({ method: 'delete', url, ...config })
    }

    protected async request<T>(config: PrivateRequestConfig) {
        try {
            const { data } = await this.client.request<T>(config)

            return data
        } catch (error: any) {
            if (config.requiresAuth && getEnv() !== 'dev' && axios.isAxiosError(error) && error.response?.status === 401) {
                window.location.href = `${getVerbitAuthServerUrl()}/users/sign_in?redirect_to=${encodeURI(window.location.href)}`
            }

            throw error
        }
    }
}
