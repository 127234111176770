import { useCallback } from 'react'

import styled, { useTheme } from 'styled-components'
import { DoubleUpArrowsIcon } from '@verbit-ai/icons-library'
import { ifProp, prop, theme } from 'styled-tools'

import { AI_PANEL_TRANSITION_DURATION, AI_PANEL_TRANSITION_EASING } from 'src/components/constants'

const Button = styled.button<{ $isVisible: boolean; $hasIndicator: boolean; $positionRight: number }>`
    position: absolute;
    bottom: 32px;
    right: ${prop('$positionRight')}%;
    transform: scale(${({ $isVisible }) => ($isVisible ? 1 : 0)});
    transition:
        transform ${({ $isVisible }) => ($isVisible ? '500ms cubic-bezier(.99,1.97,.56,.72)' : '250ms ease-out')},
        right ${AI_PANEL_TRANSITION_DURATION}s ${AI_PANEL_TRANSITION_EASING};
    cursor: pointer;

    width: 36px;
    height: 36px;
    flex-shrink: 0;
    background-color: ${theme('palette.scrollDownButton.backgroundColor')};
    border: 1px solid ${theme('palette.scrollDownButton.borderColor')};
    border-radius: 50%;
    box-shadow: ${theme('palette.scrollDownButton.shadow')};
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        display: ${ifProp('$hasIndicator', 'block', 'none')};
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        top: -2px;
        right: -2px;
        background-color: #de2839;
        border-radius: 50%;
    }

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        bottom: 20px;
        right: 20px;
    }
`

const StyledScrollDownIcon = styled(DoubleUpArrowsIcon)`
    transform: rotate(180deg);
`

interface Props {
    onScrollDown: () => void
    isVisible: boolean
    isShifted: boolean
    hasIndicator: boolean
}

export const ScrollButton = ({ onScrollDown, isVisible, isShifted, hasIndicator }: Props) => {
    const {
        palette: {
            scrollDownButton: { iconColor },
        },
    } = useTheme()

    const onClick = useCallback(() => {
        onScrollDown()
    }, [onScrollDown])

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLButtonElement>) => {
            switch (e.keyCode) {
                // ENTER
                case 13: {
                    e.preventDefault()
                    onScrollDown()
                }
            }
        },
        [onScrollDown],
    )

    return (
        <Button
            onClick={onClick}
            onKeyDown={onKeyDown}
            tabIndex={isVisible ? 0 : -1}
            title="Jump to the end"
            $isVisible={isVisible}
            $positionRight={isShifted ? 15 : 5}
            $hasIndicator={hasIndicator}>
            <StyledScrollDownIcon size={20} color={iconColor} />
        </Button>
    )
}
