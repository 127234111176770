import { useContext, useEffect } from 'react'

import { TranscriptViewerContext } from 'src/contexts/TranscriptViewerProvider'

export const useNonBlockingAutoscrollEffect = (run: () => void, deps: any[]) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { requestNonBlockingAutoscrollFrame = (run: () => void) => run() } = useContext(TranscriptViewerContext) ?? {}

    useEffect(() => {
        requestNonBlockingAutoscrollFrame(run)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
}
