import { QueryClient, QueryClientProvider } from 'react-query'
import { RouterProvider } from 'react-router-dom'

import { createRoot } from 'react-dom/client'

import '@blueprintjs/core/lib/css/blueprint.css'

import 'src/index.css'
import 'src/mocks/MockManager'

import { getRequestedLanguageCode, getTranscriptJobId } from 'src/utils/env'
import { webSessionRecordingService } from 'src/services/WebSessionRecordingService'
import { analyticsService } from 'src/services/AnalyticsService'
import { productAdoptionService } from 'src/services/ProductAdoptionService'
import { UserProvider } from 'src/contexts/UserProvider'
import { TranscriptProvider } from 'src/contexts/TranscriptContext'
import { QueueProvider } from 'src/contexts/QueueProvider'
import { ConfigurationProvider } from 'src/contexts/ConfigurationContext'
import { rollbarService } from 'src/services/RollbarService'
import { FeatureFlagProvider } from 'src/contexts/FeatureFlagContext'
import { ClipboardProvider } from 'src/contexts/ClipboardContext'
import { ThemeProvider } from 'src/contexts/ThemeContext'

import { router } from './routes'

webSessionRecordingService.init()
analyticsService.init()
rollbarService.init()
productAdoptionService.init()

const queryClient = new QueryClient()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
    <QueryClientProvider client={queryClient}>
        <QueueProvider>
            <ConfigurationProvider>
                <UserProvider>
                    <TranscriptProvider eventId={getTranscriptJobId()} languageCode={getRequestedLanguageCode()}>
                        <ThemeProvider>
                            <FeatureFlagProvider>
                                <ClipboardProvider>
                                    <RouterProvider router={router} />
                                </ClipboardProvider>
                            </FeatureFlagProvider>
                        </ThemeProvider>
                    </TranscriptProvider>
                </UserProvider>
            </ConfigurationProvider>
        </QueueProvider>
    </QueryClientProvider>,
)
