import { getPlatformServerUrl } from 'src/utils/env'
import { toUser, UserJSON } from 'src/models/User'
import { Transcript, TranscriptJSON } from 'src/models/Transcript'
import { toTranscriptMetadata, TranscriptMetadata, TranscriptMetadataJSON } from 'src/models/TranscriptMetadata'
import { rollbarService } from 'src/services/RollbarService'

import { HttpClientInterface } from './HttpClientInterface'
import { HttpClientBase } from './HttpClientBase'

export interface HttpOptions {
    baseUrl?: string
}

export class HttpClient extends HttpClientBase implements HttpClientInterface {
    constructor(options: HttpOptions = {}) {
        super({
            baseURL: options.baseUrl || `${getPlatformServerUrl()}/api/v1/`,
        })
    }

    async getTranscriptMetadata(transcriptId: string): Promise<TranscriptMetadata> {
        return this.get<TranscriptMetadataJSON>(`/transcription_jobs/${transcriptId}/configuration`, {
            withCredentials: true,
            requiresAuth: true,
        }).then(toTranscriptMetadata)
    }

    async getTranscript(transcriptId: string, languageCode?: string): Promise<Transcript> {
        const params = languageCode ? { language_code: languageCode } : {}

        return this.get<TranscriptJSON>(`/transcription_jobs/${transcriptId}/transcript`, {
            withCredentials: true,
            requiresAuth: true,
            params,
        }).then((json) => {
            if (Transcript.isValidJSON(json)) {
                return Transcript.fromJSON(json)
            }

            console.warn('[HttpClient] Invalid transcript JSON', json)
            rollbarService.error(`[HttpClient] Invalid transcript JSON: ${json}`)

            return Transcript.EMPTY
        })
    }

    async identify() {
        return this.get<UserJSON>('/users/whoami', { withCredentials: true }).then(toUser)
    }
}
