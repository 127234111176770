import { getPlatformServerUrl, getRequestedLanguageCode } from 'src/utils/env'

import { getLanguageByCode, Language, Translation } from './Language'

export type TranscriptType = 'asr_only' | 'edit' | 'immediate' | 'rough_draft'

export interface TranscriptMetadata {
    transcriptType: string
    sessionState?: {
        startTime?: Date
        deposition?: SessionDeposition
    }
    ablyTokenUrl: string
    ablyClientOptions: Record<string, any>
    sessionId?: number
    scheduledEventId: number
    downloadable: boolean
    transcriptionJobId: number
    sessionFinished: Date
    sessionName: string
    customerId: number
    customerName: string
    suppressSpeakerName: boolean
    generativeAiEnabled: boolean
    vertical: string
    originalLanguageCode: string
    translations: Translation[]
    currentLanguageCode: string
    currentLanguage: Language
}

export enum SessionDeposition {
    NEW = 'new',
    SCHEDULED = 'scheduled',
    LIVE = 'live',
    FINISHED = 'finished',
    CANCELED = 'canceled',
    DATA_DELETION_REQUESTED = 'data_deletion_requested',
    DATA_DELETED = 'data_deleted',
}

export interface TranscriptMetadataJSON {
    transcript_type: TranscriptType
    session_start_time: string
    session_state?: { deposition: { status: SessionDeposition } }
    ably_token_url: string
    ably_client_options: Record<string, any>
    trax_session_id?: number
    scheduled_event_id: number
    DL: boolean
    transcription_job_id: number
    session_finished_at: Date
    session_name: string
    customer_id: number
    customer_name: string
    suppress_speaker_names: boolean
    generative_ai_enabled: boolean
    vertical: string
    language_code: string
    languages?: { code: string; name: string }[]
}

export function toTranscriptMetadata(json: TranscriptMetadataJSON): TranscriptMetadata {
    const availableTranslationCodes = new Set(json.languages?.map(({ code }) => code))
    const requestedLanguageCode = getRequestedLanguageCode()
    const currentLanguageCode = availableTranslationCodes.has(requestedLanguageCode) ? requestedLanguageCode : json.language_code
    const currentLanguage = getLanguageByCode(currentLanguageCode)

    return {
        transcriptType: json.transcript_type,
        sessionState: {
            startTime: new Date(json.session_start_time),
            deposition: json.session_state?.deposition?.status,
        },
        ablyTokenUrl: formatAblyTokenUrl(json.ably_token_url),
        ablyClientOptions: json.ably_client_options,
        sessionId: json.trax_session_id,
        scheduledEventId: json.scheduled_event_id,
        downloadable: json.DL,
        transcriptionJobId: json.transcription_job_id,
        sessionFinished: json.session_finished_at,
        sessionName: json.session_name,
        customerId: json.customer_id,
        customerName: json.customer_name,
        suppressSpeakerName: json.suppress_speaker_names,
        generativeAiEnabled: json.generative_ai_enabled,
        vertical: json.vertical,
        originalLanguageCode: json.language_code,
        translations: json.languages || [],
        currentLanguageCode,
        currentLanguage,
    }
}

const formatAblyTokenUrl = (urlString: string) => {
    try {
        const { pathname, search } = new URL(urlString)

        return `${getPlatformServerUrl()}${pathname}${search}`
    } catch (e) {
        return ''
    }
}

export const isJobLive = (metadata: TranscriptMetadata) => !isNaN(metadata.scheduledEventId)

export const isJobFinished = (metadata: TranscriptMetadata) => metadata.sessionFinished !== undefined

export const isLanguageSelectionAvailable = (metadata: TranscriptMetadata | null) => !!metadata?.translations?.length
