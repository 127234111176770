import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { VerbitThemeProvider, theme as verbitTheme } from '@verbit-ai/verbit-ui-library'

import { themeCore, themePalettes } from 'src/themes'
import {
    THEME_TYPES,
    Theme,
    TEXT_BACKGROUND_COLORS_AMOUNT,
    DEFAULT_FONT_FAMILY_BY_LANGUAGE,
    DEFAULT_THEME_TYPE,
    TEXT_COLORS_AMOUNT,
    DEFAULT_TEXT_COLOR_INDEX,
    DEFAULT_TEXT_BACKGROUND_COLOR_INDEX,
    FontFamily,
    ConfigurableThemeFields,
    ThemeType,
    FONT_FAMILIES,
} from 'src/themes/types'
import { THEME_TYPE_QUERY_PARAM, isChromakeyModeActive } from 'src/utils/env'
import { analyticsService } from 'src/services/AnalyticsService'
import { DEFAULT_LANGUAGE, Language, LANGUAGES_WITH_SPECIAL_FONTS, LanguageWithSpecialFont } from 'src/models/Language'

import { useTranscriptMetadata } from './TranscriptContext'

function getAvailableFontsByLanguage(language?: Language): FontFamily[] {
    if (!!language && LANGUAGES_WITH_SPECIAL_FONTS.includes(language as LanguageWithSpecialFont)) {
        return [DEFAULT_FONT_FAMILY_BY_LANGUAGE[language]]
    }

    return ['Arial', 'Inter', 'Roboto', 'SourceSerif']
}

function getThemeConfigOverrides(): Partial<ConfigurableThemeFields> {
    const urlParams = new URLSearchParams(window.location.search)
    const overrides: Partial<ConfigurableThemeFields> = {}

    const themeTypeParam = urlParams.get(THEME_TYPE_QUERY_PARAM)
    if (THEME_TYPES.includes(themeTypeParam as ThemeType)) {
        overrides.themeType = themeTypeParam as ThemeType
    }

    const fontFamilyParam = urlParams.get('fontFamily')
    if (FONT_FAMILIES.includes(fontFamilyParam as FontFamily)) {
        overrides.fontFamily = fontFamilyParam as FontFamily
    }

    const textColorIndexParam = parseInt(urlParams.get('textColorIndex') || '')
    if (!isNaN(textColorIndexParam) && textColorIndexParam >= 0 && textColorIndexParam <= TEXT_COLORS_AMOUNT) {
        overrides.textColorIndex = textColorIndexParam
    }

    const textBackgroundColorIndexParam = parseInt(urlParams.get('textBackgroundColorIndex') || '')
    if (
        !isNaN(textBackgroundColorIndexParam) &&
        textBackgroundColorIndexParam >= 0 &&
        textBackgroundColorIndexParam <= TEXT_BACKGROUND_COLORS_AMOUNT
    ) {
        overrides.textBackgroundColorIndex = textBackgroundColorIndexParam
    }

    return overrides
}

interface GetDefaultConfigurableFieldsParams {
    isChromakeyMode: boolean
    language?: Language
}
function getDefaultConfigurableFields({ isChromakeyMode, language }: GetDefaultConfigurableFieldsParams): ConfigurableThemeFields {
    let themeType = DEFAULT_THEME_TYPE
    let fontFamily = DEFAULT_FONT_FAMILY_BY_LANGUAGE[language || DEFAULT_LANGUAGE]
    let textColorIndex = DEFAULT_TEXT_COLOR_INDEX
    let textBackgroundColorIndex = DEFAULT_TEXT_BACKGROUND_COLOR_INDEX

    const availableFonts = getAvailableFontsByLanguage(language)

    if (isChromakeyMode) {
        themeType = 'chromakey'
    } else {
        try {
            const {
                themeType: savedThemeType,
                fontFamily: savedFontFamily,
                textColorIndex: savedTextColorIndex,
                textBackgroundColorIndex: savedTextBackgroundColorIndex,
            } = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}') as ConfigurableThemeFields

            if (THEME_TYPES.includes(savedThemeType)) {
                themeType = savedThemeType
            }

            if (availableFonts.includes(savedFontFamily)) {
                fontFamily = savedFontFamily
            }

            if (Number.isInteger(savedTextColorIndex) && savedTextColorIndex >= 0 && savedTextColorIndex <= TEXT_COLORS_AMOUNT - 1) {
                textColorIndex = savedTextColorIndex
            }

            if (
                Number.isInteger(savedTextBackgroundColorIndex) &&
                savedTextBackgroundColorIndex >= 0 &&
                savedTextBackgroundColorIndex <= TEXT_BACKGROUND_COLORS_AMOUNT - 1
            ) {
                textBackgroundColorIndex = savedTextBackgroundColorIndex
            }
        } catch (e) {}
    }

    const config: ConfigurableThemeFields = {
        themeType,
        fontFamily,
        textColorIndex,
        textBackgroundColorIndex,
    }

    const configOverrides = getThemeConfigOverrides()
    if (configOverrides.fontFamily && !availableFonts.includes(configOverrides.fontFamily)) {
        delete configOverrides.fontFamily
    }
    Object.assign(config, configOverrides)

    return config
}

interface ThemeContextData {
    theme: Theme
    setThemeField: <T extends keyof ConfigurableThemeFields>(p1: T, p2: ConfigurableThemeFields[T]) => void
}

interface ThemeProviderProps {
    children?: React.ReactNode
}

const LOCAL_STORAGE_KEY = 'verbitext_ui_active_theme'

export const ThemeContext = createContext<ThemeContextData | null>(null)

export function ThemeProvider({ children }: ThemeProviderProps) {
    const isChromakeyMode = isChromakeyModeActive()
    const metadata = useTranscriptMetadata()
    const [configurableFields, setConfigurableFields] = useState(getDefaultConfigurableFields({ isChromakeyMode }))

    useEffect(() => {
        if (metadata?.currentLanguage) {
            setConfigurableFields(getDefaultConfigurableFields({ isChromakeyMode, language: metadata.currentLanguage }))
        }
    }, [isChromakeyMode, metadata?.currentLanguage])

    useEffect(() => {
        if (isChromakeyMode) {
            return
        }
        try {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(configurableFields))
        } catch (e) {}
    }, [configurableFields, isChromakeyMode])

    const setThemeField: ThemeContextData['setThemeField'] = (field, value) =>
        setConfigurableFields((oldFields) => {
            const newFields: ConfigurableThemeFields = {
                ...oldFields,
                [field]: value,
            }

            if (field === 'themeType') {
                // when user changes theme, reset text color and background color
                newFields.textColorIndex = DEFAULT_TEXT_COLOR_INDEX
                newFields.textBackgroundColorIndex = DEFAULT_TEXT_BACKGROUND_COLOR_INDEX

                analyticsService.track('AccessibilityThemeClicked', { themeType: value })
            }

            if (field === 'textColorIndex') {
                analyticsService.track('AccessibilityFontColorChange', { textColorIndex: value })
            }

            if (field === 'textBackgroundColorIndex') {
                analyticsService.track('AccessibilityBackgroundColorChange', { textBackgroundColorIndex: value })
            }

            if (field === 'fontFamily') {
                analyticsService.track('AccessibilityFontChange', { fontFamily: value })
            }

            return newFields
        })

    const providedValue = useMemo<ThemeContextData>(
        () => ({
            theme: {
                ...themeCore,
                ...configurableFields,
                palette: themePalettes[configurableFields.themeType],
                availableFontFamilies: getAvailableFontsByLanguage(metadata?.currentLanguage),
            },
            setThemeField,
        }),
        [configurableFields, metadata?.currentLanguage],
    )

    return (
        <ThemeContext.Provider value={providedValue}>
            <StyledThemeProvider theme={providedValue.theme}>
                <VerbitThemeProvider theme={verbitTheme}>{children}</VerbitThemeProvider>
            </StyledThemeProvider>
        </ThemeContext.Provider>
    )
}

export const useSetThemeField = () => {
    const ctx = useContext(ThemeContext)

    if (!ctx) {
        throw new Error('You have forgot to use <ThemeContext.Provider />, shame on you.')
    }

    return ctx.setThemeField
}
