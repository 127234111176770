import styled, { useTheme } from 'styled-components'
import { prop } from 'styled-tools'

import { TranscriptAuthor } from 'src/models/Transcript'
import { useTranscriptMetadata } from 'src/contexts/TranscriptContext'
import { isChromakeyModeActive } from 'src/utils/env'

interface ParagraphAuthorProps {
    author?: TranscriptAuthor
}

export const PARAGRAPH_AUTHOR_FONT_SIZE_COEFF = 0.875
export const PARAGRAPH_AUTHOR_LINE_HEIGHT_COEFF = 1.5

const Speaker = styled.div<{ $color: string }>`
    font-family: inherit;
    font-size: ${PARAGRAPH_AUTHOR_FONT_SIZE_COEFF}em;
    font-weight: 400;
    line-height: ${PARAGRAPH_AUTHOR_LINE_HEIGHT_COEFF};
    color: ${prop('$color')};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export function ParagraphAuthor({ author }: ParagraphAuthorProps) {
    const metadata = useTranscriptMetadata()
    const isChromakeyMode = isChromakeyModeActive()
    const speakerName = isChromakeyMode ? '>>' : metadata?.suppressSpeakerName ? '' : author?.name || '>>'

    const {
        textColorIndex,
        palette: {
            transcript: { speakerColors },
        },
    } = useTheme()
    const textColor = speakerColors[textColorIndex]

    return <Speaker $color={textColor}>{speakerName}</Speaker>
}
