import styled, { css } from 'styled-components'
import { ifNotProp, theme } from 'styled-tools'

import { SCROLL_BAR_WIDTH } from 'src/utils/dom'
import { AI_PANEL_CLOSED_WIDTH } from 'src/components/constants'

const BAR_PADDING_MOBILE = 20

export const TranscriptToolbarBar = styled.div<{ $keepOnTablet?: boolean }>`
    width: 100%;
    height: ${theme('sizes.trancriptToolbarHeight')}px;
    z-index: ${theme('zIndices.trancriptToolbar')};
    background-color: ${theme('palette.transcript.toolbarOuterBackground')};
    padding: 0 ${AI_PANEL_CLOSED_WIDTH + SCROLL_BAR_WIDTH}px 6px 0;

    ${ifNotProp(
        '$keepOnTablet',
        css`
            @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
                display: none;
            }
        `,
    )}

    @media (max-width: ${theme('breakPoints.mobilePhoneMaxWidth')}px) {
        padding: 0 ${BAR_PADDING_MOBILE + SCROLL_BAR_WIDTH}px 0 ${BAR_PADDING_MOBILE}px;
    }
`
