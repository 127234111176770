import { useEffect, useRef, useState } from 'react'

import { debounce } from 'lodash/fp'

interface Options {
    forceRerender?: boolean
    debounceMs?: number
}

interface Dimensions {
    width: number
    height: number
}

export const useDimensions = ({ debounceMs = 0 }: Options = {}) => {
    const dimensionsMeasurerRef = useRef<HTMLDivElement>(null)
    const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 })

    useEffect(() => {
        const onResize = debounce(debounceMs)(() => {
            if (!dimensionsMeasurerRef.current) return

            const { clientWidth, clientHeight } = dimensionsMeasurerRef.current
            setDimensions({ width: clientWidth, height: clientHeight })
        })

        onResize()

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [debounceMs])

    return { dimensionsMeasurerRef, dimensions }
}
