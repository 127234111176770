import { useMemo } from 'react'

import styled, { useTheme } from 'styled-components'
import { prop, theme } from 'styled-tools'
import moment from 'moment'

import { useTranscript } from 'src/contexts/TranscriptContext'

export const PARAGRAPH_TIMESTAMP_FONT_SIZE_COEFF = 0.75
export const PARAGRAPH_TIMESTAMP_LINE_HEIGHT_COEFF = 1.5

const TABLET_FONT_SIZE_COEFF = 0.875

const Timestamp = styled.div<{ $color: string }>`
    font-family: inherit;
    font-size: ${PARAGRAPH_TIMESTAMP_FONT_SIZE_COEFF}em;
    font-weight: 400;
    line-height: ${PARAGRAPH_TIMESTAMP_LINE_HEIGHT_COEFF};
    color: ${prop('$color')};
    text-transform: uppercase;

    @media (max-width: ${theme('breakPoints.tabletMaxWidth')}px) {
        font-size: ${TABLET_FONT_SIZE_COEFF}em;
    }
`

interface ParagraphTimestampProps {
    timeRelative: number
    timeAbsolute: number
}

export function ParagraphTimestamp({ timeAbsolute, timeRelative }: ParagraphTimestampProps) {
    const { recordingStartedAt } = useTranscript()
    const formattedParagraphTime = useMemo(() => {
        const format = 'HH:mm'

        return timeAbsolute
            ? formatParagraphTime({ type: 'absolute', absoluteParagraphTimeInSeconds: timeAbsolute, format })
            : formatParagraphTime({
                  type: 'relative',
                  sessionStartTime: recordingStartedAt,
                  relativeParagraphTimeInSeconds: timeRelative,
                  format,
              })
    }, [recordingStartedAt, timeAbsolute, timeRelative])

    const {
        textColorIndex,
        palette: {
            transcript: { timestampColors },
        },
    } = useTheme()
    const textColor = timestampColors[textColorIndex]

    return <Timestamp $color={textColor}>{formattedParagraphTime}</Timestamp>
}

type FormatParagraphTimeParams =
    | {
          type: 'absolute'
          format: string
          absoluteParagraphTimeInSeconds: number
      }
    | {
          type: 'relative'
          format: string
          sessionStartTime: Date
          relativeParagraphTimeInSeconds: number
      }

const formatParagraphTime = (params: FormatParagraphTimeParams) =>
    params.type === 'absolute'
        ? moment(params.absoluteParagraphTimeInSeconds * 1000).format(params.format)
        : moment(params.sessionStartTime).add(params.relativeParagraphTimeInSeconds, 'seconds').format(params.format)
