import { PromptInternalTestResponse } from 'src/models/Prompt'
import { AiServiceClientInterface } from 'src/network/AiServiceClientInterface'

export class MockAiServiceClient implements AiServiceClientInterface {
    async testPromptInit(): Promise<PromptInternalTestResponse> {
        await document.mocks.isReady()
        await new Promise((resolve) => setTimeout(resolve, 1000))

        return document.mocks.getPromptTestInitResult()
    }

    async testPromptFetchCsv(): Promise<string> {
        await document.mocks.isReady()
        await new Promise((resolve) => setTimeout(resolve, 1000))

        return document.mocks.getPromptTestCsv()
    }
}
