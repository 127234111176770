import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { pick } from 'lodash-es'
import axios from 'axios'

import { Transcript } from 'src/models/Transcript'
import { isJobFinished, isJobLive, TranscriptMetadata } from 'src/models/TranscriptMetadata'
import { httpClient } from 'src/network'
import { AblyStreamSource } from 'src/network/AblyStreamSource'
import { StreamMockSource } from 'src/mocks/StreamMockSource'
import { StreamClient, StreamSourceInterface } from 'src/network/StreamClient'
import { isMockActive, isZoomAppsEnv } from 'src/utils/env'
import { downloadTranscriptFile } from 'src/utils/download'
import { analyticsService } from 'src/services/AnalyticsService'
import { rollbarService } from 'src/services/RollbarService'
import { TranscriptError } from 'src/models/Error'
import { isUUID } from 'src/utils/text'

import { useQueue } from './QueueProvider'

const streamClient = new StreamClient()

const TranscriptContext = createContext<Transcript | null | undefined>(undefined)
const MetadataContext = createContext<TranscriptMetadata | null | undefined>(undefined)
const IsJobFinishedContext = createContext<boolean>(false)
const TranscriptErrorContext = createContext<TranscriptError | null>(null)

const METADATA_REFRESH_TIMEOUT = 2 * 60 * 1000 //ms

interface TranscriptProviderProps {
    children: ReactNode
    eventId: string
    languageCode?: string
}

const transcriptErrorsByStatusCode = new Map<number, TranscriptError>([
    [403, TranscriptError.ACCESS_DENIED],
    [404, TranscriptError.TRANSCRIPT_NOT_FOUND],
    [429, TranscriptError.SERVER_OVERLOAD],
    [500, TranscriptError.TECHNICAL_DIFFICULTIES],
])

export function TranscriptProvider({ children, eventId, languageCode }: TranscriptProviderProps) {
    const [transcript, setTranscript] = useState<Transcript | null>(null)
    const [metadata, setMetadata] = useState<TranscriptMetadata | null>(null)
    const [isCurrentJobFinished, setIsCurrentJobFinished] = useState(false)
    const [transcriptError, setTranscriptError] = useState<TranscriptError | null>(null)

    const executeRevisionQueue = useCallback(() => {
        const revisionMessages = streamClient.queue('revision_update').drain()

        setTranscript(
            (currentTranscript) =>
                currentTranscript &&
                revisionMessages.reduce((acc, revisionMessage, index) => {
                    let transcriptRevision = Transcript.EMPTY
                    if (Transcript.isValidJSON(revisionMessage.data)) {
                        transcriptRevision = Transcript.fromJSON(revisionMessage.data)
                    } else {
                        console.warn('[TranscriptProvider] Invalid transcript JSON', revisionMessage)
                        rollbarService.error(`[TranscriptProvider] Invalid transcript JSON: ${revisionMessage.data}`)
                    }

                    return Transcript.merge(acc, transcriptRevision, index > 0)
                }, currentTranscript),
        )
    }, [])

    const executeAsrQueue = useCallback(() => {
        const asrMessages = streamClient.queue('asr_chunk').drain()

        setTranscript(
            (currentTranscript) =>
                currentTranscript &&
                asrMessages.reduce(
                    (acc, asrMessage, index) => Transcript.mergeAsrChunk(acc, asrMessage.data, index > 0),
                    currentTranscript,
                ),
        )
    }, [])

    const setRevisionQueueSize = useQueue('revision', executeRevisionQueue)
    const setAsrQueueSize = useQueue('asr', executeAsrQueue)

    const getMetadataTimeoutRef = useRef<NodeJS.Timeout>()

    const errorHandler = (error: any) => {
        setTranscriptError(
            axios.isAxiosError(error) && error.response && transcriptErrorsByStatusCode.has(error.response.status)
                ? transcriptErrorsByStatusCode.get(error.response.status)!
                : error?.response?.status === 401
                  ? // when 401 is encountered, `HttpClientBase` will redirect user to the sign in page;
                    // this takes time, so we set null here in this case, to prevent user from seeing
                    // "Network Error" before being redirected
                    null
                  : TranscriptError.NETWORK_ERROR,
        )
    }

    useEffect(() => {
        // avoid requesting transcript and metadata on routes that aren't working with them
        if (!isMockActive() && (!eventId || !isUUID(eventId))) {
            setTranscriptError(TranscriptError.INVALID_LINK)

            return
        }

        const continuoslyRefreshMetadata = () => {
            if (getMetadataTimeoutRef.current) {
                clearTimeout(getMetadataTimeoutRef.current)
            }
            getMetadataTimeoutRef.current = setTimeout(() => {
                httpClient
                    .getTranscriptMetadata(eventId)
                    .then((latestMetadata) => {
                        const isFinished = isJobFinished(latestMetadata)
                        setIsCurrentJobFinished(isFinished)
                        if (!isFinished) {
                            continuoslyRefreshMetadata()
                        }
                    })
                    .catch((error) => {
                        errorHandler(error)
                        continuoslyRefreshMetadata()
                    })
            }, METADATA_REFRESH_TIMEOUT)
        }

        Promise.all([httpClient.getTranscriptMetadata(eventId), httpClient.getTranscript(eventId, languageCode)])
            .then(async ([initialMetadata, initialTranscript]) => {
                let source: StreamSourceInterface | undefined = undefined

                if (isMockActive() && document.mocks?.getMode() === 'replay') {
                    source = new StreamMockSource({
                        delayConfig: {
                            default: [400, 500],
                        },
                    })
                } else if (initialMetadata.sessionId && initialMetadata.ablyTokenUrl) {
                    source = new AblyStreamSource({
                        url: initialMetadata.ablyTokenUrl,
                        options: initialMetadata.ablyClientOptions,
                        sessionId: initialMetadata.sessionId.toString(),
                    })
                }

                if (source) {
                    streamClient.attachSource(source)
                }

                const start = Transcript.getProcessedTime(initialTranscript)
                const asrHistory = await streamClient.history<'asr_chunk'>('asr', { start, limit: 1000 })

                // mandatory event fields have to saved in AnalyticsService before saving them to state,
                // because otherwise events like page visit, which are triggered upon metadata changes,
                // might not receive them
                analyticsService.setMandatoryEventFields(initialMetadata)

                setMetadata(initialMetadata)

                const isFinished = isJobFinished(initialMetadata)
                setIsCurrentJobFinished(isFinished)
                if (isJobLive(initialMetadata) && !isFinished) {
                    continuoslyRefreshMetadata()
                }

                setTranscript(Transcript.mergeAsrTranscriptionHistory(initialTranscript, asrHistory))

                streamClient.subscribe('trax', 'asr')
                streamClient.queue('revision_update').on('size_change', setRevisionQueueSize)
                streamClient.queue('asr_chunk').on('size_change', setAsrQueueSize)
            })
            .catch(errorHandler)

        return () => {
            if (getMetadataTimeoutRef.current) {
                clearTimeout(getMetadataTimeoutRef.current)
            }
        }
    }, [eventId, languageCode, setAsrQueueSize, setRevisionQueueSize])

    return (
        <TranscriptContext.Provider value={transcript}>
            <MetadataContext.Provider value={metadata}>
                <IsJobFinishedContext.Provider value={isCurrentJobFinished}>
                    <TranscriptErrorContext.Provider value={transcriptError}>{children}</TranscriptErrorContext.Provider>
                </IsJobFinishedContext.Provider>
            </MetadataContext.Provider>
        </TranscriptContext.Provider>
    )
}

export function useIsTranscriptAvailable() {
    const transcript = useContext(TranscriptContext)
    const metadata = useContext(MetadataContext)

    if (transcript === undefined || metadata === undefined) {
        throw new Error('You have forgot to use <TranscriptProvider />, shame on you.')
    }

    return !!(transcript && metadata)
}

export function useIsTranscriptEmpty() {
    const transcript = useContext(TranscriptContext)
    const metadata = useContext(MetadataContext)

    if (transcript === undefined || metadata === undefined) {
        throw new Error('You have forgot to use <TranscriptProvider />, shame on you.')
    }

    return !transcript || !Transcript.getTotalParagraphCount(transcript)
}

export function useTranscript() {
    const transcript = useContext(TranscriptContext)

    if (transcript === undefined) {
        throw new Error('You have forgot to use <TranscriptContext.Provider />, shame on you.')
    }

    if (!transcript) {
        throw new Error(
            'No transcript available. This is a logic error. Please make sure the transcript is available when using useTranscript()',
        )
    }

    return transcript
}

export function useTranscriptTokensAmount() {
    const transcript = useContext(TranscriptContext)

    if (transcript === undefined) {
        throw new Error('You have forgot to use <TranscriptContext.Provider />, shame on you.')
    }

    if (!transcript) {
        return 0
    }

    return Transcript.getTokensAmount(transcript)
}

export function useTranscriptMetadata() {
    const value = useContext(MetadataContext)

    if (value === undefined) {
        throw new Error('You have forgot to use <MetadataContext.Provider />, shame on you.')
    }

    return value
}

export function useDownload() {
    const transcript = useContext(TranscriptContext)
    const metadata = useContext(MetadataContext)

    const canDownload = metadata?.downloadable && !isZoomAppsEnv()

    const downloadTextFile = useCallback(() => {
        if (transcript && metadata) {
            downloadTranscriptFile(transcript, metadata)
            analyticsService.trackDownload(pick(metadata, ['sessionId', 'sessionState', 'customerId', 'customerName']))
        }
    }, [transcript, metadata])

    return { canDownload, downloadTextFile }
}

export function useIsJobFinished() {
    const isFinished = useContext(IsJobFinishedContext)

    return isFinished
}

export function useTranscriptError() {
    const transcriptError = useContext(TranscriptErrorContext)

    return transcriptError
}
