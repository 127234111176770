import { createGlobalStyle, css } from 'styled-components'
import { theme } from 'styled-tools'

export const BP5_POPOVER_VERTICAL_SPACING_CLASSNAME = 'vertical-spacing'
export const BP5_POPOVER_HORIZONTAL_SPACING_CLASSNAME = 'horizontal-spacing'

const cssPopoverNoFocusTrapOutline = css`
    .bp5-overlay-start-focus-trap,
    .bp5-overlay-end-focus-trap {
        outline: 0;
    }
`
const cssPopoverVerticalSpacing = css`
    .bp5-overlay-content {
        transform: translateY(4px);
    }
`
const cssPopoverHorizontalSpacing = css`
    .bp5-overlay-content {
        transform: translateX(4px);
    }
`

export const getMobileOverlayTransitionCss = (transitionName: string, transitionDuration = 300) => css`
    &.${transitionName}-enter {
        transform: translateY(100%);
        &-active {
            transform: translateY(0);
            transition: transform ${transitionDuration}ms;
        }
    }
    &.${transitionName}-exit {
        transform: translateY(0);
        &-active {
            transform: translateY(100%);
            transition: transform ${transitionDuration}ms;
        }
    }
`

export const GlobalStyles = createGlobalStyle`
    html, body {
        background: ${theme('palette.transcript.backgroundColor')} !important;
    }

    // for popovers added through portal
    .bp5-portal {
        ${cssPopoverNoFocusTrapOutline}
        &.${BP5_POPOVER_VERTICAL_SPACING_CLASSNAME} {
            ${cssPopoverVerticalSpacing}
        }
        &.${BP5_POPOVER_HORIZONTAL_SPACING_CLASSNAME} {
            ${cssPopoverHorizontalSpacing}
        }
    }
    // for inline popovers
    .bp5-popover-target {
        & + .bp5-overlay {
            ${cssPopoverNoFocusTrapOutline}
        }
        &.${BP5_POPOVER_VERTICAL_SPACING_CLASSNAME} + .bp5-overlay {
            ${cssPopoverVerticalSpacing}
        }
        &.${BP5_POPOVER_HORIZONTAL_SPACING_CLASSNAME} + .bp5-overlay {
            ${cssPopoverHorizontalSpacing}
        }
    }

    :focus {
        outline: ${theme('palette.global.focusIndicatorColor')} auto 2px;
        outline-offset: 2px;
    }
`
