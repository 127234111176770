import { EventEmitter } from 'events'

import { StreamMessage } from 'src/network/StreamClient'

export interface LiveState {
    index: number
    length: number
}

type MockManagerEvents = {
    live_state_update: (liveState: LiveState) => void
    play: () => void
    pause: () => void
    step: (count: number) => void
}

type DelayRange = [number, number]
export type LiveEventPlayerIteratorOptions = Partial<Record<StreamMessage['action'], DelayRange>> & Record<'default', DelayRange>

type PlayerStates = 'playing' | 'paused' | 'stepping' // stepping means playing 1 event at a time

export class LiveEventPlayer {
    private events: StreamMessage[] = []
    private liveEventState: LiveState = { index: 0, length: 0 }
    private emitter = new EventEmitter<MockManagerEvents>()

    private playerState: PlayerStates = 'paused'

    addEvents(events: StreamMessage[]) {
        this.events = events
        this.liveEventState = {
            index: 0,
            length: events.length,
        }
        this.emitter.emit('live_state_update', this.liveEventState)
    }

    getCurrentPlayerState() {
        return this.playerState
    }

    play() {
        this.emitter.emit('play')
        this.playerState = 'playing'
    }

    /**
     * Step over 1 event at a time.
     */
    step(count = 1) {
        if (this.playerState === 'paused') {
            this.emitter.emit('step', count)
            this.playerState = 'stepping'
        }
    }

    pause() {
        this.emitter.emit('pause')
        this.playerState = 'paused'
    }

    private randomDelay([min, max]: DelayRange) {
        return Math.floor(Math.random() * (max - min)) + min
    }

    async *iterator(options: LiveEventPlayerIteratorOptions = { default: [500, 1000] }) {
        let pausePromise: { current: Promise<void> | null } = { current: null }
        let resolveFn: { current: (() => void) | null } = { current: null }

        let isStepping: { current: boolean } = { current: false }
        let stepCount: { current: number } = { current: 0 }

        const onPlay = () => {
            resolveFn.current?.()

            resolveFn.current = null
            pausePromise.current = null
        }
        const onPause = () => {
            pausePromise.current = new Promise((resolve) => {
                resolveFn.current = resolve
            })
        }
        const onStep = (count: number) => {
            isStepping.current = true
            stepCount.current += count
            onPlay()
        }
        this.on('play', onPlay)
        this.on('pause', onPause)
        this.on('step', onStep)

        if (this.playerState === 'paused') {
            onPause()
        }

        for (const [i, event] of this.events.entries()) {
            if (isStepping.current) {
                if (stepCount.current > 0) {
                    stepCount.current--
                }

                if (stepCount.current === 0) {
                    isStepping.current = false
                    this.pause()
                }
            }

            if (pausePromise.current) {
                await pausePromise.current
            }

            this.liveEventState = {
                index: i,
                length: this.liveEventState.length,
            }
            this.emitter.emit('live_state_update', this.liveEventState)
            yield event

            if (!isStepping.current) {
                await new Promise((resolve) => setTimeout(resolve, this.randomDelay(options[event.action] ?? options.default)))
            }
        }

        this.off('play', onPlay)
        this.off('pause', onPause)
        this.off('step', onStep)

        this.liveEventState = {
            index: this.liveEventState.index + 1,
            length: this.liveEventState.length,
        }
        this.emitter.emit('live_state_update', this.liveEventState)

        this.pause()
    }

    on<T extends keyof MockManagerEvents>(event: T, fn: MockManagerEvents[T]) {
        this.emitter.on(event, fn)
    }
    off<T extends keyof MockManagerEvents>(event: T, fn: MockManagerEvents[T]) {
        this.emitter.off(event, fn)
    }
}
