import { createBrowserRouter } from 'react-router-dom'

import { isMockActive } from 'src/utils/env'
import { GlobalStyles } from 'src/global-styles'

import App from './App/App'
import { AppHelmet } from './App/AppHelmet'
import { InternalPromptTest } from './InternalPromptTest/InternalPromptTest'
import { MockControls } from './MockControls/MockControls'
import { RoutingError } from './RoutingError/RoutingError'

export const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <>
                <AppHelmet />
                <GlobalStyles />
                <App />
                {isMockActive() && <MockControls />}
            </>
        ),
        errorElement: <RoutingError />,
    },
    {
        path: '/prompts-testing',
        element: <InternalPromptTest />,
    },
])
