import { DOMAttributes, useMemo, useRef } from 'react'

import styled from 'styled-components'
// import { sanitize } from 'dompurify'
import { Button, OverlayToaster } from '@blueprintjs/core'

import { PromptTemplateDetails, PROMPT_TEMPLATE_DETAILS_FIELDS } from 'src/models/Prompt'
import { useClipboard } from 'src/contexts/ClipboardContext'

const copyButtonClassname = 'copy-button'
const Container = styled.div`
    .${copyButtonClassname} {
        width: 100%;
        margin-bottom: 15px;
    }
`

const placeholderClassname = 'placeholder'
const fieldValueClassname = 'field-value'
const Prompt = styled.div`
    margin-bottom: 15px;
    .${placeholderClassname} {
        color: #bfbfbf;
        font-style: italic;
    }
    .${fieldValueClassname} {
        font-weight: 600;
    }
`

interface InternalPromptTestPromptProps {
    template: string
    details: PromptTemplateDetails
}

export function InternalPromptTestPrompt({ template, details }: InternalPromptTestPromptProps) {
    const promptPreviewHtml = useMemo<DOMAttributes<HTMLDivElement>['dangerouslySetInnerHTML']>(() => {
        // let htmlString = sanitize(template)
        let htmlString = template

        PROMPT_TEMPLATE_DETAILS_FIELDS.forEach((field) => {
            const fieldValue = details[field]
            const element = fieldValue
                ? `<span class="${fieldValueClassname}">${fieldValue}</span>`
                : `<span class="${placeholderClassname}">{${field}}</span>`
            htmlString = htmlString.replace(new RegExp(`{variable: ${field}}`), element)
        })

        return { __html: htmlString }
    }, [details, template])

    const toasterRef = useRef<OverlayToaster | null>(null)
    const { canWriteToClipboard, writeToClipboard } = useClipboard()
    const copyPrompt = () => {
        let promptString = template
        PROMPT_TEMPLATE_DETAILS_FIELDS.forEach((field) => {
            promptString = promptString.replace(new RegExp(`{variable: ${field}}`), details[field])
        })
        writeToClipboard?.(promptString).then(() => {
            toasterRef.current?.show({
                message: 'Prompt copied to clipboard!',
                timeout: 3000,
            })
        })
    }

    return (
        <Container>
            <Prompt dangerouslySetInnerHTML={promptPreviewHtml} />
            {canWriteToClipboard && (
                <Button intent="none" className={copyButtonClassname} onClick={copyPrompt}>
                    Copy prompt
                </Button>
            )}
            <OverlayToaster ref={toasterRef} position="top-right" maxToasts={1} />
        </Container>
    )
}
