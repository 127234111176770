import { createContext, ReactNode, useContext, useEffect, useRef } from 'react'
import { useQuery } from 'react-query'

import { httpClient } from 'src/network'
import { User } from 'src/models/User'
import { useShallowMemo } from 'src/hooks/useShallowMemo'
import { webSessionRecordingService } from 'src/services/WebSessionRecordingService'
import { analyticsService } from 'src/services/AnalyticsService'
import { productAdoptionService } from 'src/services/ProductAdoptionService'
import { rollbarService } from 'src/services/RollbarService'
import { getVerbitAuthServerUrl } from 'src/utils/env'

interface AuthorizerProps {
    children: ReactNode
}

export const UserProvider = ({ children }: AuthorizerProps) => {
    const { data: user = null, isFetched: isUserFetched } = useQuery([], () => httpClient.identify(), {
        retry: false,
        refetchOnWindowFocus: false,
    })
    const logoutFormRef = useRef<HTMLFormElement>(null)

    useEffect(() => {
        if (!user) {
            // identify anonymously only if we're sure that we tried identifying the user
            if (isUserFetched) {
                productAdoptionService.identify()
            }

            return
        }

        productAdoptionService.identify(user.email, user)
        webSessionRecordingService.identify(user.email)
        analyticsService.identify(user.email) // TODO: use "id"
        analyticsService.updateUserProps(user)
        rollbarService.identify(user.email)
    }, [isUserFetched, user])

    const logout = () => {
        setTimeout(() => logoutFormRef.current?.submit())
    }

    return (
        <UserContext.Provider value={useShallowMemo({ user, isUserFetched })}>
            <LogoutContext.Provider value={logout}>{children}</LogoutContext.Provider>
            <form
                style={{ display: 'none' }}
                ref={logoutFormRef}
                action={`${getVerbitAuthServerUrl()}/users/sign_out?redirect_to=${encodeURI(window.location.href)}`}
                method="POST">
                <input name="_method" value="delete" readOnly />
            </form>
        </UserContext.Provider>
    )
}

const UserContext = createContext<{ user: User | null; isUserFetched: boolean } | null>(null)
const LogoutContext = createContext<(() => void) | null>(null)

export const useUserContext = () => {
    const userContextValue = useContext(UserContext)

    if (userContextValue === null) {
        throw new Error('You have forgot to use <UserContext.Provider />, shame on you.')
    }

    return userContextValue
}

export const useLogout = () => {
    const logout = useContext(LogoutContext)

    if (logout === null) {
        throw new Error('You have forgot to use <LogoutContext.Provider />, shame on you.')
    }

    return logout
}
